var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"grid-view four-col-grid-view"},_vm._l((_vm.adsRealestate),function(ad){return _c('b-card',{key:ad.id,staticClass:"itemgrid-card",attrs:{"no-body":""},on:{"click":function($event){_vm.newTabTo(
        _vm.$router.resolve({
          name: "realestate-property",
          query: {
            propertyID: ad.id,
          },
        }).href
      )}}},[_c('div',[_c('b-link',[(ad.images && ad.images.length > 0)?_c('b-img',{staticClass:"tabs-img-estimate",attrs:{"src":ad.images[0],"alt":("" + (ad.id)),"fluid":""},on:{"error":function($event){return _vm.imageLoadError(ad.id)}}}):_vm._e()],1)],1),_c('b-card-body',[_c('div',{staticClass:"item-wrapper"},[_c('div',{staticClass:"item-rating"},[_c('h6',{staticClass:"item-price"},[_vm._v(" "+_vm._s(_vm.currency(ad.price))+" ")])]),_c('div',[_c('span',[_vm._v(" "+_vm._s(_vm.currency(ad.price / ad.area))+"/m² ")])])]),_c('div',{staticClass:"item-wrapper"},[_c('div',{staticClass:"item-rating"},[_c('b-badge',{staticClass:"mr-1",attrs:{"variant":"light-success"}},[_vm._v(" "+_vm._s(ad.area)+" m² ")]),_c('b-badge',{staticClass:"ml-25",attrs:{"variant":"light-primary"}},[_vm._v(" "+_vm._s(ad.nb_rooms)+" "+_vm._s(ad.nb_rooms > 1 ? "pièces" : "pièce")+" ")])],1),_c('div',[_c('span',[_vm._v(" "+_vm._s(ad.created_at.slice(0, 10))+" ")])])])])],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }