import { isToday } from "./utils";

export const kFormatter = (num) =>
  num > 999 ? `${(num / 1000).toFixed(1)}k` : num;

export const title = (value, replacer = " ") => {
  if (!value) return "";
  const str = value.toString();

  const arr = str.split(replacer);
  const capitalizedArray = [];
  arr.forEach((word) => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1);
    capitalizedArray.push(capitalized);
  });
  return capitalizedArray.join(" ");
};

export const avatarText = (value) => {
  if (!value) return "";
  const nameArray = value.split(" ");
  return nameArray.map((word) => word.charAt(0).toUpperCase()).join("");
};

/**
 * Format and return date in Humanize format
 * Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 * Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value,
  formatting = { month: "short", day: "numeric", year: "numeric" }
) => {
  if (!value) return value;
  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};

/**
 * Return short human friendly month representation of date
 * Can also convert date to only time if date is of today (Better UX)
 * @param {String} value date to format
 * @param {Boolean} toTimeForCurrentDay Shall convert to time if day is today/current
 */
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value);
  let formatting = { month: "short", day: "numeric" };

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: "numeric", minute: "numeric" };
  }

  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};

// Strip all the tags from markup and return plain text
export const filterTags = (value) => value.replace(/<\/?[^>]+(>|$)/g, "");

export const crossProduct = (val1, val2) => {
  if (typeof val1 !== "number" || val1 === 0) return 0;
  if (typeof val2 !== "number" || val2 === 0) return 0;

  const result = (val1 * 100) / val2;
  // format percentage
  return parseFloat(result).toFixed(2);
};

export const evalBetweenType = (program) => {
  const types = ["t1", "t2", "t3", "t4", "t5"];
  let minType = "";
  let maxType = "";
  for (const type of types) {
    if (program[type].volume.initial_stock > 0) {
      minType = type.toUpperCase();
      break;
    }
  }

  const typesReverse = types.reverse();
  for (const type of typesReverse) {
    if (program[type].volume.initial_stock > 0) {
      maxType = type.toUpperCase();
      break;
    }
  }

  return `Du ${minType} au ${maxType}`;
};

export const formatPeriodQuarter = (value) => {
  const year = value.slice(0, 4);
  const quarter = value.slice(5, 6);

  // format to 16/06/2012
  return `${quarter}T ${year}`;
};

export const formatReverseDate = (value) => {
  // split date like 2012-06-16
  const parts = value.slice(0, 10).split("-");
  // format to 16/06/2012
  return `${parts[2]}/${parts[1]}/${parts[0]}`;
};

export const formatMoment = (value) => {
  // split date like 2012-06-16
  const parts = value.split("T");
  // format to 16/06/2012
  return formatReverseDate(parts[0]) + " " + parts[1].slice(0, 5);
};

export const currencyPriceMeters = (value) => {
  if (typeof value !== "number" || value === 0) return "NA €/m²";
  // add space between long number
  const parts = parseFloat(value).toFixed(2).toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  // format currency
  return `${parts[0]} €/m²`;
};

export const currency = (value) => {
  if (typeof value !== "number" || value === 0) return "-";
  // add space between long number
  const parts = parseFloat(value).toFixed(2).toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  // format currency
  return `${parts[0]} €`;
};

export const bigNumberFormatter = (value) => {
  if (typeof value !== "number" || value === 0) return "-";
  // add space between long number
  const parts = parseFloat(value).toFixed(2).toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  // format currency
  return `${parts[0]}`;
};

export const percentage = (value) => {
  if (typeof value !== "number") return "-";
  // add space between long number
  const formated = parseFloat(value * 100).toFixed(2);
  // format currency
  return `${formated}%`;
};

export const evalTrend = (value1, value2) => {
  if (typeof value1 !== "number" || value1 <= 0) return 0;
  if (typeof value2 !== "number" || value2 <= 0) return 0;

  return parseFloat((value1 * 100) / value2 - 100).toFixed(2);
};

export const evalMajorPsc = (psc) => {
  const pscOptions = [
    { code: "farmer_rate", label: "Agriculteurs exploitants" },
    {
      code: "intermediaite_profession_rate",
      label: "Artisans, commerçants, chefs entreprise",
    },
    {
      code: "senior_executive_rate",
      label: "Cadres et professions intellectuelles supérieures",
    },
    {
      code: "intermediaite_profession_rate",
      label: "Professions intermédiaires",
    },
    { code: "employee_rate", label: "Salariés" },
    { code: "worker_rate", label: "Ouvriers" },
    { code: "employee_rate", label: "Salariés" },
    { code: "retired_rate", label: "Retraités" },
  ];

  let max = 0;
  let result = "";
  pscOptions.forEach((option) => {
    if (psc[option.code] > max) {
      max = psc[option.code];
      result =
        option.label +
        " - " +
        parseFloat(psc[option.code] * 100).toFixed(2) +
        "%";
    }
  });

  return result;
};
