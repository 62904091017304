/* tslint:disable */
/* eslint-disable */
/**
 * API Programs
 * L\'API Programmes permet d\'accéder à l\'ensemble des programmes immobilier répertoriés, ainsi que de leur descriptif au lot.  # Authentication  Authentification par token JWT Firebase sur le backoffice.  <!-- ReDoc-Inject: <security-definitions> -->
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: cdv-si@cadredevie.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AdsLot
 */
export interface AdsLot {
    /**
     * Id du programme.
     * @type {string}
     * @memberof AdsLot
     */
    'id_program'?: string;
    /**
     * Surface habitable (m²).
     * @type {number}
     * @memberof AdsLot
     */
    'area'?: number;
    /**
     * Nombre de pièces.
     * @type {number}
     * @memberof AdsLot
     */
    'rooms'?: number;
    /**
     * Prix TVA pleine libre.
     * @type {number}
     * @memberof AdsLot
     */
    'price'?: number;
    /**
     * Url de l\'image de couverture
     * @type {string}
     * @memberof AdsLot
     */
    'brochure_url'?: string;
    /**
     * Date de livraison du programme (Format YYYYMMDD)
     * @type {string}
     * @memberof AdsLot
     */
    'delivery_date'?: string;
    /**
     * Type de construction (Forme Urbaine).
     * @type {string}
     * @memberof AdsLot
     */
    'construction_type'?: AdsLotConstructionTypeEnum;
    /**
     * 
     * @type {Location}
     * @memberof AdsLot
     */
    'location'?: Location;
}

export const AdsLotConstructionTypeEnum = {
    Collectif: 'COLLECTIF',
    Individuel: 'INDIVIDUEL'
} as const;

export type AdsLotConstructionTypeEnum = typeof AdsLotConstructionTypeEnum[keyof typeof AdsLotConstructionTypeEnum];

/**
 * 
 * @export
 * @interface BoundingBox
 */
export interface BoundingBox {
    /**
     * Coordonnées GPS latitude haut.
     * @type {number}
     * @memberof BoundingBox
     */
    'top': number;
    /**
     * Coordonnées GPS longitude gauche.
     * @type {number}
     * @memberof BoundingBox
     */
    'left': number;
    /**
     * Coordonnées GPS latitude bas.
     * @type {number}
     * @memberof BoundingBox
     */
    'bottom': number;
    /**
     * Coordonnées GPS longitude droit.
     * @type {number}
     * @memberof BoundingBox
     */
    'right': number;
}
/**
 * 
 * @export
 * @interface Circle
 */
export interface Circle {
    /**
     * Le rayon de recherche si type géométrique \'cercle\'.
     * @type {number}
     * @memberof Circle
     */
    'distance': number;
    /**
     * 
     * @type {CircleCenter}
     * @memberof Circle
     */
    'center': CircleCenter;
}
/**
 * Centre du cercle de recherche.
 * @export
 * @interface CircleCenter
 */
export interface CircleCenter {
    /**
     * Coordonnées GPS latitude.
     * @type {number}
     * @memberof CircleCenter
     */
    'latitude': number;
    /**
     * Coordonnées GPS longitude.
     * @type {number}
     * @memberof CircleCenter
     */
    'longitude': number;
}
/**
 * 
 * @export
 * @interface GetLotsByProgramIDFiltersParameterInner
 */
export interface GetLotsByProgramIDFiltersParameterInner {
    /**
     * 
     * @type {string}
     * @memberof GetLotsByProgramIDFiltersParameterInner
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof GetLotsByProgramIDFiltersParameterInner
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface ListAdsLot
 */
export interface ListAdsLot {
    /**
     * 
     * @type {Pagination}
     * @memberof ListAdsLot
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {Array<AdsLot>}
     * @memberof ListAdsLot
     */
    'lots'?: Array<AdsLot>;
}
/**
 * 
 * @export
 * @interface ListLot
 */
export interface ListLot {
    /**
     * Nombre total d\'éléments trouvés.
     * @type {number}
     * @memberof ListLot
     */
    'total'?: number;
    /**
     * 
     * @type {Array<Lot>}
     * @memberof ListLot
     */
    'lots'?: Array<Lot>;
}
/**
 * 
 * @export
 * @interface ListProgram
 */
export interface ListProgram {
    /**
     * 
     * @type {Pagination}
     * @memberof ListProgram
     */
    'pagination'?: Pagination;
    /**
     * 
     * @type {Array<SituationProgram>}
     * @memberof ListProgram
     */
    'programs'?: Array<SituationProgram>;
}
/**
 * 
 * @export
 * @interface Location
 */
export interface Location {
    /**
     * Coordonnées GPS latitude.
     * @type {string}
     * @memberof Location
     */
    'latitude'?: string;
    /**
     * Coordonnées GPS longitude.
     * @type {string}
     * @memberof Location
     */
    'longitude'?: string;
    /**
     * Nom du secteur.
     * @type {string}
     * @memberof Location
     */
    'sector_name'?: string;
    /**
     * Rue.
     * @type {string}
     * @memberof Location
     */
    'street'?: string;
    /**
     * Code postal.
     * @type {string}
     * @memberof Location
     */
    'zip'?: string;
    /**
     * Ville.
     * @type {string}
     * @memberof Location
     */
    'city'?: string;
    /**
     * Code Insee.
     * @type {string}
     * @memberof Location
     */
    'insee_code'?: string;
}
/**
 * 
 * @export
 * @interface Lot
 */
export interface Lot {
    /**
     * Id (uuid).
     * @type {string}
     * @memberof Lot
     */
    'id'?: string;
    /**
     * Type de lot.
     * @type {string}
     * @memberof Lot
     */
    'type'?: LotTypeEnum;
    /**
     * Surface habitable (m²).
     * @type {number}
     * @memberof Lot
     */
    'living_area'?: number;
    /**
     * Surface de jardin (m²).
     * @type {number}
     * @memberof Lot
     */
    'garden_area'?: number;
    /**
     * Surface des terrasses (m²).
     * @type {number}
     * @memberof Lot
     */
    'terrace_area'?: number;
    /**
     * Surface des terrasses (m²).
     * @type {number}
     * @memberof Lot
     */
    'varrangue_area'?: number;
    /**
     * Prix TVA pleine libre.
     * @type {number}
     * @memberof Lot
     */
    'full_vat_price_parking_excepted'?: number;
    /**
     * Prix TVA pleine libre avec parking.
     * @type {number}
     * @memberof Lot
     */
    'full_vat_price_parking_included'?: number;
    /**
     * Prix TVA aidé libre.
     * @type {number}
     * @memberof Lot
     */
    'full_vat_price_subsidized_parking_excepted'?: number;
    /**
     * Prix TVA pleine aidé avec parking.
     * @type {number}
     * @memberof Lot
     */
    'full_vat_price_subsidized_parking_included'?: number;
    /**
     * Prix TVA réduite.
     * @type {number}
     * @memberof Lot
     */
    'reduced_vat_price_parking_excepted'?: number;
    /**
     * Prix TVA réduite avec parking.
     * @type {number}
     * @memberof Lot
     */
    'reduced_vat_price_parking_included'?: number;
    /**
     * 
     * @type {LotBenefits}
     * @memberof Lot
     */
    'benefits'?: LotBenefits;
    /**
     * Etat de lot.
     * @type {string}
     * @memberof Lot
     */
    'state'?: LotStateEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof Lot
     */
    'funding'?: Array<string>;
}

export const LotTypeEnum = {
    T1: 'T1',
    T2: 'T2',
    T3: 'T3',
    T4: 'T4',
    T5: 'T5+'
} as const;

export type LotTypeEnum = typeof LotTypeEnum[keyof typeof LotTypeEnum];
export const LotStateEnum = {
    ForSale: 'FOR SALE',
    Sold: 'SOLD',
    Pull: 'PULL'
} as const;

export type LotStateEnum = typeof LotStateEnum[keyof typeof LotStateEnum];

/**
 * 
 * @export
 * @interface LotBenefits
 */
export interface LotBenefits {
    /**
     * Etage.
     * @type {number}
     * @memberof LotBenefits
     */
    'floor'?: number;
    /**
     * Dernier étage
     * @type {boolean}
     * @memberof LotBenefits
     */
    'last_floor'?: boolean;
    /**
     * Orientation.
     * @type {string}
     * @memberof LotBenefits
     */
    'orientation'?: string;
    /**
     * Vue mer
     * @type {boolean}
     * @memberof LotBenefits
     */
    'sea-view'?: boolean;
}
/**
 * A representation of an errorMessage
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * error Message
     * @type {string}
     * @memberof ModelError
     */
    'errorMessage'?: string;
}
/**
 * 
 * @export
 * @interface Pagination
 */
export interface Pagination {
    /**
     * Nombre total d\'éléments trouvés.
     * @type {number}
     * @memberof Pagination
     */
    'total'?: number;
    /**
     * Numéro de page.
     * @type {number}
     * @memberof Pagination
     */
    'page'?: number;
    /**
     * Taille de la page.
     * @type {number}
     * @memberof Pagination
     */
    'page_size'?: number;
}
/**
 * 
 * @export
 * @interface ProgramPrice
 */
export interface ProgramPrice {
    /**
     * Prix au m² TVA pleine libre..
     * @type {number}
     * @memberof ProgramPrice
     */
    'full_vat_price_per_meter_parking_excepted'?: number;
    /**
     * Prix au m² TVA pleine libre avec parking.
     * @type {number}
     * @memberof ProgramPrice
     */
    'full_vat_price_per_meter_parking_included'?: number;
    /**
     * Prix au m² TVA aidé libre.
     * @type {number}
     * @memberof ProgramPrice
     */
    'full_vat_price_per_meter_subsidized_parking_excepted'?: number;
    /**
     * Prix au m² TVA pleine aidé avec parking.
     * @type {number}
     * @memberof ProgramPrice
     */
    'full_vat_price_per_meter_subsidized_parking_included'?: number;
    /**
     * Prix au m² TVA réduite.
     * @type {number}
     * @memberof ProgramPrice
     */
    'reduced_vat_price_per_meter_parking_excepted'?: number;
    /**
     * Prix au m² TVA réduite avec parking.
     * @type {number}
     * @memberof ProgramPrice
     */
    'reduced_vat_price_per_meter_parking_included'?: number;
}
/**
 * 
 * @export
 * @interface ProgramRhythm
 */
export interface ProgramRhythm {
    /**
     * 
     * @type {Rhythm}
     * @memberof ProgramRhythm
     */
    'global'?: Rhythm;
    /**
     * 
     * @type {Rhythm}
     * @memberof ProgramRhythm
     */
    'start'?: Rhythm;
    /**
     * 
     * @type {Rhythm}
     * @memberof ProgramRhythm
     */
    'ninetieth'?: Rhythm;
    /**
     * 
     * @type {Rhythm}
     * @memberof ProgramRhythm
     */
    'crusing'?: Rhythm;
}
/**
 * 
 * @export
 * @interface Rhythm
 */
export interface Rhythm {
    /**
     * Taux d\'écoulement (en pourcentage).
     * @type {number}
     * @memberof Rhythm
     */
    'flow_rate'?: number;
    /**
     * Ventes par mois.
     * @type {number}
     * @memberof Rhythm
     */
    'sale_per_month'?: number;
    /**
     * Durée de commercialisation (en mois).
     * @type {number}
     * @memberof Rhythm
     */
    'duration'?: number;
}
/**
 * 
 * @export
 * @interface SearchListPrograms
 */
export interface SearchListPrograms {
    /**
     * Numéro de la page.
     * @type {number}
     * @memberof SearchListPrograms
     */
    'page'?: number;
    /**
     * Nombre maximum d\'éléments par page.
     * @type {number}
     * @memberof SearchListPrograms
     */
    'page_limit'?: number;
    /**
     * 
     * @type {SearchListProgramsFilters}
     * @memberof SearchListPrograms
     */
    'filters'?: SearchListProgramsFilters;
    /**
     * 
     * @type {SearchListProgramsGeometries}
     * @memberof SearchListPrograms
     */
    'geometries'?: SearchListProgramsGeometries;
}
/**
 * 
 * @export
 * @interface SearchListProgramsFilters
 */
export interface SearchListProgramsFilters {
    /**
     * Filtres clef/valeur.
     * @type {Array<GetLotsByProgramIDFiltersParameterInner>}
     * @memberof SearchListProgramsFilters
     */
    'key_value'?: Array<GetLotsByProgramIDFiltersParameterInner>;
    /**
     * Filtres Range.
     * @type {Array<SearchListProgramsFiltersRangeInner>}
     * @memberof SearchListProgramsFilters
     */
    'range'?: Array<SearchListProgramsFiltersRangeInner>;
}
/**
 * 
 * @export
 * @interface SearchListProgramsFiltersRangeInner
 */
export interface SearchListProgramsFiltersRangeInner {
    /**
     * 
     * @type {string}
     * @memberof SearchListProgramsFiltersRangeInner
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof SearchListProgramsFiltersRangeInner
     */
    'value_min': string;
    /**
     * 
     * @type {string}
     * @memberof SearchListProgramsFiltersRangeInner
     */
    'value_max': string;
}
/**
 * 
 * @export
 * @interface SearchListProgramsGeometries
 */
export interface SearchListProgramsGeometries {
    /**
     * 
     * @type {Circle}
     * @memberof SearchListProgramsGeometries
     */
    'circle'?: Circle;
    /**
     * 
     * @type {BoundingBox}
     * @memberof SearchListProgramsGeometries
     */
    'bounding_box'?: BoundingBox;
}
/**
 * 
 * @export
 * @interface SearchSuggestPrograms
 */
export interface SearchSuggestPrograms {
    /**
     * Numéro de la page.
     * @type {number}
     * @memberof SearchSuggestPrograms
     */
    'page'?: number;
    /**
     * Nombre maximum d\'éléments par page.
     * @type {number}
     * @memberof SearchSuggestPrograms
     */
    'page_limit'?: number;
    /**
     * Surface minimum des lots suggérés.
     * @type {number}
     * @memberof SearchSuggestPrograms
     */
    'area_min': number;
    /**
     * Surface maximum des lots suggérés.
     * @type {number}
     * @memberof SearchSuggestPrograms
     */
    'area_max': number;
    /**
     * Nombre de chambre des lots suggérés.
     * @type {number}
     * @memberof SearchSuggestPrograms
     */
    'rooms': number;
    /**
     * Type de résidence (COLLECTIF, INDIVIDUEL)
     * @type {string}
     * @memberof SearchSuggestPrograms
     */
    'construction_type'?: SearchSuggestProgramsConstructionTypeEnum;
    /**
     * 
     * @type {SearchListProgramsGeometries}
     * @memberof SearchSuggestPrograms
     */
    'geometries': SearchListProgramsGeometries;
}

export const SearchSuggestProgramsConstructionTypeEnum = {
    Collectif: 'COLLECTIF',
    Individuel: 'INDIVIDUEL'
} as const;

export type SearchSuggestProgramsConstructionTypeEnum = typeof SearchSuggestProgramsConstructionTypeEnum[keyof typeof SearchSuggestProgramsConstructionTypeEnum];

/**
 * 
 * @export
 * @interface SituationProgram
 */
export interface SituationProgram {
    /**
     * Id (uuid).
     * @type {string}
     * @memberof SituationProgram
     */
    'id'?: string;
    /**
     * Si recherche par cercle, la distance entre le centre du cercle et le programme (en mètre).
     * @type {number}
     * @memberof SituationProgram
     */
    'distance'?: number;
    /**
     * Nom.
     * @type {string}
     * @memberof SituationProgram
     */
    'name'?: string;
    /**
     * Url de l\'image de couverture
     * @type {string}
     * @memberof SituationProgram
     */
    'brochure_url'?: string;
    /**
     * Id du programme dans le SI promoteur.
     * @type {string}
     * @memberof SituationProgram
     */
    'promoter_program_id'?: string;
    /**
     * Nom du promoteur.
     * @type {string}
     * @memberof SituationProgram
     */
    'promoter_name'?: string;
    /**
     * Date de livraison (Format YYYY-MM-DD)
     * @type {string}
     * @memberof SituationProgram
     */
    'delivery_date'?: string;
    /**
     * Date de commercialisation (Format YYYY-MM-DD)
     * @type {string}
     * @memberof SituationProgram
     */
    'sale_date'?: string;
    /**
     * 
     * @type {Location}
     * @memberof SituationProgram
     */
    'location'?: Location;
    /**
     * 
     * @type {ProgramPrice}
     * @memberof SituationProgram
     */
    'price'?: ProgramPrice;
    /**
     * 
     * @type {ProgramRhythm}
     * @memberof SituationProgram
     */
    'rhythm'?: ProgramRhythm;
    /**
     * Type de construction (Forme Urbaine).
     * @type {string}
     * @memberof SituationProgram
     */
    'construction_type'?: SituationProgramConstructionTypeEnum;
    /**
     * Type de financement.
     * @type {string}
     * @memberof SituationProgram
     */
    'funding_type'?: string;
    /**
     * Nombre de lots total.
     * @type {number}
     * @memberof SituationProgram
     */
    'total_lots'?: number;
    /**
     * Nombre de lots disponnibles.
     * @type {number}
     * @memberof SituationProgram
     */
    'total_available_lots'?: number;
    /**
     * Part investisseur (en pourcentage).
     * @type {number}
     * @memberof SituationProgram
     */
    'investor_rate'?: number;
    /**
     * Eligible à la TVA réduite.
     * @type {boolean}
     * @memberof SituationProgram
     */
    'reduced_vat_zone'?: boolean;
    /**
     * Indice de confiance.
     * @type {number}
     * @memberof SituationProgram
     */
    'confidence_index'?: number;
    /**
     * 
     * @type {Typo}
     * @memberof SituationProgram
     */
    't1'?: Typo;
    /**
     * 
     * @type {Typo}
     * @memberof SituationProgram
     */
    't2'?: Typo;
    /**
     * 
     * @type {Typo}
     * @memberof SituationProgram
     */
    't3'?: Typo;
    /**
     * 
     * @type {Typo}
     * @memberof SituationProgram
     */
    't4'?: Typo;
    /**
     * 
     * @type {Typo}
     * @memberof SituationProgram
     */
    't5'?: Typo;
}

export const SituationProgramConstructionTypeEnum = {
    Collectif: 'COLLECTIF',
    Individuel: 'INDIVIDUEL'
} as const;

export type SituationProgramConstructionTypeEnum = typeof SituationProgramConstructionTypeEnum[keyof typeof SituationProgramConstructionTypeEnum];

/**
 * 
 * @export
 * @interface Typo
 */
export interface Typo {
    /**
     * 
     * @type {TypoVolume}
     * @memberof Typo
     */
    'volume'?: TypoVolume;
    /**
     * 
     * @type {TypoArea}
     * @memberof Typo
     */
    'living_area'?: TypoArea;
    /**
     * 
     * @type {TypoPrice}
     * @memberof Typo
     */
    'all_vat_price'?: TypoPrice;
    /**
     * 
     * @type {TypoPrice}
     * @memberof Typo
     */
    'full_vat_price'?: TypoPrice;
    /**
     * 
     * @type {TypoPrice}
     * @memberof Typo
     */
    'full_vat_price_subsidized'?: TypoPrice;
    /**
     * 
     * @type {TypoPrice}
     * @memberof Typo
     */
    'reduced_vat_price'?: TypoPrice;
}
/**
 * 
 * @export
 * @interface TypoArea
 */
export interface TypoArea {
    /**
     * Surface minimum
     * @type {number}
     * @memberof TypoArea
     */
    'min'?: number;
    /**
     * Surface moyenne
     * @type {number}
     * @memberof TypoArea
     */
    'avg'?: number;
    /**
     * Surface maximum
     * @type {number}
     * @memberof TypoArea
     */
    'max'?: number;
}
/**
 * 
 * @export
 * @interface TypoPrice
 */
export interface TypoPrice {
    /**
     * 
     * @type {TypoPriceMmm}
     * @memberof TypoPrice
     */
    'price_parking_included'?: TypoPriceMmm;
    /**
     * 
     * @type {TypoPriceMmm}
     * @memberof TypoPrice
     */
    'price_parking_excepted'?: TypoPriceMmm;
}
/**
 * 
 * @export
 * @interface TypoPriceMmm
 */
export interface TypoPriceMmm {
    /**
     * Prix maximum
     * @type {number}
     * @memberof TypoPriceMmm
     */
    'min'?: number;
    /**
     * Prix moyen
     * @type {number}
     * @memberof TypoPriceMmm
     */
    'avg'?: number;
    /**
     * Prix maximum
     * @type {number}
     * @memberof TypoPriceMmm
     */
    'max'?: number;
}
/**
 * 
 * @export
 * @interface TypoVolume
 */
export interface TypoVolume {
    /**
     * Stock Initial
     * @type {number}
     * @memberof TypoVolume
     */
    'initial_stock'?: number;
    /**
     * Offre
     * @type {number}
     * @memberof TypoVolume
     */
    'available_offer'?: number;
    /**
     * Vente
     * @type {number}
     * @memberof TypoVolume
     */
    'sale'?: number;
}

/**
 * ProgramsApi - axios parameter creator
 * @export
 */
export const ProgramsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Rechercher les lots d\'un programme par id programme et filtres (key/value).
         * @summary Rechercher les lots d\'un programme
         * @param {string} id ID (uuid)
         * @param {number} [page] Numéro de la page.
         * @param {number} [pageLimit] Nombre maximum d\&#39;éléments par page.
         * @param {Array<GetLotsByProgramIDFiltersParameterInner>} [filters] Filtres clef/valeur.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLotsByProgramID: async (id: string, page?: number, pageLimit?: number, filters?: Array<GetLotsByProgramIDFiltersParameterInner>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLotsByProgramID', 'id', id)
            const localVarPath = `/programs/{id}/lots`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page_limit'] = pageLimit;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Rechercher un programme par ID.
         * @summary Rechercher un programme par ID
         * @param {string} id ID (uuid)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramByID: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProgramByID', 'id', id)
            const localVarPath = `/programs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Rechercher des programmes à partir de filtres (key/value) ainsi que d\'objets géométriques (bbox, bpolygon, ...).
         * @summary Rechercher des programmes.
         * @param {SearchListPrograms} searchListPrograms Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPrograms: async (searchListPrograms: SearchListPrograms, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchListPrograms' is not null or undefined
            assertParamExists('listPrograms', 'searchListPrograms', searchListPrograms)
            const localVarPath = `/programs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchListPrograms, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Suggérer des lots à partir de critères.
         * @summary Suggérer des lots.
         * @param {SearchSuggestPrograms} searchSuggestPrograms Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suggestPrograms: async (searchSuggestPrograms: SearchSuggestPrograms, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchSuggestPrograms' is not null or undefined
            assertParamExists('suggestPrograms', 'searchSuggestPrograms', searchSuggestPrograms)
            const localVarPath = `/programs/suggest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchSuggestPrograms, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProgramsApi - functional programming interface
 * @export
 */
export const ProgramsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProgramsApiAxiosParamCreator(configuration)
    return {
        /**
         * Rechercher les lots d\'un programme par id programme et filtres (key/value).
         * @summary Rechercher les lots d\'un programme
         * @param {string} id ID (uuid)
         * @param {number} [page] Numéro de la page.
         * @param {number} [pageLimit] Nombre maximum d\&#39;éléments par page.
         * @param {Array<GetLotsByProgramIDFiltersParameterInner>} [filters] Filtres clef/valeur.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLotsByProgramID(id: string, page?: number, pageLimit?: number, filters?: Array<GetLotsByProgramIDFiltersParameterInner>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListLot>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLotsByProgramID(id, page, pageLimit, filters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Rechercher un programme par ID.
         * @summary Rechercher un programme par ID
         * @param {string} id ID (uuid)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProgramByID(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SituationProgram>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProgramByID(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Rechercher des programmes à partir de filtres (key/value) ainsi que d\'objets géométriques (bbox, bpolygon, ...).
         * @summary Rechercher des programmes.
         * @param {SearchListPrograms} searchListPrograms Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPrograms(searchListPrograms: SearchListPrograms, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListProgram>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPrograms(searchListPrograms, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Suggérer des lots à partir de critères.
         * @summary Suggérer des lots.
         * @param {SearchSuggestPrograms} searchSuggestPrograms Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async suggestPrograms(searchSuggestPrograms: SearchSuggestPrograms, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListAdsLot>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.suggestPrograms(searchSuggestPrograms, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProgramsApi - factory interface
 * @export
 */
export const ProgramsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProgramsApiFp(configuration)
    return {
        /**
         * Rechercher les lots d\'un programme par id programme et filtres (key/value).
         * @summary Rechercher les lots d\'un programme
         * @param {string} id ID (uuid)
         * @param {number} [page] Numéro de la page.
         * @param {number} [pageLimit] Nombre maximum d\&#39;éléments par page.
         * @param {Array<GetLotsByProgramIDFiltersParameterInner>} [filters] Filtres clef/valeur.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLotsByProgramID(id: string, page?: number, pageLimit?: number, filters?: Array<GetLotsByProgramIDFiltersParameterInner>, options?: any): AxiosPromise<ListLot> {
            return localVarFp.getLotsByProgramID(id, page, pageLimit, filters, options).then((request) => request(axios, basePath));
        },
        /**
         * Rechercher un programme par ID.
         * @summary Rechercher un programme par ID
         * @param {string} id ID (uuid)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramByID(id: string, options?: any): AxiosPromise<SituationProgram> {
            return localVarFp.getProgramByID(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Rechercher des programmes à partir de filtres (key/value) ainsi que d\'objets géométriques (bbox, bpolygon, ...).
         * @summary Rechercher des programmes.
         * @param {SearchListPrograms} searchListPrograms Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPrograms(searchListPrograms: SearchListPrograms, options?: any): AxiosPromise<ListProgram> {
            return localVarFp.listPrograms(searchListPrograms, options).then((request) => request(axios, basePath));
        },
        /**
         * Suggérer des lots à partir de critères.
         * @summary Suggérer des lots.
         * @param {SearchSuggestPrograms} searchSuggestPrograms Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suggestPrograms(searchSuggestPrograms: SearchSuggestPrograms, options?: any): AxiosPromise<ListAdsLot> {
            return localVarFp.suggestPrograms(searchSuggestPrograms, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProgramsApi - object-oriented interface
 * @export
 * @class ProgramsApi
 * @extends {BaseAPI}
 */
export class ProgramsApi extends BaseAPI {
    /**
     * Rechercher les lots d\'un programme par id programme et filtres (key/value).
     * @summary Rechercher les lots d\'un programme
     * @param {string} id ID (uuid)
     * @param {number} [page] Numéro de la page.
     * @param {number} [pageLimit] Nombre maximum d\&#39;éléments par page.
     * @param {Array<GetLotsByProgramIDFiltersParameterInner>} [filters] Filtres clef/valeur.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramsApi
     */
    public getLotsByProgramID(id: string, page?: number, pageLimit?: number, filters?: Array<GetLotsByProgramIDFiltersParameterInner>, options?: AxiosRequestConfig) {
        return ProgramsApiFp(this.configuration).getLotsByProgramID(id, page, pageLimit, filters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Rechercher un programme par ID.
     * @summary Rechercher un programme par ID
     * @param {string} id ID (uuid)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramsApi
     */
    public getProgramByID(id: string, options?: AxiosRequestConfig) {
        return ProgramsApiFp(this.configuration).getProgramByID(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Rechercher des programmes à partir de filtres (key/value) ainsi que d\'objets géométriques (bbox, bpolygon, ...).
     * @summary Rechercher des programmes.
     * @param {SearchListPrograms} searchListPrograms Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramsApi
     */
    public listPrograms(searchListPrograms: SearchListPrograms, options?: AxiosRequestConfig) {
        return ProgramsApiFp(this.configuration).listPrograms(searchListPrograms, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Suggérer des lots à partir de critères.
     * @summary Suggérer des lots.
     * @param {SearchSuggestPrograms} searchSuggestPrograms Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramsApi
     */
    public suggestPrograms(searchSuggestPrograms: SearchSuggestPrograms, options?: AxiosRequestConfig) {
        return ProgramsApiFp(this.configuration).suggestPrograms(searchSuggestPrograms, options).then((request) => request(this.axios, this.basePath));
    }
}


