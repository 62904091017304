<template>
  <b-card v-if="program && program.name">
    <div class="d-flex align-items-center justify-content-center">
      <b-img
        :src="program.brochure_url"
        :alt="`Image of ${program.name}`"
        class="program-img"
        fluid
      />
    </div>

    <div>
      <b-card-text>
        <b-row>
          <b-col cols="12">
            <h3 class="text-primary mt-1">
              {{ program.name }}
            </h3>
          </b-col>
          <b-col cols="12">
            <span>proposé par</span>
            <b-link>
              {{ program.promoter_name }}
            </b-link>
          </b-col>
        </b-row>
      </b-card-text>

      <hr class="my-50" />

      <div class="d-flex flex-wrap">
        <h4 class="mr-1">
          {{
            currencyPriceMeters(
              program.price.full_vat_price_per_meter_parking_excepted
            )
          }}
        </h4>
        <ul class="list-inline pl-1 border-left">
          Prix médian sur l'ensemble des lots.
        </ul>
      </div>

      <hr class="my-50" />

      <h6>{{ program.location.sector_name }}</h6>
      <h6>
        {{ program.location.street }} - {{ program.location.zip }} -
        {{ program.location.city }}
      </h6>

      <hr class="my-50" />

      <b-row>
        <b-col xs>
          <ul class="itemgrid-features list-unstyled">
            <li>
              <feather-icon icon="TrelloIcon" />
              <span>Type: {{ program.construction_type }}</span>
            </li>
            <li>
              <feather-icon icon="DollarSignIcon" />
              <span>Financement: {{ program.funding_type }}</span>
            </li>
          </ul>
        </b-col>
        <b-col xs>
          <ul class="itemgrid-features list-unstyled">
            <li>
              <feather-icon icon="SunriseIcon" />
              <span>Livraison: {{ program.delivery_date }}</span>
            </li>
            <li>
              <feather-icon icon="ShoppingBagIcon" />
              <span>Commercialisation: {{ program.sale_date }}</span>
            </li>
          </ul>
        </b-col>
        <b-col xs>
          <ul class="itemgrid-features list-unstyled">
            <li>
              <feather-icon icon="ShoppingCartIcon" />
              <span>Offre: {{ program.total_lots }} lots</span>
            </li>
            <li>
              <feather-icon icon="CodepenIcon" />
              <span>Disponible: {{ program.total_available_lots }} lots</span>
            </li>
          </ul>
        </b-col>
      </b-row>

      <hr class="my-50" />

      <div>
        <b-table :items="grid_lots" :fields="fields" small responsive>
        </b-table>
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BTable,
  BRow,
  BCol,
  BCard,
  BImg,
  BCardText,
  BLink,
} from "bootstrap-vue";

// Custom formater
import { currency, currencyPriceMeters } from "@core/utils/filter";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BCard,
    BImg,
    BCardText,
    BLink,
  },
  props: {
    program: {
      type: Object,
      required: true,
    },
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fields: [
        {
          key: "type",
          label: "Type",
          tdClass: "limit-col-width-3",
        },
        {
          key: "total",
          label: "Nombre de lots",
          tdClass: "limit-col-width-5",
        },
        {
          key: "area_min",
          label: "Surface minimum",
          formatter: (value) => `${value} m²`,
        },
        {
          key: "area_max",
          label: "Surface maximum",
          formatter: (value) => `${value} m²`,
        },
        {
          key: "price_min",
          label: "Prix minimum",
          formatter: (value) => `${currency(value)}`,
        },
        {
          key: "price_max",
          label: "Prix maximum",
          formatter: (value) => `${currency(value)}`,
        },
      ],
    };
  },
  computed: {
    grid_lots() {
      const types = ["t1", "t2", "t3", "t4", "t5"];
      const grid_lots = [];
      for (const type of types) {
        if (this.program[type].volume.initial_stock > 0) {
          const lot = {
            type,
            total: `${this.program[type].volume.available_offer}/${this.program[type].volume.initial_stock}`,
            area_min: this.program[type].living_area.min
              .toString()
              .split(".")[0],
            area_max: this.program[type].living_area.max
              .toString()
              .split(".")[0],
            price_min:
              this.program[type].full_vat_price.price_parking_excepted.min,
            price_max:
              this.program[type].full_vat_price.price_parking_excepted.max,
          };
          grid_lots.push(lot);
        }
      }

      return grid_lots;
    },
  },
  methods: {
    currency,
    currencyPriceMeters,
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/bootstrap-extended/variables";

.program-img {
  max-height: 300px;
  @media (max-width: 1600px) {
    max-height: 125px !important;
  }
}

.itemgrid-features {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 12px;
  li {
    svg,
    i {
      height: 1.4rem;
      width: 1.4rem;
      font-size: 1.4rem;
      margin-right: 0.75rem;
    }
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    span {
      font-weight: $font-weight-bolder;
    }
  }
}
</style>
