/* tslint:disable */
/* eslint-disable */
/**
 * API RealEstate V2 (full version)
 * L\'API RealEstate permet d\'accéder et de rechercher des annonces immobilières géolocalisées ainsi que leurs descriptifs.  **Exemple d\'intégration** : [Découvrez notre exemple de portail d\'annonces](https://bo-staging.hello-cadredevie.fr/realestate/demo/searcher)  # Authentication  Authentification par token JWT généré à partir du backoffice client \'Cadre de Vie\'.  <!-- ReDoc-Inject: <security-definitions> -->
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: cdv-si@cadredevie.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Activity
 */
export interface Activity {
    /**
     * CDV’s DB reference
     * @type {number}
     * @memberof Activity
     */
    'id': number;
    /**
     * Last updated date (YYYY-MM-DD)
     * @type {string}
     * @memberof Activity
     */
    'update_at': string;
}
/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * Street
     * @type {string}
     * @memberof Address
     */
    'street': string;
    /**
     * Zipcode
     * @type {string}
     * @memberof Address
     */
    'zip': string;
    /**
     * City
     * @type {string}
     * @memberof Address
     */
    'city': string;
    /**
     * Country
     * @type {string}
     * @memberof Address
     */
    'country': string;
}
/**
 * 
 * @export
 * @interface AddressLocation
 */
export interface AddressLocation {
    /**
     * Street
     * @type {string}
     * @memberof AddressLocation
     */
    'street': string;
    /**
     * Zipcode
     * @type {string}
     * @memberof AddressLocation
     */
    'zip': string;
    /**
     * City
     * @type {string}
     * @memberof AddressLocation
     */
    'city': string;
    /**
     * Country
     * @type {string}
     * @memberof AddressLocation
     */
    'country': string;
    /**
     * 
     * @type {Location}
     * @memberof AddressLocation
     */
    'location': Location;
}
/**
 * 
 * @export
 * @interface AgendaProgram
 */
export interface AgendaProgram {
    /**
     * Start delivery date (Format ISO 8601)
     * @type {string}
     * @memberof AgendaProgram
     */
    'delivery_date': string;
    /**
     * Start commercial date (Format ISO 8601)
     * @type {string}
     * @memberof AgendaProgram
     */
    'commercial_launch'?: string;
    /**
     * Start launch date (Format ISO 8601)
     * @type {string}
     * @memberof AgendaProgram
     */
    'start_launch'?: string;
}
/**
 * 
 * @export
 * @interface AgendaSCPI
 */
export interface AgendaSCPI {
    /**
     * Date de création (Format ISO 8601)
     * @type {string}
     * @memberof AgendaSCPI
     */
    'creation_date': string;
    /**
     * Terme statutaire (Format ISO 8601)
     * @type {string}
     * @memberof AgendaSCPI
     */
    'statutory_term': string;
}
/**
 * 
 * @export
 * @interface Annexe
 */
export interface Annexe {
    /**
     * identifiant du lot annexe
     * @type {string}
     * @memberof Annexe
     */
    'id'?: string;
    /**
     * référence du lot annexe
     * @type {string}
     * @memberof Annexe
     */
    'reference'?: string;
    /**
     * type du lot annexe
     * @type {string}
     * @memberof Annexe
     */
    'type'?: string;
    /**
     * Description du lot annexe
     * @type {string}
     * @memberof Annexe
     */
    'description'?: string;
    /**
     * Prix du lot annexe
     * @type {number}
     * @memberof Annexe
     */
    'price'?: number;
    /**
     * Surface du lot annexe
     * @type {number}
     * @memberof Annexe
     */
    'area'?: number;
    /**
     * Availability description
     * @type {string}
     * @memberof Annexe
     */
    'availability'?: string;
}
/**
 * 
 * @export
 * @interface AnnexesProperty
 */
export interface AnnexesProperty {
    /**
     * 
     * @type {Array<Annexe>}
     * @memberof AnnexesProperty
     */
    'annexes'?: Array<Annexe>;
}
/**
 * 
 * @export
 * @interface Area
 */
export interface Area {
    /**
     * Total area
     * @type {number}
     * @memberof Area
     */
    'area': number;
    /**
     * Stay area
     * @type {number}
     * @memberof Area
     */
    'stay_area'?: number;
    /**
     * Terrace area
     * @type {number}
     * @memberof Area
     */
    'terrace_area'?: number;
    /**
     * Balcony area
     * @type {number}
     * @memberof Area
     */
    'balcony_area'?: number;
    /**
     * Garden area
     * @type {number}
     * @memberof Area
     */
    'garden_area'?: number;
}
/**
 * 
 * @export
 * @interface ClusterMap
 */
export interface ClusterMap {
    /**
     * Number of objects in the cluster
     * @type {number}
     * @memberof ClusterMap
     */
    'count'?: number;
    /**
     * GPS lat of the center of the cluster
     * @type {number}
     * @memberof ClusterMap
     */
    'lat'?: number;
    /**
     * GPS lng of the center of the cluster
     * @type {number}
     * @memberof ClusterMap
     */
    'lng'?: number;
    /**
     * GPS min lat of the cluster
     * @type {number}
     * @memberof ClusterMap
     */
    'minlat'?: number;
    /**
     * GPS min lng of the cluster
     * @type {number}
     * @memberof ClusterMap
     */
    'minlng'?: number;
    /**
     * GPS max lat of the cluster
     * @type {number}
     * @memberof ClusterMap
     */
    'maxlat'?: number;
    /**
     * GPS max lng of the cluster
     * @type {number}
     * @memberof ClusterMap
     */
    'maxlng'?: number;
    /**
     * Min cluster items prices
     * @type {number}
     * @memberof ClusterMap
     */
    'price'?: number;
    /**
     * Array of item IDs
     * @type {Array<number>}
     * @memberof ClusterMap
     */
    'itemIds'?: Array<number>;
    /**
     * Is the cluster expandable when the geo_precision parameter growth? (limited to geo_precision 9)
     * @type {boolean}
     * @memberof ClusterMap
     */
    'singlePoint'?: boolean;
}
/**
 * 
 * @export
 * @interface ContactProperty
 */
export interface ContactProperty {
    /**
     * Commercial or usual name
     * @type {string}
     * @memberof ContactProperty
     */
    'name': string;
    /**
     * contact e-mail
     * @type {string}
     * @memberof ContactProperty
     */
    'email': string;
    /**
     * contact phone
     * @type {string}
     * @memberof ContactProperty
     */
    'phone'?: string;
    /**
     * Address of the agency
     * @type {string}
     * @memberof ContactProperty
     */
    'street': string;
    /**
     * City location of the property
     * @type {string}
     * @memberof ContactProperty
     */
    'city': string;
    /**
     * Zipcode of the city
     * @type {string}
     * @memberof ContactProperty
     */
    'zip': string;
    /**
     * Country
     * @type {string}
     * @memberof ContactProperty
     */
    'country': string;
}
/**
 * 
 * @export
 * @interface ContactShort
 */
export interface ContactShort {
    /**
     * Professionnal name
     * @type {string}
     * @memberof ContactShort
     */
    'name': string;
    /**
     * Professionnal e-mail
     * @type {string}
     * @memberof ContactShort
     */
    'email': string;
    /**
     * Professionnal phone
     * @type {string}
     * @memberof ContactShort
     */
    'phone': string;
}
/**
 * 
 * @export
 * @interface Crn
 */
export interface Crn {
    /**
     * CRN Number
     * @type {string}
     * @memberof Crn
     */
    'number': string;
    /**
     * City
     * @type {string}
     * @memberof Crn
     */
    'city': string;
}
/**
 * 
 * @export
 * @interface Description
 */
export interface Description {
    /**
     * Long description format
     * @type {string}
     * @memberof Description
     */
    'text'?: string;
    /**
     * URL program details
     * @type {string}
     * @memberof Description
     */
    'url'?: string;
    /**
     * URL program virtual visit
     * @type {string}
     * @memberof Description
     */
    'virtual_visit'?: string;
}
/**
 * 
 * @export
 * @interface Developer
 */
export interface Developer {
    /**
     * CDV’s DB reference
     * @type {number}
     * @memberof Developer
     */
    'id'?: number;
    /**
     * Last update date (Format ISO 8601)
     * @type {string}
     * @memberof Developer
     */
    'update_at'?: string;
    /**
     * Name
     * @type {string}
     * @memberof Developer
     */
    'name': string;
    /**
     * Label
     * @type {string}
     * @memberof Developer
     */
    'label': string;
    /**
     * URL Web-Site
     * @type {string}
     * @memberof Developer
     */
    'web'?: string;
    /**
     * URL of the logo
     * @type {string}
     * @memberof Developer
     */
    'logo'?: string;
    /**
     * 
     * @type {Crn}
     * @memberof Developer
     */
    'crn': Crn;
    /**
     * 
     * @type {Address}
     * @memberof Developer
     */
    'address': Address;
    /**
     * 
     * @type {ContactShort}
     * @memberof Developer
     */
    'contact': ContactShort;
}
/**
 * 
 * @export
 * @interface DeveloperAllOf
 */
export interface DeveloperAllOf {
    /**
     * CDV’s DB reference
     * @type {number}
     * @memberof DeveloperAllOf
     */
    'id'?: number;
    /**
     * Last update date (Format ISO 8601)
     * @type {string}
     * @memberof DeveloperAllOf
     */
    'update_at'?: string;
}
/**
 * 
 * @export
 * @interface DeveloperIn
 */
export interface DeveloperIn {
    /**
     * Name
     * @type {string}
     * @memberof DeveloperIn
     */
    'name': string;
    /**
     * Label
     * @type {string}
     * @memberof DeveloperIn
     */
    'label': string;
    /**
     * URL Web-Site
     * @type {string}
     * @memberof DeveloperIn
     */
    'web'?: string;
    /**
     * URL of the logo
     * @type {string}
     * @memberof DeveloperIn
     */
    'logo'?: string;
    /**
     * 
     * @type {Crn}
     * @memberof DeveloperIn
     */
    'crn': Crn;
    /**
     * 
     * @type {Address}
     * @memberof DeveloperIn
     */
    'address': Address;
    /**
     * 
     * @type {ContactShort}
     * @memberof DeveloperIn
     */
    'contact': ContactShort;
}
/**
 * 
 * @export
 * @interface Energy
 */
export interface Energy {
    /**
     * heating_type
     * @type {string}
     * @memberof Energy
     */
    'heating_type'?: EnergyHeatingTypeEnum;
    /**
     * heating_energy
     * @type {string}
     * @memberof Energy
     */
    'heating_energy'?: EnergyHeatingEnergyEnum;
    /**
     * heating_mechanism
     * @type {string}
     * @memberof Energy
     */
    'heating_mechanism'?: string;
    /**
     * Energy consumption label (legal notice)
     * @type {string}
     * @memberof Energy
     */
    'dpe_label_consumption'?: EnergyDpeLabelConsumptionEnum;
    /**
     * Energy consumption vale (legal notice)
     * @type {number}
     * @memberof Energy
     */
    'dpe_value_consumption'?: number;
    /**
     * Gas emission label (legal notice)
     * @type {string}
     * @memberof Energy
     */
    'dpe_label_gaz'?: EnergyDpeLabelGazEnum;
    /**
     * Gas emission vale (legal notice)
     * @type {number}
     * @memberof Energy
     */
    'dpe_value_gaz'?: number;
}

export const EnergyHeatingTypeEnum = {
    Individuel: 'individuel',
    Collectif: 'collectif'
} as const;

export type EnergyHeatingTypeEnum = typeof EnergyHeatingTypeEnum[keyof typeof EnergyHeatingTypeEnum];
export const EnergyHeatingEnergyEnum = {
    Gaz: 'gaz',
    Electricity: 'electricity'
} as const;

export type EnergyHeatingEnergyEnum = typeof EnergyHeatingEnergyEnum[keyof typeof EnergyHeatingEnergyEnum];
export const EnergyDpeLabelConsumptionEnum = {
    A: 'A',
    B: 'B',
    C: 'C',
    D: 'D',
    E: 'E',
    F: 'F',
    G: 'G'
} as const;

export type EnergyDpeLabelConsumptionEnum = typeof EnergyDpeLabelConsumptionEnum[keyof typeof EnergyDpeLabelConsumptionEnum];
export const EnergyDpeLabelGazEnum = {
    A: 'A',
    B: 'B',
    C: 'C',
    D: 'D',
    E: 'E',
    F: 'F',
    G: 'G'
} as const;

export type EnergyDpeLabelGazEnum = typeof EnergyDpeLabelGazEnum[keyof typeof EnergyDpeLabelGazEnum];

/**
 * 
 * @export
 * @interface EnergyProgram
 */
export interface EnergyProgram {
    /**
     * heating_type
     * @type {string}
     * @memberof EnergyProgram
     */
    'heating_type'?: EnergyProgramHeatingTypeEnum;
    /**
     * heating_energy
     * @type {string}
     * @memberof EnergyProgram
     */
    'heating_energy'?: EnergyProgramHeatingEnergyEnum;
    /**
     * heating_mechanism
     * @type {string}
     * @memberof EnergyProgram
     */
    'heating_mechanism'?: string;
}

export const EnergyProgramHeatingTypeEnum = {
    Individuel: 'individuel',
    Collectif: 'collectif'
} as const;

export type EnergyProgramHeatingTypeEnum = typeof EnergyProgramHeatingTypeEnum[keyof typeof EnergyProgramHeatingTypeEnum];
export const EnergyProgramHeatingEnergyEnum = {
    Gaz: 'gaz',
    Electricity: 'electricity'
} as const;

export type EnergyProgramHeatingEnergyEnum = typeof EnergyProgramHeatingEnergyEnum[keyof typeof EnergyProgramHeatingEnergyEnum];

/**
 * 
 * @export
 * @interface Features
 */
export interface Features {
    /**
     * kitchen
     * @type {string}
     * @memberof Features
     */
    'kitchen'?: FeaturesKitchenEnum;
    /**
     * has terrace
     * @type {boolean}
     * @memberof Features
     */
    'terrace'?: boolean;
    /**
     * is furnished
     * @type {boolean}
     * @memberof Features
     */
    'furnished'?: boolean;
    /**
     * has cellar
     * @type {boolean}
     * @memberof Features
     */
    'cellar'?: boolean;
    /**
     * has elevator
     * @type {boolean}
     * @memberof Features
     */
    'elevator'?: boolean;
    /**
     * has fireplace
     * @type {boolean}
     * @memberof Features
     */
    'fireplace'?: boolean;
    /**
     * has veranda
     * @type {boolean}
     * @memberof Features
     */
    'veranda'?: boolean;
    /**
     * has garage
     * @type {boolean}
     * @memberof Features
     */
    'garage'?: boolean;
    /**
     * has swimming_pool
     * @type {boolean}
     * @memberof Features
     */
    'swimming_pool'?: boolean;
    /**
     * has green_spaces
     * @type {boolean}
     * @memberof Features
     */
    'green_spaces'?: boolean;
    /**
     * has loggia
     * @type {boolean}
     * @memberof Features
     */
    'loggia'?: boolean;
    /**
     * has balcony
     * @type {boolean}
     * @memberof Features
     */
    'balcony'?: boolean;
    /**
     * has private_garden
     * @type {boolean}
     * @memberof Features
     */
    'private_garden'?: boolean;
    /**
     * has videocom
     * @type {boolean}
     * @memberof Features
     */
    'videocom'?: boolean;
    /**
     * has fibre
     * @type {string}
     * @memberof Features
     */
    'fibre'?: string;
}

export const FeaturesKitchenEnum = {
    Equip: 'equipé',
    Americaine: 'americaine',
    Ouverte: 'ouverte',
    Autre: 'autre'
} as const;

export type FeaturesKitchenEnum = typeof FeaturesKitchenEnum[keyof typeof FeaturesKitchenEnum];

/**
 * 
 * @export
 * @interface FeaturesProgram
 */
export interface FeaturesProgram {
    /**
     * Number of properties
     * @type {number}
     * @memberof FeaturesProgram
     */
    'nb_properties'?: number;
    /**
     * Number of available properties
     * @type {number}
     * @memberof FeaturesProgram
     */
    'nb_available_properties'?: number;
    /**
     * has elevator
     * @type {boolean}
     * @memberof FeaturesProgram
     */
    'elevator'?: boolean;
    /**
     * 
     * @type {MinMax}
     * @memberof FeaturesProgram
     */
    'areas': MinMax;
    /**
     * 
     * @type {MinMax}
     * @memberof FeaturesProgram
     */
    'prices': MinMax;
    /**
     * Grid of property type (important for nb_room filter) (ex=2,4 for program with T2 and T4)
     * @type {string}
     * @memberof FeaturesProgram
     */
    'nb_room': string;
    /**
     * 
     * @type {MinMax}
     * @memberof FeaturesProgram
     */
    'rooms'?: MinMax;
    /**
     * 
     * @type {string}
     * @memberof FeaturesProgram
     */
    'label'?: FeaturesProgramLabelEnum;
    /**
     * Tax exemptions
     * @type {Array<string>}
     * @memberof FeaturesProgram
     */
    'tax_exemptions': Array<FeaturesProgramTaxExemptionsEnum>;
    /**
     * 
     * @type {EnergyProgram}
     * @memberof FeaturesProgram
     */
    'energy'?: EnergyProgram;
}

export const FeaturesProgramLabelEnum = {
    Re2025: 're_2025',
    Rt2012: 'rt_2012',
    Bbc: 'bbc'
} as const;

export type FeaturesProgramLabelEnum = typeof FeaturesProgramLabelEnum[keyof typeof FeaturesProgramLabelEnum];
export const FeaturesProgramTaxExemptionsEnum = {
    Cosse: 'cosse',
    DeficitFoncier: 'deficit_foncier',
    Demembrement: 'demembrement',
    Denormandie: 'denormandie',
    Ehpad: 'ehpad',
    LmnpLmp: 'lmnp_lmp',
    Malraux: 'malraux',
    MonumentHistorique: 'monument_historique',
    OffreCelda: 'offre-celda',
    Pinel: 'pinel',
    PinelPlus: 'pinel_plus',
    ResidencePrincipale: 'residence_principale',
    LocatifHorsDispositif: 'locatif_hors_dispositif',
    Ptz: 'ptz'
} as const;

export type FeaturesProgramTaxExemptionsEnum = typeof FeaturesProgramTaxExemptionsEnum[keyof typeof FeaturesProgramTaxExemptionsEnum];

/**
 * 
 * @export
 * @interface FeaturesProgramShort
 */
export interface FeaturesProgramShort {
    /**
     * Number of properties
     * @type {number}
     * @memberof FeaturesProgramShort
     */
    'nb_properties'?: number;
    /**
     * Number of available properties
     * @type {number}
     * @memberof FeaturesProgramShort
     */
    'nb_available_properties'?: number;
    /**
     * 
     * @type {MinMax}
     * @memberof FeaturesProgramShort
     */
    'areas'?: MinMax;
    /**
     * 
     * @type {MinMax}
     * @memberof FeaturesProgramShort
     */
    'prices'?: MinMax;
    /**
     * Grid of property type (important for nb_room filter) (ex=2,4 for program with T2 and T4)
     * @type {string}
     * @memberof FeaturesProgramShort
     */
    'nb_room'?: string;
    /**
     * 
     * @type {MinMax}
     * @memberof FeaturesProgramShort
     */
    'rooms'?: MinMax;
    /**
     * 
     * @type {string}
     * @memberof FeaturesProgramShort
     */
    'label'?: FeaturesProgramShortLabelEnum;
    /**
     * Tax exemptions
     * @type {Array<string>}
     * @memberof FeaturesProgramShort
     */
    'tax_exemptions'?: Array<FeaturesProgramShortTaxExemptionsEnum>;
}

export const FeaturesProgramShortLabelEnum = {
    Re2025: 're_2025',
    Rt2012: 'rt_2012',
    Bbc: 'bbc'
} as const;

export type FeaturesProgramShortLabelEnum = typeof FeaturesProgramShortLabelEnum[keyof typeof FeaturesProgramShortLabelEnum];
export const FeaturesProgramShortTaxExemptionsEnum = {
    Cosse: 'cosse',
    DeficitFoncier: 'deficit_foncier',
    Demembrement: 'demembrement',
    Denormandie: 'denormandie',
    Ehpad: 'ehpad',
    LmnpLmp: 'lmnp_lmp',
    Malraux: 'malraux',
    MonumentHistorique: 'monument_historique',
    OffreCelda: 'offre-celda',
    Pinel: 'pinel',
    PinelPlus: 'pinel_plus',
    ResidencePrincipale: 'residence_principale',
    LocatifHorsDispositif: 'locatif_hors_dispositif',
    Ptz: 'ptz'
} as const;

export type FeaturesProgramShortTaxExemptionsEnum = typeof FeaturesProgramShortTaxExemptionsEnum[keyof typeof FeaturesProgramShortTaxExemptionsEnum];

/**
 * 
 * @export
 * @interface Fees
 */
export interface Fees {
    /**
     * Price excluding fees
     * @type {number}
     * @memberof Fees
     */
    'price_excl_fees'?: number;
    /**
     * Label of the payer(s) (in French)
     * @type {string}
     * @memberof Fees
     */
    'payer_fees'?: string;
    /**
     * Must the buyer pay the fees?
     * @type {boolean}
     * @memberof Fees
     */
    'buyer_fees'?: boolean;
    /**
     * Must the seller pay the fees?
     * @type {boolean}
     * @memberof Fees
     */
    'seller_fees'?: boolean;
    /**
     * Percentage fees payed by the buyer (\"5.2\" is 5.2%)
     * @type {number}
     * @memberof Fees
     */
    'percentage_buyer_fees'?: number;
    /**
     * Percentage fees payed by the seller (\"5.2\" is 5.2%)
     * @type {number}
     * @memberof Fees
     */
    'percentage_seller_fees'?: number;
    /**
     * Additionnal cost to the rent price
     * @type {number}
     * @memberof Fees
     */
    'charges'?: number;
    /**
     * Cost of the guarantee
     * @type {number}
     * @memberof Fees
     */
    'guarantee_deposit'?: number;
    /**
     * URL of the realtor public tariff (legal notice)
     * @type {string}
     * @memberof Fees
     */
    'url_public_tariff'?: string;
}
/**
 * 
 * @export
 * @interface GeoBoundingBox
 */
export interface GeoBoundingBox {
    /**
     * Latitude
     * @type {number}
     * @memberof GeoBoundingBox
     */
    'lat': number;
    /**
     * Longitude
     * @type {number}
     * @memberof GeoBoundingBox
     */
    'lng': number;
}
/**
 * 
 * @export
 * @interface GeoDistances
 */
export interface GeoDistances {
    /**
     * Latitude
     * @type {number}
     * @memberof GeoDistances
     */
    'lat': number;
    /**
     * Longitude
     * @type {number}
     * @memberof GeoDistances
     */
    'lng': number;
    /**
     * Distance (km)
     * @type {number}
     * @memberof GeoDistances
     */
    'distance': number;
}
/**
 * 
 * @export
 * @interface KeyNumbers
 */
export interface KeyNumbers {
    /**
     * Subscription price
     * @type {number}
     * @memberof KeyNumbers
     */
    'subscription_price': number;
    /**
     * Subscription fees
     * @type {number}
     * @memberof KeyNumbers
     */
    'subscription_fees': number;
    /**
     * Nominal value
     * @type {number}
     * @memberof KeyNumbers
     */
    'nominal_value': number;
    /**
     * Format ISO 8601
     * @type {string}
     * @memberof KeyNumbers
     */
    'nominal_value_update_date': string;
    /**
     * Bonus
     * @type {number}
     * @memberof KeyNumbers
     */
    'bonus': number;
    /**
     * Income payment dates
     * @type {string}
     * @memberof KeyNumbers
     */
    'income_payment_dates': string;
    /**
     * Subscription minimal piece
     * @type {number}
     * @memberof KeyNumbers
     */
    'subscription_min_piece': number;
    /**
     * Subscription minimal value
     * @type {number}
     * @memberof KeyNumbers
     */
    'subscription_min_value': number;
    /**
     * Enjoyment delay
     * @type {number}
     * @memberof KeyNumbers
     */
    'enjoyment_delay': number;
    /**
     * Withdrawal value
     * @type {number}
     * @memberof KeyNumbers
     */
    'withdrawal_value': number;
    /**
     * Format ISO 8601
     * @type {string}
     * @memberof KeyNumbers
     */
    'withdrawal_value_update_date': string;
    /**
     * Management fees
     * @type {number}
     * @memberof KeyNumbers
     */
    'management_fees': number;
}
/**
 * 
 * @export
 * @interface Kpi
 */
export interface Kpi {
    /**
     * Date de référence pour les indicateurs de performance (Format ISO 8601)
     * @type {string}
     * @memberof Kpi
     */
    'reference_date': string;
    /**
     * Taux de rendement interne TRI à 5 ans
     * @type {number}
     * @memberof Kpi
     */
    'tri5': number;
    /**
     * Taux de rendement interne TRI à 10 ans
     * @type {number}
     * @memberof Kpi
     */
    'tri10': number;
    /**
     * Taux de rendement interne TRI à 15 ans
     * @type {number}
     * @memberof Kpi
     */
    'tri15': number;
    /**
     * Taux de distribution sur valeur de marché
     * @type {number}
     * @memberof Kpi
     */
    'tdvm': number;
    /**
     * Revenu distribué par part
     * @type {number}
     * @memberof Kpi
     */
    'income': number;
    /**
     * Variation du prix acquéreur moyen
     * @type {number}
     * @memberof Kpi
     */
    'vpm': number;
}
/**
 * 
 * @export
 * @interface Location
 */
export interface Location {
    /**
     * Latitude
     * @type {number}
     * @memberof Location
     */
    'lat': number;
    /**
     * Longitude
     * @type {number}
     * @memberof Location
     */
    'lng': number;
}
/**
 * 
 * @export
 * @interface Manager
 */
export interface Manager {
    /**
     * CDV’s DB reference
     * @type {number}
     * @memberof Manager
     */
    'id'?: number;
    /**
     * Last update date (Format ISO 8601)
     * @type {string}
     * @memberof Manager
     */
    'update_at'?: string;
    /**
     * Name
     * @type {string}
     * @memberof Manager
     */
    'name': string;
    /**
     * Label
     * @type {string}
     * @memberof Manager
     */
    'label': string;
    /**
     * URL Web-Site
     * @type {string}
     * @memberof Manager
     */
    'web'?: string;
    /**
     * URL of the logo
     * @type {string}
     * @memberof Manager
     */
    'logo'?: string;
    /**
     * 
     * @type {Crn}
     * @memberof Manager
     */
    'crn': Crn;
    /**
     * 
     * @type {Address}
     * @memberof Manager
     */
    'address': Address;
    /**
     * 
     * @type {ContactShort}
     * @memberof Manager
     */
    'contact': ContactShort;
}
/**
 * 
 * @export
 * @interface ManagerIn
 */
export interface ManagerIn {
    /**
     * Name
     * @type {string}
     * @memberof ManagerIn
     */
    'name': string;
    /**
     * Label
     * @type {string}
     * @memberof ManagerIn
     */
    'label': string;
    /**
     * URL Web-Site
     * @type {string}
     * @memberof ManagerIn
     */
    'web'?: string;
    /**
     * URL of the logo
     * @type {string}
     * @memberof ManagerIn
     */
    'logo'?: string;
    /**
     * 
     * @type {Crn}
     * @memberof ManagerIn
     */
    'crn': Crn;
    /**
     * 
     * @type {Address}
     * @memberof ManagerIn
     */
    'address': Address;
    /**
     * 
     * @type {ContactShort}
     * @memberof ManagerIn
     */
    'contact': ContactShort;
}
/**
 * 
 * @export
 * @interface Media
 */
export interface Media {
    /**
     * CDV’s DB reference
     * @type {number}
     * @memberof Media
     */
    'id'?: number;
    /**
     * order preference to show
     * @type {number}
     * @memberof Media
     */
    'order'?: number;
    /**
     * URL of media
     * @type {string}
     * @memberof Media
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface Metric
 */
export interface Metric {
    /**
     * Date time interval
     * @type {string}
     * @memberof Metric
     */
    'time'?: string;
    /**
     * Http request count in time interval
     * @type {number}
     * @memberof Metric
     */
    'count'?: number;
}
/**
 * 
 * @export
 * @interface MinMax
 */
export interface MinMax {
    /**
     * Min value
     * @type {number}
     * @memberof MinMax
     */
    'min': number;
    /**
     * Max value
     * @type {number}
     * @memberof MinMax
     */
    'max': number;
}
/**
 * A representation of an errorMessage
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * error Message
     * @type {string}
     * @memberof ModelError
     */
    'errorMessage'?: string;
}
/**
 * 
 * @export
 * @interface ParamsProgramsLink
 */
export interface ParamsProgramsLink {
    /**
     * New program id
     * @type {number}
     * @memberof ParamsProgramsLink
     */
    'new_program_id': number;
}
/**
 * 
 * @export
 * @interface Patrimony
 */
export interface Patrimony {
    /**
     * Date référence pour la composition du patrimoine (Format ISO 8601)
     * @type {string}
     * @memberof Patrimony
     */
    'reference_date': string;
    /**
     * Taux moyen annuel occupation financier
     * @type {number}
     * @memberof Patrimony
     */
    'tof': number;
    /**
     * Taux moyen annuel occupation physique
     * @type {number}
     * @memberof Patrimony
     */
    'top': number;
    /**
     * Taux moyen annuel de vacances
     * @type {number}
     * @memberof Patrimony
     */
    'vacancy_rate': number;
    /**
     * Nombre de sites
     * @type {number}
     * @memberof Patrimony
     */
    'nb_sites': number;
    /**
     * Nombre de baux
     * @type {number}
     * @memberof Patrimony
     */
    'nb_leases': number;
    /**
     * Surface en m²
     * @type {number}
     * @memberof Patrimony
     */
    'area': number;
    /**
     * Répartition économique
     * @type {string}
     * @memberof Patrimony
     */
    'financial_distribution': string;
    /**
     * Répartition géographique
     * @type {string}
     * @memberof Patrimony
     */
    'geo_distribution': string;
}
/**
 * 
 * @export
 * @interface PriceDetails
 */
export interface PriceDetails {
    /**
     * Property furniture price ttc
     * @type {number}
     * @memberof PriceDetails
     */
    'furniture_price_ttc'?: number;
    /**
     * Property price ttc
     * @type {number}
     * @memberof PriceDetails
     */
    'property_price_ttc'?: number;
    /**
     * Property furniture price ttc
     * @type {number}
     * @memberof PriceDetails
     */
    'furniture_price_ht'?: number;
    /**
     * Property price ttc
     * @type {number}
     * @memberof PriceDetails
     */
    'property_price_ht'?: number;
    /**
     * Tax rate
     * @type {number}
     * @memberof PriceDetails
     */
    'tax_rate'?: number;
    /**
     * Profitability rate
     * @type {number}
     * @memberof PriceDetails
     */
    'profit_rate'?: number;
    /**
     * Renovation rate
     * @type {number}
     * @memberof PriceDetails
     */
    'renovation_rate'?: number;
}
/**
 * 
 * @export
 * @interface Program
 */
export interface Program {
    /**
     * CDV’s DB reference
     * @type {number}
     * @memberof Program
     */
    'id'?: number;
    /**
     * Create date (Format ISO 8601)
     * @type {string}
     * @memberof Program
     */
    'created_at'?: string;
    /**
     * Last update date (Format ISO 8601)
     * @type {string}
     * @memberof Program
     */
    'updated_at'?: string;
    /**
     * True if program from flow is linked with a manualy created program.
     * @type {boolean}
     * @memberof Program
     */
    'linked_flow'?: boolean;
    /**
     * 
     * @type {Developer}
     * @memberof Program
     */
    'developer'?: Developer;
    /**
     * Original title (if exists)
     * @type {string}
     * @memberof Program
     */
    'title': string;
    /**
     * Program developer internal id.
     * @type {number}
     * @memberof Program
     */
    'developer_id': number;
    /**
     * Images urls
     * @type {Array<string>}
     * @memberof Program
     */
    'images'?: Array<string>;
    /**
     * 
     * @type {Description}
     * @memberof Program
     */
    'description'?: Description;
    /**
     * 
     * @type {AgendaProgram}
     * @memberof Program
     */
    'agenda': AgendaProgram;
    /**
     * 
     * @type {AddressLocation}
     * @memberof Program
     */
    'address': AddressLocation;
    /**
     * 
     * @type {FeaturesProgram}
     * @memberof Program
     */
    'features': FeaturesProgram;
}
/**
 * 
 * @export
 * @interface ProgramAllOf
 */
export interface ProgramAllOf {
    /**
     * CDV’s DB reference
     * @type {number}
     * @memberof ProgramAllOf
     */
    'id'?: number;
    /**
     * Create date (Format ISO 8601)
     * @type {string}
     * @memberof ProgramAllOf
     */
    'created_at'?: string;
    /**
     * Last update date (Format ISO 8601)
     * @type {string}
     * @memberof ProgramAllOf
     */
    'updated_at'?: string;
    /**
     * True if program from flow is linked with a manualy created program.
     * @type {boolean}
     * @memberof ProgramAllOf
     */
    'linked_flow'?: boolean;
    /**
     * 
     * @type {Developer}
     * @memberof ProgramAllOf
     */
    'developer'?: Developer;
}
/**
 * A representation of a property for create/update
 * @export
 * @interface ProgramIn
 */
export interface ProgramIn {
    /**
     * Original title (if exists)
     * @type {string}
     * @memberof ProgramIn
     */
    'title': string;
    /**
     * Program developer internal id.
     * @type {number}
     * @memberof ProgramIn
     */
    'developer_id': number;
    /**
     * Images urls
     * @type {Array<string>}
     * @memberof ProgramIn
     */
    'images'?: Array<string>;
    /**
     * 
     * @type {Description}
     * @memberof ProgramIn
     */
    'description'?: Description;
    /**
     * 
     * @type {AgendaProgram}
     * @memberof ProgramIn
     */
    'agenda': AgendaProgram;
    /**
     * 
     * @type {AddressLocation}
     * @memberof ProgramIn
     */
    'address': AddressLocation;
    /**
     * 
     * @type {FeaturesProgram}
     * @memberof ProgramIn
     */
    'features': FeaturesProgram;
}
/**
 * A representation of a property for search
 * @export
 * @interface ProgramShort
 */
export interface ProgramShort {
    /**
     * CDV’s DB reference
     * @type {number}
     * @memberof ProgramShort
     */
    'id'?: number;
    /**
     * Create date (Format ISO 8601)
     * @type {string}
     * @memberof ProgramShort
     */
    'created_at'?: string;
    /**
     * Last update date (Format ISO 8601)
     * @type {string}
     * @memberof ProgramShort
     */
    'updated_at'?: string;
    /**
     * Images urls
     * @type {Array<string>}
     * @memberof ProgramShort
     */
    'images'?: Array<string>;
    /**
     * Original title (if exists)
     * @type {string}
     * @memberof ProgramShort
     */
    'title'?: string;
    /**
     * Long description format
     * @type {string}
     * @memberof ProgramShort
     */
    'description'?: string;
    /**
     * Program developer id.
     * @type {number}
     * @memberof ProgramShort
     */
    'developer_id'?: number;
    /**
     * Program developer name.
     * @type {string}
     * @memberof ProgramShort
     */
    'developer_name'?: string;
    /**
     * True if program from flow is linked with a manualy created program.
     * @type {boolean}
     * @memberof ProgramShort
     */
    'linked_flow'?: boolean;
    /**
     * 
     * @type {AgendaProgram}
     * @memberof ProgramShort
     */
    'agenda'?: AgendaProgram;
    /**
     * 
     * @type {AddressLocation}
     * @memberof ProgramShort
     */
    'address'?: AddressLocation;
    /**
     * 
     * @type {FeaturesProgramShort}
     * @memberof ProgramShort
     */
    'features'?: FeaturesProgramShort;
}
/**
 * 
 * @export
 * @interface ProgramsActivity
 */
export interface ProgramsActivity {
    /**
     * 
     * @type {Array<Activity>}
     * @memberof ProgramsActivity
     */
    'programs_in'?: Array<Activity>;
    /**
     * 
     * @type {Array<Activity>}
     * @memberof ProgramsActivity
     */
    'programs_out'?: Array<Activity>;
    /**
     * 
     * @type {Array<Activity>}
     * @memberof ProgramsActivity
     */
    'programs_back'?: Array<Activity>;
}
/**
 * 
 * @export
 * @interface PropertiesSeveral
 */
export interface PropertiesSeveral {
    /**
     * 
     * @type {Array<Property>}
     * @memberof PropertiesSeveral
     */
    'properties'?: Array<Property>;
}
/**
 * 
 * @export
 * @interface Property
 */
export interface Property {
    /**
     * CDV’s DB reference
     * @type {number}
     * @memberof Property
     */
    'id'?: number;
    /**
     * Create date (Format ISO 8601)
     * @type {string}
     * @memberof Property
     */
    'created_at'?: string;
    /**
     * Last update date (Format ISO 8601)
     * @type {string}
     * @memberof Property
     */
    'updated_at'?: string;
    /**
     * Property Age : new, old
     * @type {string}
     * @memberof Property
     */
    'age'?: PropertyAgeEnum;
    /**
     * Images urls
     * @type {Array<string>}
     * @memberof Property
     */
    'images'?: Array<string>;
    /**
     * Plans urls
     * @type {Array<string>}
     * @memberof Property
     */
    'plans'?: Array<string>;
    /**
     * 
     * @type {Array<Annexe>}
     * @memberof Property
     */
    'annexes'?: Array<Annexe>;
    /**
     * Program developer internal id.
     * @type {number}
     * @memberof Property
     */
    'developer_id'?: number;
    /**
     * Program internal id.
     * @type {number}
     * @memberof Property
     */
    'program_id'?: number;
    /**
     * Original title (if exists)
     * @type {string}
     * @memberof Property
     */
    'title': string;
    /**
     * Long text description
     * @type {string}
     * @memberof Property
     */
    'description': string;
    /**
     * Availability description (for program property)
     * @type {string}
     * @memberof Property
     */
    'availability'?: string;
    /**
     * Reference of the property
     * @type {string}
     * @memberof Property
     */
    'reference': string;
    /**
     * Property type by category
     * @type {string}
     * @memberof Property
     */
    'type_parent': PropertyTypeParentEnum;
    /**
     * Owner type
     * @type {string}
     * @memberof Property
     */
    'owner_type': PropertyOwnerTypeEnum;
    /**
     * floor
     * @type {number}
     * @memberof Property
     */
    'floor'?: number;
    /**
     * Number of floors
     * @type {number}
     * @memberof Property
     */
    'nb_floors'?: number;
    /**
     * Year of construction
     * @type {string}
     * @memberof Property
     */
    'year_of_construction'?: string;
    /**
     * URL of the virtual visit
     * @type {string}
     * @memberof Property
     */
    'virtual_visit'?: string;
    /**
     * exposition
     * @type {string}
     * @memberof Property
     */
    'exposure'?: PropertyExposureEnum;
    /**
     * Net price
     * @type {number}
     * @memberof Property
     */
    'price'?: number;
    /**
     * 
     * @type {PriceDetails}
     * @memberof Property
     */
    'price_details'?: PriceDetails;
    /**
     * Rent price
     * @type {number}
     * @memberof Property
     */
    'rent'?: number;
    /**
     * Format ISO 8601
     * @type {string}
     * @memberof Property
     */
    'availability_date': string;
    /**
     * Sell or rent
     * @type {string}
     * @memberof Property
     */
    'service': PropertyServiceEnum;
    /**
     * mandate_type
     * @type {string}
     * @memberof Property
     */
    'mandate_type': PropertyMandateTypeEnum;
    /**
     * 
     * @type {Fees}
     * @memberof Property
     */
    'fees'?: Fees;
    /**
     * 
     * @type {Area}
     * @memberof Property
     */
    'area': Area;
    /**
     * 
     * @type {ContactProperty}
     * @memberof Property
     */
    'contact': ContactProperty;
    /**
     * 
     * @type {AddressLocation}
     * @memberof Property
     */
    'address': AddressLocation;
    /**
     * 
     * @type {Rooms}
     * @memberof Property
     */
    'rooms'?: Rooms;
    /**
     * 
     * @type {Features}
     * @memberof Property
     */
    'features'?: Features;
    /**
     * 
     * @type {Energy}
     * @memberof Property
     */
    'energy'?: Energy;
}

export const PropertyAgeEnum = {
    New: 'new',
    Old: 'old'
} as const;

export type PropertyAgeEnum = typeof PropertyAgeEnum[keyof typeof PropertyAgeEnum];
export const PropertyTypeParentEnum = {
    Apartment: 'apartment',
    Parking: 'parking',
    House: 'house',
    Local: 'local',
    Field: 'field',
    Other: 'other'
} as const;

export type PropertyTypeParentEnum = typeof PropertyTypeParentEnum[keyof typeof PropertyTypeParentEnum];
export const PropertyOwnerTypeEnum = {
    Private: 'private',
    Pro: 'pro'
} as const;

export type PropertyOwnerTypeEnum = typeof PropertyOwnerTypeEnum[keyof typeof PropertyOwnerTypeEnum];
export const PropertyExposureEnum = {
    North: 'north',
    NorthEast: 'north-east',
    NorthWest: 'north-west',
    South: 'south',
    SouthEast: 'south-east',
    SouthWest: 'south-west',
    East: 'east',
    West: 'west'
} as const;

export type PropertyExposureEnum = typeof PropertyExposureEnum[keyof typeof PropertyExposureEnum];
export const PropertyServiceEnum = {
    Buy: 'buy',
    Rent: 'rent'
} as const;

export type PropertyServiceEnum = typeof PropertyServiceEnum[keyof typeof PropertyServiceEnum];
export const PropertyMandateTypeEnum = {
    Exclusif: 'exclusif',
    Simple: 'simple'
} as const;

export type PropertyMandateTypeEnum = typeof PropertyMandateTypeEnum[keyof typeof PropertyMandateTypeEnum];

/**
 * 
 * @export
 * @interface PropertyAllOf
 */
export interface PropertyAllOf {
    /**
     * CDV’s DB reference
     * @type {number}
     * @memberof PropertyAllOf
     */
    'id'?: number;
    /**
     * Create date (Format ISO 8601)
     * @type {string}
     * @memberof PropertyAllOf
     */
    'created_at'?: string;
    /**
     * Last update date (Format ISO 8601)
     * @type {string}
     * @memberof PropertyAllOf
     */
    'updated_at'?: string;
    /**
     * Property Age : new, old
     * @type {string}
     * @memberof PropertyAllOf
     */
    'age'?: PropertyAllOfAgeEnum;
    /**
     * Images urls
     * @type {Array<string>}
     * @memberof PropertyAllOf
     */
    'images'?: Array<string>;
    /**
     * Plans urls
     * @type {Array<string>}
     * @memberof PropertyAllOf
     */
    'plans'?: Array<string>;
}

export const PropertyAllOfAgeEnum = {
    New: 'new',
    Old: 'old'
} as const;

export type PropertyAllOfAgeEnum = typeof PropertyAllOfAgeEnum[keyof typeof PropertyAllOfAgeEnum];

/**
 * A representation of a property for create/update
 * @export
 * @interface PropertyIn
 */
export interface PropertyIn {
    /**
     * Program developer internal id.
     * @type {number}
     * @memberof PropertyIn
     */
    'developer_id'?: number;
    /**
     * Program internal id.
     * @type {number}
     * @memberof PropertyIn
     */
    'program_id'?: number;
    /**
     * Original title (if exists)
     * @type {string}
     * @memberof PropertyIn
     */
    'title': string;
    /**
     * Long text description
     * @type {string}
     * @memberof PropertyIn
     */
    'description': string;
    /**
     * Availability description (for program property)
     * @type {string}
     * @memberof PropertyIn
     */
    'availability'?: string;
    /**
     * Reference of the property
     * @type {string}
     * @memberof PropertyIn
     */
    'reference': string;
    /**
     * Property type by category
     * @type {string}
     * @memberof PropertyIn
     */
    'type_parent': PropertyInTypeParentEnum;
    /**
     * Owner type
     * @type {string}
     * @memberof PropertyIn
     */
    'owner_type': PropertyInOwnerTypeEnum;
    /**
     * floor
     * @type {number}
     * @memberof PropertyIn
     */
    'floor'?: number;
    /**
     * Number of floors
     * @type {number}
     * @memberof PropertyIn
     */
    'nb_floors'?: number;
    /**
     * Year of construction
     * @type {string}
     * @memberof PropertyIn
     */
    'year_of_construction'?: string;
    /**
     * URL of the virtual visit
     * @type {string}
     * @memberof PropertyIn
     */
    'virtual_visit'?: string;
    /**
     * exposition
     * @type {string}
     * @memberof PropertyIn
     */
    'exposure'?: PropertyInExposureEnum;
    /**
     * Net price
     * @type {number}
     * @memberof PropertyIn
     */
    'price'?: number;
    /**
     * 
     * @type {PriceDetails}
     * @memberof PropertyIn
     */
    'price_details'?: PriceDetails;
    /**
     * Rent price
     * @type {number}
     * @memberof PropertyIn
     */
    'rent'?: number;
    /**
     * Format ISO 8601
     * @type {string}
     * @memberof PropertyIn
     */
    'availability_date': string;
    /**
     * Sell or rent
     * @type {string}
     * @memberof PropertyIn
     */
    'service': PropertyInServiceEnum;
    /**
     * mandate_type
     * @type {string}
     * @memberof PropertyIn
     */
    'mandate_type': PropertyInMandateTypeEnum;
    /**
     * 
     * @type {Fees}
     * @memberof PropertyIn
     */
    'fees'?: Fees;
    /**
     * 
     * @type {Area}
     * @memberof PropertyIn
     */
    'area': Area;
    /**
     * 
     * @type {ContactProperty}
     * @memberof PropertyIn
     */
    'contact': ContactProperty;
    /**
     * 
     * @type {AddressLocation}
     * @memberof PropertyIn
     */
    'address': AddressLocation;
    /**
     * 
     * @type {Rooms}
     * @memberof PropertyIn
     */
    'rooms'?: Rooms;
    /**
     * 
     * @type {Features}
     * @memberof PropertyIn
     */
    'features'?: Features;
    /**
     * 
     * @type {Energy}
     * @memberof PropertyIn
     */
    'energy'?: Energy;
}

export const PropertyInTypeParentEnum = {
    Apartment: 'apartment',
    Parking: 'parking',
    House: 'house',
    Local: 'local',
    Field: 'field',
    Other: 'other'
} as const;

export type PropertyInTypeParentEnum = typeof PropertyInTypeParentEnum[keyof typeof PropertyInTypeParentEnum];
export const PropertyInOwnerTypeEnum = {
    Private: 'private',
    Pro: 'pro'
} as const;

export type PropertyInOwnerTypeEnum = typeof PropertyInOwnerTypeEnum[keyof typeof PropertyInOwnerTypeEnum];
export const PropertyInExposureEnum = {
    North: 'north',
    NorthEast: 'north-east',
    NorthWest: 'north-west',
    South: 'south',
    SouthEast: 'south-east',
    SouthWest: 'south-west',
    East: 'east',
    West: 'west'
} as const;

export type PropertyInExposureEnum = typeof PropertyInExposureEnum[keyof typeof PropertyInExposureEnum];
export const PropertyInServiceEnum = {
    Buy: 'buy',
    Rent: 'rent'
} as const;

export type PropertyInServiceEnum = typeof PropertyInServiceEnum[keyof typeof PropertyInServiceEnum];
export const PropertyInMandateTypeEnum = {
    Exclusif: 'exclusif',
    Simple: 'simple'
} as const;

export type PropertyInMandateTypeEnum = typeof PropertyInMandateTypeEnum[keyof typeof PropertyInMandateTypeEnum];

/**
 * A representation of a property for search
 * @export
 * @interface PropertyShort
 */
export interface PropertyShort {
    /**
     * CDV’s DB reference
     * @type {number}
     * @memberof PropertyShort
     */
    'id'?: number;
    /**
     * Create date (Format ISO 8601)
     * @type {string}
     * @memberof PropertyShort
     */
    'created_at'?: string;
    /**
     * Last update date (Format ISO 8601)
     * @type {string}
     * @memberof PropertyShort
     */
    'updated_at'?: string;
    /**
     * Original title (if exists)
     * @type {string}
     * @memberof PropertyShort
     */
    'title'?: string;
    /**
     * Long text description
     * @type {string}
     * @memberof PropertyShort
     */
    'description'?: string;
    /**
     * Availability description
     * @type {string}
     * @memberof PropertyShort
     */
    'availability'?: string;
    /**
     * Reference of the property
     * @type {string}
     * @memberof PropertyShort
     */
    'reference'?: string;
    /**
     * Property type by category
     * @type {string}
     * @memberof PropertyShort
     */
    'type_parent'?: PropertyShortTypeParentEnum;
    /**
     * Owner type
     * @type {string}
     * @memberof PropertyShort
     */
    'owner_type'?: PropertyShortOwnerTypeEnum;
    /**
     * Property Age : new, old
     * @type {string}
     * @memberof PropertyShort
     */
    'age'?: PropertyShortAgeEnum;
    /**
     * Net price
     * @type {number}
     * @memberof PropertyShort
     */
    'price'?: number;
    /**
     * 
     * @type {string}
     * @memberof PropertyShort
     */
    'label'?: PropertyShortLabelEnum;
    /**
     * Tax exemptions
     * @type {Array<string>}
     * @memberof PropertyShort
     */
    'tax_exemptions'?: Array<PropertyShortTaxExemptionsEnum>;
    /**
     * Rent price
     * @type {number}
     * @memberof PropertyShort
     */
    'rent'?: number;
    /**
     * Sell or rent
     * @type {string}
     * @memberof PropertyShort
     */
    'service'?: PropertyShortServiceEnum;
    /**
     * Images urls
     * @type {Array<string>}
     * @memberof PropertyShort
     */
    'images'?: Array<string>;
    /**
     * Total area
     * @type {number}
     * @memberof PropertyShort
     */
    'area'?: number;
    /**
     * Number of standard rooms
     * @type {number}
     * @memberof PropertyShort
     */
    'nb_rooms'?: number;
    /**
     * Developer ID (for program property only)
     * @type {number}
     * @memberof PropertyShort
     */
    'developer_id'?: number;
    /**
     * Developer name (for program property only)
     * @type {string}
     * @memberof PropertyShort
     */
    'developer_name'?: string;
    /**
     * Program ID (for program property only)
     * @type {number}
     * @memberof PropertyShort
     */
    'program_id'?: number;
    /**
     * Program name (for program property only)
     * @type {string}
     * @memberof PropertyShort
     */
    'program_name'?: string;
    /**
     * 
     * @type {AddressLocation}
     * @memberof PropertyShort
     */
    'address'?: AddressLocation;
}

export const PropertyShortTypeParentEnum = {
    Apartment: 'apartment',
    Parking: 'parking',
    House: 'house',
    Local: 'local',
    Field: 'field',
    Other: 'other'
} as const;

export type PropertyShortTypeParentEnum = typeof PropertyShortTypeParentEnum[keyof typeof PropertyShortTypeParentEnum];
export const PropertyShortOwnerTypeEnum = {
    Private: 'private',
    Pro: 'pro'
} as const;

export type PropertyShortOwnerTypeEnum = typeof PropertyShortOwnerTypeEnum[keyof typeof PropertyShortOwnerTypeEnum];
export const PropertyShortAgeEnum = {
    New: 'new',
    Old: 'old'
} as const;

export type PropertyShortAgeEnum = typeof PropertyShortAgeEnum[keyof typeof PropertyShortAgeEnum];
export const PropertyShortLabelEnum = {
    Re2025: 're_2025',
    Rt2012: 'rt_2012',
    Bbc: 'bbc'
} as const;

export type PropertyShortLabelEnum = typeof PropertyShortLabelEnum[keyof typeof PropertyShortLabelEnum];
export const PropertyShortTaxExemptionsEnum = {
    Cosse: 'cosse',
    DeficitFoncier: 'deficit_foncier',
    Demembrement: 'demembrement',
    Denormandie: 'denormandie',
    Ehpad: 'ehpad',
    LmnpLmp: 'lmnp_lmp',
    Malraux: 'malraux',
    MonumentHistorique: 'monument_historique',
    OffreCelda: 'offre-celda',
    Pinel: 'pinel',
    PinelPlus: 'pinel_plus',
    ResidencePrincipale: 'residence_principale',
    LocatifHorsDispositif: 'locatif_hors_dispositif',
    Ptz: 'ptz'
} as const;

export type PropertyShortTaxExemptionsEnum = typeof PropertyShortTaxExemptionsEnum[keyof typeof PropertyShortTaxExemptionsEnum];
export const PropertyShortServiceEnum = {
    Buy: 'buy',
    Rent: 'rent'
} as const;

export type PropertyShortServiceEnum = typeof PropertyShortServiceEnum[keyof typeof PropertyShortServiceEnum];

/**
 * 
 * @export
 * @interface ResponseFetchClusters
 */
export interface ResponseFetchClusters {
    /**
     * Total objects found
     * @type {number}
     * @memberof ResponseFetchClusters
     */
    'count'?: number;
    /**
     * Clusters fetched
     * @type {Array<ClusterMap>}
     * @memberof ResponseFetchClusters
     */
    'clusters'?: Array<ClusterMap>;
}
/**
 * 
 * @export
 * @interface ResponseFetchDevelopers
 */
export interface ResponseFetchDevelopers {
    /**
     * Total objects found
     * @type {number}
     * @memberof ResponseFetchDevelopers
     */
    'count'?: number;
    /**
     * Developers fetched
     * @type {Array<Developer>}
     * @memberof ResponseFetchDevelopers
     */
    'developers'?: Array<Developer>;
}
/**
 * 
 * @export
 * @interface ResponseFetchManagers
 */
export interface ResponseFetchManagers {
    /**
     * Total objects found
     * @type {number}
     * @memberof ResponseFetchManagers
     */
    'count'?: number;
    /**
     * Managers fetched
     * @type {Array<Manager>}
     * @memberof ResponseFetchManagers
     */
    'managers'?: Array<Manager>;
}
/**
 * 
 * @export
 * @interface ResponseFetchPrograms
 */
export interface ResponseFetchPrograms {
    /**
     * 
     * @type {number}
     * @memberof ResponseFetchPrograms
     */
    'count'?: number;
    /**
     * 
     * @type {Array<ProgramShort>}
     * @memberof ResponseFetchPrograms
     */
    'programs'?: Array<ProgramShort>;
}
/**
 * 
 * @export
 * @interface ResponseFetchProperties
 */
export interface ResponseFetchProperties {
    /**
     * Total objects found
     * @type {number}
     * @memberof ResponseFetchProperties
     */
    'count'?: number;
    /**
     * Properties fetched
     * @type {Array<PropertyShort>}
     * @memberof ResponseFetchProperties
     */
    'properties'?: Array<PropertyShort>;
}
/**
 * 
 * @export
 * @interface ResponseFetchSCPIs
 */
export interface ResponseFetchSCPIs {
    /**
     * Total objects found
     * @type {number}
     * @memberof ResponseFetchSCPIs
     */
    'count'?: number;
    /**
     * SCPIs fetched
     * @type {Array<Scpi>}
     * @memberof ResponseFetchSCPIs
     */
    'scpis'?: Array<Scpi>;
}
/**
 * 
 * @export
 * @interface Risk
 */
export interface Risk {
    /**
     * Indicateur de risque
     * @type {number}
     * @memberof Risk
     */
    'risk': number;
    /**
     * Durée de conservation préconisée (en année)
     * @type {number}
     * @memberof Risk
     */
    'best_retention_period': number;
    /**
     * Durée de l\'investissement minimum
     * @type {string}
     * @memberof Risk
     */
    'minimum_duration': RiskMinimumDurationEnum;
    /**
     * Profil de risque minimum
     * @type {string}
     * @memberof Risk
     */
    'minimum_risk': RiskMinimumRiskEnum;
    /**
     * Niveau de connaissance financière minimum
     * @type {string}
     * @memberof Risk
     */
    'minimum_knowledge': RiskMinimumKnowledgeEnum;
}

export const RiskMinimumDurationEnum = {
    Court: 'court',
    Moyen: 'moyen',
    Long: 'long'
} as const;

export type RiskMinimumDurationEnum = typeof RiskMinimumDurationEnum[keyof typeof RiskMinimumDurationEnum];
export const RiskMinimumRiskEnum = {
    R1: 'R1',
    R2: 'R2',
    R3: 'R3',
    R4: 'R4'
} as const;

export type RiskMinimumRiskEnum = typeof RiskMinimumRiskEnum[keyof typeof RiskMinimumRiskEnum];
export const RiskMinimumKnowledgeEnum = {
    C1: 'C1',
    C2: 'C2',
    C3: 'C3',
    C4: 'C4'
} as const;

export type RiskMinimumKnowledgeEnum = typeof RiskMinimumKnowledgeEnum[keyof typeof RiskMinimumKnowledgeEnum];

/**
 * 
 * @export
 * @interface Rooms
 */
export interface Rooms {
    /**
     * Number of standard rooms
     * @type {number}
     * @memberof Rooms
     */
    'nb_rooms': number;
    /**
     * Number of standard bedrooms
     * @type {number}
     * @memberof Rooms
     */
    'nb_bed_rooms'?: number;
    /**
     * Number of bathrooms
     * @type {number}
     * @memberof Rooms
     */
    'nb_bathrooms'?: number;
    /**
     * Number of water rooms
     * @type {number}
     * @memberof Rooms
     */
    'nb_water_rooms'?: number;
}
/**
 * 
 * @export
 * @interface Scpi
 */
export interface Scpi {
    /**
     * CDV’s DB reference
     * @type {number}
     * @memberof Scpi
     */
    'id': number;
    /**
     * Create date (Format ISO 8601)
     * @type {string}
     * @memberof Scpi
     */
    'create_at': string;
    /**
     * Last update date (Format ISO 8601)
     * @type {string}
     * @memberof Scpi
     */
    'update_at': string;
    /**
     * CDV’s DB reference
     * @type {number}
     * @memberof Scpi
     */
    'manager_id'?: number;
    /**
     * Name
     * @type {string}
     * @memberof Scpi
     */
    'name': string;
    /**
     * Strategy
     * @type {string}
     * @memberof Scpi
     */
    'strategy': string;
    /**
     * URL of the logo
     * @type {string}
     * @memberof Scpi
     */
    'logo'?: string;
    /**
     * Capital (0 = variable, 1 = fixe)
     * @type {number}
     * @memberof Scpi
     */
    'capital': number;
    /**
     * Type
     * @type {string}
     * @memberof Scpi
     */
    'type': ScpiTypeEnum;
    /**
     * Subtype
     * @type {string}
     * @memberof Scpi
     */
    'subtype': string;
    /**
     * 
     * @type {AgendaSCPI}
     * @memberof Scpi
     */
    'agenda': AgendaSCPI;
    /**
     * 
     * @type {Risk}
     * @memberof Scpi
     */
    'risk': Risk;
    /**
     * 
     * @type {Kpi}
     * @memberof Scpi
     */
    'kpi': Kpi;
    /**
     * 
     * @type {Patrimony}
     * @memberof Scpi
     */
    'patrimony': Patrimony;
    /**
     * 
     * @type {KeyNumbers}
     * @memberof Scpi
     */
    'key_numbers': KeyNumbers;
}

export const ScpiTypeEnum = {
    Fiscal: 'fiscal',
    Rendement: 'rendement',
    Capitalisation: 'capitalisation'
} as const;

export type ScpiTypeEnum = typeof ScpiTypeEnum[keyof typeof ScpiTypeEnum];

/**
 * 
 * @export
 * @interface ScpiAllOf
 */
export interface ScpiAllOf {
    /**
     * CDV’s DB reference
     * @type {number}
     * @memberof ScpiAllOf
     */
    'id'?: number;
    /**
     * Create date (Format ISO 8601)
     * @type {string}
     * @memberof ScpiAllOf
     */
    'create_at'?: string;
    /**
     * Last update date (Format ISO 8601)
     * @type {string}
     * @memberof ScpiAllOf
     */
    'update_at'?: string;
}
/**
 * 
 * @export
 * @interface ScpiIn
 */
export interface ScpiIn {
    /**
     * CDV’s DB reference
     * @type {number}
     * @memberof ScpiIn
     */
    'manager_id'?: number;
    /**
     * Name
     * @type {string}
     * @memberof ScpiIn
     */
    'name': string;
    /**
     * Strategy
     * @type {string}
     * @memberof ScpiIn
     */
    'strategy': string;
    /**
     * URL of the logo
     * @type {string}
     * @memberof ScpiIn
     */
    'logo'?: string;
    /**
     * Capital (0 = variable, 1 = fixe)
     * @type {number}
     * @memberof ScpiIn
     */
    'capital': number;
    /**
     * Type
     * @type {string}
     * @memberof ScpiIn
     */
    'type': ScpiInTypeEnum;
    /**
     * Subtype
     * @type {string}
     * @memberof ScpiIn
     */
    'subtype': string;
    /**
     * 
     * @type {AgendaSCPI}
     * @memberof ScpiIn
     */
    'agenda': AgendaSCPI;
    /**
     * 
     * @type {Risk}
     * @memberof ScpiIn
     */
    'risk': Risk;
    /**
     * 
     * @type {Kpi}
     * @memberof ScpiIn
     */
    'kpi': Kpi;
    /**
     * 
     * @type {Patrimony}
     * @memberof ScpiIn
     */
    'patrimony': Patrimony;
    /**
     * 
     * @type {KeyNumbers}
     * @memberof ScpiIn
     */
    'key_numbers': KeyNumbers;
}

export const ScpiInTypeEnum = {
    Fiscal: 'fiscal',
    Rendement: 'rendement',
    Capitalisation: 'capitalisation'
} as const;

export type ScpiInTypeEnum = typeof ScpiInTypeEnum[keyof typeof ScpiInTypeEnum];

/**
 * 
 * @export
 * @interface StatusIn
 */
export interface StatusIn {
    /**
     * 
     * @type {string}
     * @memberof StatusIn
     */
    'status': StatusInStatusEnum;
}

export const StatusInStatusEnum = {
    Disabled: 'DISABLED',
    Enabled: 'ENABLED',
    Shared: 'SHARED',
    Suspended: 'SUSPENDED'
} as const;

export type StatusInStatusEnum = typeof StatusInStatusEnum[keyof typeof StatusInStatusEnum];

/**
 * 
 * @export
 * @interface StatusInShort
 */
export interface StatusInShort {
    /**
     * 
     * @type {string}
     * @memberof StatusInShort
     */
    'status': StatusInShortStatusEnum;
}

export const StatusInShortStatusEnum = {
    Disabled: 'DISABLED',
    Enabled: 'ENABLED',
    Shared: 'SHARED'
} as const;

export type StatusInShortStatusEnum = typeof StatusInShortStatusEnum[keyof typeof StatusInShortStatusEnum];


/**
 * DevelopersApi - axios parameter creator
 * @export
 */
export const DevelopersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create Developer
         * @summary Create Developer
         * @param {DeveloperIn} developerIn Body
         * @param {boolean} [shared] Is shared with subscription.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDeveloper: async (developerIn: DeveloperIn, shared?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'developerIn' is not null or undefined
            assertParamExists('createDeveloper', 'developerIn', developerIn)
            const localVarPath = `/developers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (shared !== undefined) {
                localVarQueryParameter['shared'] = shared;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(developerIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a developer based on a single ID
         * @summary Get Developer
         * @param {number} id Developer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeveloper: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDeveloper', 'id', id)
            const localVarPath = `/developers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List All Developers
         * @summary List Developers
         * @param {number} [pageSize] Pagination size
         * @param {number} [pageToken] Page index token
         * @param {string} [queryName] Text name like query filter.
         * @param {boolean} [filterEnable] Filter only enabled.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDevelopers: async (pageSize?: number, pageToken?: number, queryName?: string, filterEnable?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/developers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (pageToken !== undefined) {
                localVarQueryParameter['page_token'] = pageToken;
            }

            if (queryName !== undefined) {
                localVarQueryParameter['query_name'] = queryName;
            }

            if (filterEnable !== undefined) {
                localVarQueryParameter['filter_enable'] = filterEnable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a developer based on a single ID
         * @summary Update Developer
         * @param {number} id Developer ID
         * @param {DeveloperIn} developerIn Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeveloper: async (id: number, developerIn: DeveloperIn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateDeveloper', 'id', id)
            // verify required parameter 'developerIn' is not null or undefined
            assertParamExists('updateDeveloper', 'developerIn', developerIn)
            const localVarPath = `/developers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(developerIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a developer status based on a single ID
         * @summary Update Developer Status
         * @param {number} id Developer ID
         * @param {StatusInShort} statusInShort Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeveloperStatus: async (id: number, statusInShort: StatusInShort, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateDeveloperStatus', 'id', id)
            // verify required parameter 'statusInShort' is not null or undefined
            assertParamExists('updateDeveloperStatus', 'statusInShort', statusInShort)
            const localVarPath = `/developers/{id}/status`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(statusInShort, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DevelopersApi - functional programming interface
 * @export
 */
export const DevelopersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DevelopersApiAxiosParamCreator(configuration)
    return {
        /**
         * Create Developer
         * @summary Create Developer
         * @param {DeveloperIn} developerIn Body
         * @param {boolean} [shared] Is shared with subscription.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDeveloper(developerIn: DeveloperIn, shared?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Developer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDeveloper(developerIn, shared, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a developer based on a single ID
         * @summary Get Developer
         * @param {number} id Developer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeveloper(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Developer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeveloper(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List All Developers
         * @summary List Developers
         * @param {number} [pageSize] Pagination size
         * @param {number} [pageToken] Page index token
         * @param {string} [queryName] Text name like query filter.
         * @param {boolean} [filterEnable] Filter only enabled.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDevelopers(pageSize?: number, pageToken?: number, queryName?: string, filterEnable?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFetchDevelopers>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDevelopers(pageSize, pageToken, queryName, filterEnable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a developer based on a single ID
         * @summary Update Developer
         * @param {number} id Developer ID
         * @param {DeveloperIn} developerIn Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDeveloper(id: number, developerIn: DeveloperIn, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDeveloper(id, developerIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a developer status based on a single ID
         * @summary Update Developer Status
         * @param {number} id Developer ID
         * @param {StatusInShort} statusInShort Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDeveloperStatus(id: number, statusInShort: StatusInShort, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDeveloperStatus(id, statusInShort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DevelopersApi - factory interface
 * @export
 */
export const DevelopersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DevelopersApiFp(configuration)
    return {
        /**
         * Create Developer
         * @summary Create Developer
         * @param {DeveloperIn} developerIn Body
         * @param {boolean} [shared] Is shared with subscription.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDeveloper(developerIn: DeveloperIn, shared?: boolean, options?: any): AxiosPromise<Developer> {
            return localVarFp.createDeveloper(developerIn, shared, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a developer based on a single ID
         * @summary Get Developer
         * @param {number} id Developer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeveloper(id: number, options?: any): AxiosPromise<Developer> {
            return localVarFp.getDeveloper(id, options).then((request) => request(axios, basePath));
        },
        /**
         * List All Developers
         * @summary List Developers
         * @param {number} [pageSize] Pagination size
         * @param {number} [pageToken] Page index token
         * @param {string} [queryName] Text name like query filter.
         * @param {boolean} [filterEnable] Filter only enabled.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDevelopers(pageSize?: number, pageToken?: number, queryName?: string, filterEnable?: boolean, options?: any): AxiosPromise<ResponseFetchDevelopers> {
            return localVarFp.listDevelopers(pageSize, pageToken, queryName, filterEnable, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a developer based on a single ID
         * @summary Update Developer
         * @param {number} id Developer ID
         * @param {DeveloperIn} developerIn Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeveloper(id: number, developerIn: DeveloperIn, options?: any): AxiosPromise<void> {
            return localVarFp.updateDeveloper(id, developerIn, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a developer status based on a single ID
         * @summary Update Developer Status
         * @param {number} id Developer ID
         * @param {StatusInShort} statusInShort Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeveloperStatus(id: number, statusInShort: StatusInShort, options?: any): AxiosPromise<void> {
            return localVarFp.updateDeveloperStatus(id, statusInShort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DevelopersApi - object-oriented interface
 * @export
 * @class DevelopersApi
 * @extends {BaseAPI}
 */
export class DevelopersApi extends BaseAPI {
    /**
     * Create Developer
     * @summary Create Developer
     * @param {DeveloperIn} developerIn Body
     * @param {boolean} [shared] Is shared with subscription.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevelopersApi
     */
    public createDeveloper(developerIn: DeveloperIn, shared?: boolean, options?: AxiosRequestConfig) {
        return DevelopersApiFp(this.configuration).createDeveloper(developerIn, shared, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a developer based on a single ID
     * @summary Get Developer
     * @param {number} id Developer ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevelopersApi
     */
    public getDeveloper(id: number, options?: AxiosRequestConfig) {
        return DevelopersApiFp(this.configuration).getDeveloper(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List All Developers
     * @summary List Developers
     * @param {number} [pageSize] Pagination size
     * @param {number} [pageToken] Page index token
     * @param {string} [queryName] Text name like query filter.
     * @param {boolean} [filterEnable] Filter only enabled.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevelopersApi
     */
    public listDevelopers(pageSize?: number, pageToken?: number, queryName?: string, filterEnable?: boolean, options?: AxiosRequestConfig) {
        return DevelopersApiFp(this.configuration).listDevelopers(pageSize, pageToken, queryName, filterEnable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a developer based on a single ID
     * @summary Update Developer
     * @param {number} id Developer ID
     * @param {DeveloperIn} developerIn Body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevelopersApi
     */
    public updateDeveloper(id: number, developerIn: DeveloperIn, options?: AxiosRequestConfig) {
        return DevelopersApiFp(this.configuration).updateDeveloper(id, developerIn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a developer status based on a single ID
     * @summary Update Developer Status
     * @param {number} id Developer ID
     * @param {StatusInShort} statusInShort Body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevelopersApi
     */
    public updateDeveloperStatus(id: number, statusInShort: StatusInShort, options?: AxiosRequestConfig) {
        return DevelopersApiFp(this.configuration).updateDeveloperStatus(id, statusInShort, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HealthcheckApi - axios parameter creator
 * @export
 */
export const HealthcheckApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * The healthcheck endpoint retrieve 200 code if service is up.
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealth: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/healthcheck`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthcheckApi - functional programming interface
 * @export
 */
export const HealthcheckApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthcheckApiAxiosParamCreator(configuration)
    return {
        /**
         * The healthcheck endpoint retrieve 200 code if service is up.
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHealth(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHealth(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HealthcheckApi - factory interface
 * @export
 */
export const HealthcheckApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthcheckApiFp(configuration)
    return {
        /**
         * The healthcheck endpoint retrieve 200 code if service is up.
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealth(options?: any): AxiosPromise<void> {
            return localVarFp.getHealth(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthcheckApi - object-oriented interface
 * @export
 * @class HealthcheckApi
 * @extends {BaseAPI}
 */
export class HealthcheckApi extends BaseAPI {
    /**
     * The healthcheck endpoint retrieve 200 code if service is up.
     * @summary Health Check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthcheckApi
     */
    public getHealth(options?: AxiosRequestConfig) {
        return HealthcheckApiFp(this.configuration).getHealth(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ManagersApi - axios parameter creator
 * @export
 */
export const ManagersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create Manager
         * @summary Create Manager
         * @param {ManagerIn} managerIn Body
         * @param {boolean} [shared] Is shared with subscription.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManager: async (managerIn: ManagerIn, shared?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'managerIn' is not null or undefined
            assertParamExists('createManager', 'managerIn', managerIn)
            const localVarPath = `/managers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (shared !== undefined) {
                localVarQueryParameter['shared'] = shared;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(managerIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a manager based on a single ID
         * @summary Get Manager
         * @param {number} id Manager ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManager: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getManager', 'id', id)
            const localVarPath = `/managers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List All Managers
         * @summary List Managers
         * @param {number} [pageSize] Pagination size
         * @param {number} [pageToken] Page index token
         * @param {string} [queryName] Text name like query filter.
         * @param {boolean} [filterEnable] Filter only enabled.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listManagers: async (pageSize?: number, pageToken?: number, queryName?: string, filterEnable?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/managers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (pageToken !== undefined) {
                localVarQueryParameter['page_token'] = pageToken;
            }

            if (queryName !== undefined) {
                localVarQueryParameter['query_name'] = queryName;
            }

            if (filterEnable !== undefined) {
                localVarQueryParameter['filter_enable'] = filterEnable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a manager based on a single ID
         * @summary Update Manager
         * @param {number} id Manager ID
         * @param {ManagerIn} managerIn Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManager: async (id: number, managerIn: ManagerIn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateManager', 'id', id)
            // verify required parameter 'managerIn' is not null or undefined
            assertParamExists('updateManager', 'managerIn', managerIn)
            const localVarPath = `/managers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(managerIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a manager status based on a single ID
         * @summary Update Manager Status
         * @param {number} id Manager ID
         * @param {StatusInShort} statusInShort Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManagerStatus: async (id: number, statusInShort: StatusInShort, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateManagerStatus', 'id', id)
            // verify required parameter 'statusInShort' is not null or undefined
            assertParamExists('updateManagerStatus', 'statusInShort', statusInShort)
            const localVarPath = `/managers/{id}/status`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(statusInShort, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ManagersApi - functional programming interface
 * @export
 */
export const ManagersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ManagersApiAxiosParamCreator(configuration)
    return {
        /**
         * Create Manager
         * @summary Create Manager
         * @param {ManagerIn} managerIn Body
         * @param {boolean} [shared] Is shared with subscription.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createManager(managerIn: ManagerIn, shared?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Manager>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createManager(managerIn, shared, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a manager based on a single ID
         * @summary Get Manager
         * @param {number} id Manager ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManager(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Manager>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManager(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List All Managers
         * @summary List Managers
         * @param {number} [pageSize] Pagination size
         * @param {number} [pageToken] Page index token
         * @param {string} [queryName] Text name like query filter.
         * @param {boolean} [filterEnable] Filter only enabled.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listManagers(pageSize?: number, pageToken?: number, queryName?: string, filterEnable?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFetchManagers>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listManagers(pageSize, pageToken, queryName, filterEnable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a manager based on a single ID
         * @summary Update Manager
         * @param {number} id Manager ID
         * @param {ManagerIn} managerIn Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateManager(id: number, managerIn: ManagerIn, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateManager(id, managerIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a manager status based on a single ID
         * @summary Update Manager Status
         * @param {number} id Manager ID
         * @param {StatusInShort} statusInShort Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateManagerStatus(id: number, statusInShort: StatusInShort, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateManagerStatus(id, statusInShort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ManagersApi - factory interface
 * @export
 */
export const ManagersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ManagersApiFp(configuration)
    return {
        /**
         * Create Manager
         * @summary Create Manager
         * @param {ManagerIn} managerIn Body
         * @param {boolean} [shared] Is shared with subscription.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManager(managerIn: ManagerIn, shared?: boolean, options?: any): AxiosPromise<Manager> {
            return localVarFp.createManager(managerIn, shared, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a manager based on a single ID
         * @summary Get Manager
         * @param {number} id Manager ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManager(id: number, options?: any): AxiosPromise<Manager> {
            return localVarFp.getManager(id, options).then((request) => request(axios, basePath));
        },
        /**
         * List All Managers
         * @summary List Managers
         * @param {number} [pageSize] Pagination size
         * @param {number} [pageToken] Page index token
         * @param {string} [queryName] Text name like query filter.
         * @param {boolean} [filterEnable] Filter only enabled.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listManagers(pageSize?: number, pageToken?: number, queryName?: string, filterEnable?: boolean, options?: any): AxiosPromise<ResponseFetchManagers> {
            return localVarFp.listManagers(pageSize, pageToken, queryName, filterEnable, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a manager based on a single ID
         * @summary Update Manager
         * @param {number} id Manager ID
         * @param {ManagerIn} managerIn Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManager(id: number, managerIn: ManagerIn, options?: any): AxiosPromise<void> {
            return localVarFp.updateManager(id, managerIn, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a manager status based on a single ID
         * @summary Update Manager Status
         * @param {number} id Manager ID
         * @param {StatusInShort} statusInShort Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManagerStatus(id: number, statusInShort: StatusInShort, options?: any): AxiosPromise<void> {
            return localVarFp.updateManagerStatus(id, statusInShort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ManagersApi - object-oriented interface
 * @export
 * @class ManagersApi
 * @extends {BaseAPI}
 */
export class ManagersApi extends BaseAPI {
    /**
     * Create Manager
     * @summary Create Manager
     * @param {ManagerIn} managerIn Body
     * @param {boolean} [shared] Is shared with subscription.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagersApi
     */
    public createManager(managerIn: ManagerIn, shared?: boolean, options?: AxiosRequestConfig) {
        return ManagersApiFp(this.configuration).createManager(managerIn, shared, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a manager based on a single ID
     * @summary Get Manager
     * @param {number} id Manager ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagersApi
     */
    public getManager(id: number, options?: AxiosRequestConfig) {
        return ManagersApiFp(this.configuration).getManager(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List All Managers
     * @summary List Managers
     * @param {number} [pageSize] Pagination size
     * @param {number} [pageToken] Page index token
     * @param {string} [queryName] Text name like query filter.
     * @param {boolean} [filterEnable] Filter only enabled.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagersApi
     */
    public listManagers(pageSize?: number, pageToken?: number, queryName?: string, filterEnable?: boolean, options?: AxiosRequestConfig) {
        return ManagersApiFp(this.configuration).listManagers(pageSize, pageToken, queryName, filterEnable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a manager based on a single ID
     * @summary Update Manager
     * @param {number} id Manager ID
     * @param {ManagerIn} managerIn Body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagersApi
     */
    public updateManager(id: number, managerIn: ManagerIn, options?: AxiosRequestConfig) {
        return ManagersApiFp(this.configuration).updateManager(id, managerIn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a manager status based on a single ID
     * @summary Update Manager Status
     * @param {number} id Manager ID
     * @param {StatusInShort} statusInShort Body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagersApi
     */
    public updateManagerStatus(id: number, statusInShort: StatusInShort, options?: AxiosRequestConfig) {
        return ManagersApiFp(this.configuration).updateManagerStatus(id, statusInShort, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MediasApi - axios parameter creator
 * @export
 */
export const MediasApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Add property media
         * @summary Add a Media
         * @param {number} id ID  of property
         * @param {File} [media] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMediaProperty: async (id: number, media?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addMediaProperty', 'id', id)
            const localVarPath = `/properties/{id}/medias`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (media !== undefined) { 
                localVarFormParams.append('media', media as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete Media of a Property
         * @summary Delete a Media
         * @param {number} id ID  of property
         * @param {number} mediaId ID  of media
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMediaProperty: async (id: number, mediaId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteMediaProperty', 'id', id)
            // verify required parameter 'mediaId' is not null or undefined
            assertParamExists('deleteMediaProperty', 'mediaId', mediaId)
            const localVarPath = `/properties/{id}/medias/{media_id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"media_id"}}`, encodeURIComponent(String(mediaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get property media
         * @summary Get a Media
         * @param {number} id ID  of property
         * @param {number} mediaId ID  of media
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMediaProperty: async (id: number, mediaId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMediaProperty', 'id', id)
            // verify required parameter 'mediaId' is not null or undefined
            assertParamExists('getMediaProperty', 'mediaId', mediaId)
            const localVarPath = `/properties/{id}/medias/{media_id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"media_id"}}`, encodeURIComponent(String(mediaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List property medias
         * @summary List of Media
         * @param {number} id ID  of property
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMediasProperty: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('listMediasProperty', 'id', id)
            const localVarPath = `/properties/{id}/medias`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MediasApi - functional programming interface
 * @export
 */
export const MediasApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MediasApiAxiosParamCreator(configuration)
    return {
        /**
         * Add property media
         * @summary Add a Media
         * @param {number} id ID  of property
         * @param {File} [media] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMediaProperty(id: number, media?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Media>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMediaProperty(id, media, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete Media of a Property
         * @summary Delete a Media
         * @param {number} id ID  of property
         * @param {number} mediaId ID  of media
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMediaProperty(id: number, mediaId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMediaProperty(id, mediaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get property media
         * @summary Get a Media
         * @param {number} id ID  of property
         * @param {number} mediaId ID  of media
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMediaProperty(id: number, mediaId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Media>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMediaProperty(id, mediaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List property medias
         * @summary List of Media
         * @param {number} id ID  of property
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMediasProperty(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Media>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMediasProperty(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MediasApi - factory interface
 * @export
 */
export const MediasApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MediasApiFp(configuration)
    return {
        /**
         * Add property media
         * @summary Add a Media
         * @param {number} id ID  of property
         * @param {File} [media] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMediaProperty(id: number, media?: File, options?: any): AxiosPromise<Media> {
            return localVarFp.addMediaProperty(id, media, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete Media of a Property
         * @summary Delete a Media
         * @param {number} id ID  of property
         * @param {number} mediaId ID  of media
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMediaProperty(id: number, mediaId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteMediaProperty(id, mediaId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get property media
         * @summary Get a Media
         * @param {number} id ID  of property
         * @param {number} mediaId ID  of media
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMediaProperty(id: number, mediaId: number, options?: any): AxiosPromise<Media> {
            return localVarFp.getMediaProperty(id, mediaId, options).then((request) => request(axios, basePath));
        },
        /**
         * List property medias
         * @summary List of Media
         * @param {number} id ID  of property
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMediasProperty(id: number, options?: any): AxiosPromise<Array<Media>> {
            return localVarFp.listMediasProperty(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MediasApi - object-oriented interface
 * @export
 * @class MediasApi
 * @extends {BaseAPI}
 */
export class MediasApi extends BaseAPI {
    /**
     * Add property media
     * @summary Add a Media
     * @param {number} id ID  of property
     * @param {File} [media] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediasApi
     */
    public addMediaProperty(id: number, media?: File, options?: AxiosRequestConfig) {
        return MediasApiFp(this.configuration).addMediaProperty(id, media, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete Media of a Property
     * @summary Delete a Media
     * @param {number} id ID  of property
     * @param {number} mediaId ID  of media
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediasApi
     */
    public deleteMediaProperty(id: number, mediaId: number, options?: AxiosRequestConfig) {
        return MediasApiFp(this.configuration).deleteMediaProperty(id, mediaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get property media
     * @summary Get a Media
     * @param {number} id ID  of property
     * @param {number} mediaId ID  of media
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediasApi
     */
    public getMediaProperty(id: number, mediaId: number, options?: AxiosRequestConfig) {
        return MediasApiFp(this.configuration).getMediaProperty(id, mediaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List property medias
     * @summary List of Media
     * @param {number} id ID  of property
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediasApi
     */
    public listMediasProperty(id: number, options?: AxiosRequestConfig) {
        return MediasApiFp(this.configuration).listMediasProperty(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MetricsApi - axios parameter creator
 * @export
 */
export const MetricsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * The Metrics endpoint retrieves stats data from http request logs.
         * @summary Fetch Metrics
         * @param {string} subscriptionId ID (uuid) of subscription
         * @param {number} [nbDays] Nombre de jours antérieures à scanner.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetrics: async (subscriptionId: string, nbDays?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriptionId' is not null or undefined
            assertParamExists('getMetrics', 'subscriptionId', subscriptionId)
            const localVarPath = `/metrics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nbDays !== undefined) {
                localVarQueryParameter['nb_days'] = nbDays;
            }

            if (subscriptionId !== undefined) {
                localVarQueryParameter['subscription_id'] = subscriptionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MetricsApi - functional programming interface
 * @export
 */
export const MetricsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MetricsApiAxiosParamCreator(configuration)
    return {
        /**
         * The Metrics endpoint retrieves stats data from http request logs.
         * @summary Fetch Metrics
         * @param {string} subscriptionId ID (uuid) of subscription
         * @param {number} [nbDays] Nombre de jours antérieures à scanner.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMetrics(subscriptionId: string, nbDays?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Metric>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMetrics(subscriptionId, nbDays, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MetricsApi - factory interface
 * @export
 */
export const MetricsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MetricsApiFp(configuration)
    return {
        /**
         * The Metrics endpoint retrieves stats data from http request logs.
         * @summary Fetch Metrics
         * @param {string} subscriptionId ID (uuid) of subscription
         * @param {number} [nbDays] Nombre de jours antérieures à scanner.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetrics(subscriptionId: string, nbDays?: number, options?: any): AxiosPromise<Array<Metric>> {
            return localVarFp.getMetrics(subscriptionId, nbDays, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MetricsApi - object-oriented interface
 * @export
 * @class MetricsApi
 * @extends {BaseAPI}
 */
export class MetricsApi extends BaseAPI {
    /**
     * The Metrics endpoint retrieves stats data from http request logs.
     * @summary Fetch Metrics
     * @param {string} subscriptionId ID (uuid) of subscription
     * @param {number} [nbDays] Nombre de jours antérieures à scanner.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsApi
     */
    public getMetrics(subscriptionId: string, nbDays?: number, options?: AxiosRequestConfig) {
        return MetricsApiFp(this.configuration).getMetrics(subscriptionId, nbDays, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProgramsApi - axios parameter creator
 * @export
 */
export const ProgramsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create program
         * @summary Create Program
         * @param {ProgramIn} programIn Program to create
         * @param {boolean} [shared] Is shared with subscription.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProgram: async (programIn: ProgramIn, shared?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'programIn' is not null or undefined
            assertParamExists('createProgram', 'programIn', programIn)
            const localVarPath = `/programs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (shared !== undefined) {
                localVarQueryParameter['shared'] = shared;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(programIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a program based on a single ID
         * @summary Get Program
         * @param {number} id Program ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgram: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProgram', 'id', id)
            const localVarPath = `/programs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a program based on a single Flow ID and Developer ID
         * @summary Get Program by flow_id and developer_id
         * @param {string} flowId Program ID from flow
         * @param {number} developerId developer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramByFlowIdAndDeveloperId: async (flowId: string, developerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'flowId' is not null or undefined
            assertParamExists('getProgramByFlowIdAndDeveloperId', 'flowId', flowId)
            // verify required parameter 'developerId' is not null or undefined
            assertParamExists('getProgramByFlowIdAndDeveloperId', 'developerId', developerId)
            const localVarPath = `/programs/{flow_id}/{developer_id}`
                .replace(`{${"flow_id"}}`, encodeURIComponent(String(flowId)))
                .replace(`{${"developer_id"}}`, encodeURIComponent(String(developerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get programs activity from today.
         * @summary Get Programs Activity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramsActivity: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/programs/activity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Program Properties by program ID
         * @summary Get Program Properties
         * @param {number} id Program ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertiesByProgramID: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPropertiesByProgramID', 'id', id)
            const localVarPath = `/programs/{id}/lots`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List All Clusters for map display
         * @summary List Clusters
         * @param {boolean} [filterEnable] Filter only enabled.
         * @param {string} [developerId] Filter Developer ID.
         * @param {Array<number>} [nbRooms] Filter number of rooms
         * @param {number} [minPrice] Filter min price
         * @param {number} [maxPrice] Filter max price
         * @param {number} [minArea] Filter min area
         * @param {number} [maxArea] Filter max area
         * @param {string} [minDeliveryDate] Filter min delivery date
         * @param {string} [maxDeliveryDate] Filter max delivery date
         * @param {Array<string>} [labels] Filter labels
         * @param {Array<string>} [taxesExemption] Filter taxes exemption
         * @param {Array<'parking' | 'house' | 'apartment' | 'local' | 'field' | 'other'>} [typeParents] Filter type parents.
         * @param {Array<GeoBoundingBox>} [geoBoundingBox] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProgramClusters: async (filterEnable?: boolean, developerId?: string, nbRooms?: Array<number>, minPrice?: number, maxPrice?: number, minArea?: number, maxArea?: number, minDeliveryDate?: string, maxDeliveryDate?: string, labels?: Array<string>, taxesExemption?: Array<string>, typeParents?: Array<'parking' | 'house' | 'apartment' | 'local' | 'field' | 'other'>, geoBoundingBox?: Array<GeoBoundingBox>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/programs/clusters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterEnable !== undefined) {
                localVarQueryParameter['filter_enable'] = filterEnable;
            }

            if (developerId !== undefined) {
                localVarQueryParameter['developer_id'] = developerId;
            }

            if (nbRooms) {
                localVarQueryParameter['nb_rooms'] = nbRooms;
            }

            if (minPrice !== undefined) {
                localVarQueryParameter['min_price'] = minPrice;
            }

            if (maxPrice !== undefined) {
                localVarQueryParameter['max_price'] = maxPrice;
            }

            if (minArea !== undefined) {
                localVarQueryParameter['min_area'] = minArea;
            }

            if (maxArea !== undefined) {
                localVarQueryParameter['max_area'] = maxArea;
            }

            if (minDeliveryDate !== undefined) {
                localVarQueryParameter['min_delivery_date'] = (minDeliveryDate as any instanceof Date) ?
                    (minDeliveryDate as any).toISOString() :
                    minDeliveryDate;
            }

            if (maxDeliveryDate !== undefined) {
                localVarQueryParameter['max_delivery_date'] = (maxDeliveryDate as any instanceof Date) ?
                    (maxDeliveryDate as any).toISOString() :
                    maxDeliveryDate;
            }

            if (labels) {
                localVarQueryParameter['labels'] = labels;
            }

            if (taxesExemption) {
                localVarQueryParameter['taxes_exemption'] = taxesExemption;
            }

            if (typeParents) {
                localVarQueryParameter['type_parents'] = typeParents;
            }

            if (geoBoundingBox) {
                localVarQueryParameter['geo_bounding_box'] = geoBoundingBox;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List All Programs
         * @summary List Programs
         * @param {string} [querySearch] Text like query filter to search by city/zip or program title.
         * @param {number} [pageSize] Pagination size
         * @param {number} [pageToken] Page index token
         * @param {string} [orderBy] order by field
         * @param {boolean} [sortDirDesc] is sorting desc
         * @param {boolean} [filterEnable] Filter only enabled.
         * @param {boolean} [filterNotLinked] Filter only not linked program.
         * @param {string} [developerId] Filter Developer ID.
         * @param {Array<number>} [nbRooms] Filter number of rooms
         * @param {number} [minPrice] Filter min price
         * @param {number} [maxPrice] Filter max price
         * @param {number} [minArea] Filter min area
         * @param {number} [maxArea] Filter max area
         * @param {string} [minDeliveryDate] Filter min delivery date
         * @param {string} [maxDeliveryDate] Filter max delivery date
         * @param {Array<string>} [labels] Filter labels
         * @param {Array<string>} [taxesExemption] Filter taxes exemption
         * @param {Array<'parking' | 'house' | 'apartment' | 'local' | 'field' | 'other'>} [typeParents] Filter type parents.
         * @param {Array<GeoBoundingBox>} [geoBoundingBox] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPrograms: async (querySearch?: string, pageSize?: number, pageToken?: number, orderBy?: string, sortDirDesc?: boolean, filterEnable?: boolean, filterNotLinked?: boolean, developerId?: string, nbRooms?: Array<number>, minPrice?: number, maxPrice?: number, minArea?: number, maxArea?: number, minDeliveryDate?: string, maxDeliveryDate?: string, labels?: Array<string>, taxesExemption?: Array<string>, typeParents?: Array<'parking' | 'house' | 'apartment' | 'local' | 'field' | 'other'>, geoBoundingBox?: Array<GeoBoundingBox>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/programs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (querySearch !== undefined) {
                localVarQueryParameter['query_search'] = querySearch;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (pageToken !== undefined) {
                localVarQueryParameter['page_token'] = pageToken;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['order_by'] = orderBy;
            }

            if (sortDirDesc !== undefined) {
                localVarQueryParameter['sort_dir_desc'] = sortDirDesc;
            }

            if (filterEnable !== undefined) {
                localVarQueryParameter['filter_enable'] = filterEnable;
            }

            if (filterNotLinked !== undefined) {
                localVarQueryParameter['filter_not_linked'] = filterNotLinked;
            }

            if (developerId !== undefined) {
                localVarQueryParameter['developer_id'] = developerId;
            }

            if (nbRooms) {
                localVarQueryParameter['nb_rooms'] = nbRooms;
            }

            if (minPrice !== undefined) {
                localVarQueryParameter['min_price'] = minPrice;
            }

            if (maxPrice !== undefined) {
                localVarQueryParameter['max_price'] = maxPrice;
            }

            if (minArea !== undefined) {
                localVarQueryParameter['min_area'] = minArea;
            }

            if (maxArea !== undefined) {
                localVarQueryParameter['max_area'] = maxArea;
            }

            if (minDeliveryDate !== undefined) {
                localVarQueryParameter['min_delivery_date'] = (minDeliveryDate as any instanceof Date) ?
                    (minDeliveryDate as any).toISOString() :
                    minDeliveryDate;
            }

            if (maxDeliveryDate !== undefined) {
                localVarQueryParameter['max_delivery_date'] = (maxDeliveryDate as any instanceof Date) ?
                    (maxDeliveryDate as any).toISOString() :
                    maxDeliveryDate;
            }

            if (labels) {
                localVarQueryParameter['labels'] = labels;
            }

            if (taxesExemption) {
                localVarQueryParameter['taxes_exemption'] = taxesExemption;
            }

            if (typeParents) {
                localVarQueryParameter['type_parents'] = typeParents;
            }

            if (geoBoundingBox) {
                localVarQueryParameter['geo_bounding_box'] = geoBoundingBox;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a program based on a single ID
         * @summary Update Program
         * @param {number} id Program ID
         * @param {ProgramIn} programIn Program to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProgram: async (id: number, programIn: ProgramIn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateProgram', 'id', id)
            // verify required parameter 'programIn' is not null or undefined
            assertParamExists('updateProgram', 'programIn', programIn)
            const localVarPath = `/programs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(programIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a program link
         * @summary Update Program Link
         * @param {number} id Program ID
         * @param {ParamsProgramsLink} paramsProgramsLink New program id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProgramLink: async (id: number, paramsProgramsLink: ParamsProgramsLink, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateProgramLink', 'id', id)
            // verify required parameter 'paramsProgramsLink' is not null or undefined
            assertParamExists('updateProgramLink', 'paramsProgramsLink', paramsProgramsLink)
            const localVarPath = `/programs/{id}/link`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paramsProgramsLink, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a program status based on a single ID
         * @summary Update Program Status
         * @param {number} id Program ID
         * @param {StatusIn} statusIn Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProgramStatus: async (id: number, statusIn: StatusIn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateProgramStatus', 'id', id)
            // verify required parameter 'statusIn' is not null or undefined
            assertParamExists('updateProgramStatus', 'statusIn', statusIn)
            const localVarPath = `/programs/{id}/status`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(statusIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProgramsApi - functional programming interface
 * @export
 */
export const ProgramsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProgramsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create program
         * @summary Create Program
         * @param {ProgramIn} programIn Program to create
         * @param {boolean} [shared] Is shared with subscription.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProgram(programIn: ProgramIn, shared?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Program>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProgram(programIn, shared, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a program based on a single ID
         * @summary Get Program
         * @param {number} id Program ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProgram(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Program>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProgram(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a program based on a single Flow ID and Developer ID
         * @summary Get Program by flow_id and developer_id
         * @param {string} flowId Program ID from flow
         * @param {number} developerId developer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProgramByFlowIdAndDeveloperId(flowId: string, developerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Program>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProgramByFlowIdAndDeveloperId(flowId, developerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get programs activity from today.
         * @summary Get Programs Activity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProgramsActivity(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProgramsActivity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProgramsActivity(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Program Properties by program ID
         * @summary Get Program Properties
         * @param {number} id Program ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPropertiesByProgramID(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertiesSeveral>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPropertiesByProgramID(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List All Clusters for map display
         * @summary List Clusters
         * @param {boolean} [filterEnable] Filter only enabled.
         * @param {string} [developerId] Filter Developer ID.
         * @param {Array<number>} [nbRooms] Filter number of rooms
         * @param {number} [minPrice] Filter min price
         * @param {number} [maxPrice] Filter max price
         * @param {number} [minArea] Filter min area
         * @param {number} [maxArea] Filter max area
         * @param {string} [minDeliveryDate] Filter min delivery date
         * @param {string} [maxDeliveryDate] Filter max delivery date
         * @param {Array<string>} [labels] Filter labels
         * @param {Array<string>} [taxesExemption] Filter taxes exemption
         * @param {Array<'parking' | 'house' | 'apartment' | 'local' | 'field' | 'other'>} [typeParents] Filter type parents.
         * @param {Array<GeoBoundingBox>} [geoBoundingBox] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listProgramClusters(filterEnable?: boolean, developerId?: string, nbRooms?: Array<number>, minPrice?: number, maxPrice?: number, minArea?: number, maxArea?: number, minDeliveryDate?: string, maxDeliveryDate?: string, labels?: Array<string>, taxesExemption?: Array<string>, typeParents?: Array<'parking' | 'house' | 'apartment' | 'local' | 'field' | 'other'>, geoBoundingBox?: Array<GeoBoundingBox>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFetchClusters>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listProgramClusters(filterEnable, developerId, nbRooms, minPrice, maxPrice, minArea, maxArea, minDeliveryDate, maxDeliveryDate, labels, taxesExemption, typeParents, geoBoundingBox, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List All Programs
         * @summary List Programs
         * @param {string} [querySearch] Text like query filter to search by city/zip or program title.
         * @param {number} [pageSize] Pagination size
         * @param {number} [pageToken] Page index token
         * @param {string} [orderBy] order by field
         * @param {boolean} [sortDirDesc] is sorting desc
         * @param {boolean} [filterEnable] Filter only enabled.
         * @param {boolean} [filterNotLinked] Filter only not linked program.
         * @param {string} [developerId] Filter Developer ID.
         * @param {Array<number>} [nbRooms] Filter number of rooms
         * @param {number} [minPrice] Filter min price
         * @param {number} [maxPrice] Filter max price
         * @param {number} [minArea] Filter min area
         * @param {number} [maxArea] Filter max area
         * @param {string} [minDeliveryDate] Filter min delivery date
         * @param {string} [maxDeliveryDate] Filter max delivery date
         * @param {Array<string>} [labels] Filter labels
         * @param {Array<string>} [taxesExemption] Filter taxes exemption
         * @param {Array<'parking' | 'house' | 'apartment' | 'local' | 'field' | 'other'>} [typeParents] Filter type parents.
         * @param {Array<GeoBoundingBox>} [geoBoundingBox] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPrograms(querySearch?: string, pageSize?: number, pageToken?: number, orderBy?: string, sortDirDesc?: boolean, filterEnable?: boolean, filterNotLinked?: boolean, developerId?: string, nbRooms?: Array<number>, minPrice?: number, maxPrice?: number, minArea?: number, maxArea?: number, minDeliveryDate?: string, maxDeliveryDate?: string, labels?: Array<string>, taxesExemption?: Array<string>, typeParents?: Array<'parking' | 'house' | 'apartment' | 'local' | 'field' | 'other'>, geoBoundingBox?: Array<GeoBoundingBox>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFetchPrograms>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPrograms(querySearch, pageSize, pageToken, orderBy, sortDirDesc, filterEnable, filterNotLinked, developerId, nbRooms, minPrice, maxPrice, minArea, maxArea, minDeliveryDate, maxDeliveryDate, labels, taxesExemption, typeParents, geoBoundingBox, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a program based on a single ID
         * @summary Update Program
         * @param {number} id Program ID
         * @param {ProgramIn} programIn Program to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProgram(id: number, programIn: ProgramIn, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProgram(id, programIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a program link
         * @summary Update Program Link
         * @param {number} id Program ID
         * @param {ParamsProgramsLink} paramsProgramsLink New program id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProgramLink(id: number, paramsProgramsLink: ParamsProgramsLink, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProgramLink(id, paramsProgramsLink, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a program status based on a single ID
         * @summary Update Program Status
         * @param {number} id Program ID
         * @param {StatusIn} statusIn Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProgramStatus(id: number, statusIn: StatusIn, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProgramStatus(id, statusIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProgramsApi - factory interface
 * @export
 */
export const ProgramsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProgramsApiFp(configuration)
    return {
        /**
         * Create program
         * @summary Create Program
         * @param {ProgramIn} programIn Program to create
         * @param {boolean} [shared] Is shared with subscription.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProgram(programIn: ProgramIn, shared?: boolean, options?: any): AxiosPromise<Program> {
            return localVarFp.createProgram(programIn, shared, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a program based on a single ID
         * @summary Get Program
         * @param {number} id Program ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgram(id: number, options?: any): AxiosPromise<Program> {
            return localVarFp.getProgram(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a program based on a single Flow ID and Developer ID
         * @summary Get Program by flow_id and developer_id
         * @param {string} flowId Program ID from flow
         * @param {number} developerId developer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramByFlowIdAndDeveloperId(flowId: string, developerId: number, options?: any): AxiosPromise<Program> {
            return localVarFp.getProgramByFlowIdAndDeveloperId(flowId, developerId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get programs activity from today.
         * @summary Get Programs Activity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramsActivity(options?: any): AxiosPromise<ProgramsActivity> {
            return localVarFp.getProgramsActivity(options).then((request) => request(axios, basePath));
        },
        /**
         * Get Program Properties by program ID
         * @summary Get Program Properties
         * @param {number} id Program ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertiesByProgramID(id: number, options?: any): AxiosPromise<PropertiesSeveral> {
            return localVarFp.getPropertiesByProgramID(id, options).then((request) => request(axios, basePath));
        },
        /**
         * List All Clusters for map display
         * @summary List Clusters
         * @param {boolean} [filterEnable] Filter only enabled.
         * @param {string} [developerId] Filter Developer ID.
         * @param {Array<number>} [nbRooms] Filter number of rooms
         * @param {number} [minPrice] Filter min price
         * @param {number} [maxPrice] Filter max price
         * @param {number} [minArea] Filter min area
         * @param {number} [maxArea] Filter max area
         * @param {string} [minDeliveryDate] Filter min delivery date
         * @param {string} [maxDeliveryDate] Filter max delivery date
         * @param {Array<string>} [labels] Filter labels
         * @param {Array<string>} [taxesExemption] Filter taxes exemption
         * @param {Array<'parking' | 'house' | 'apartment' | 'local' | 'field' | 'other'>} [typeParents] Filter type parents.
         * @param {Array<GeoBoundingBox>} [geoBoundingBox] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProgramClusters(filterEnable?: boolean, developerId?: string, nbRooms?: Array<number>, minPrice?: number, maxPrice?: number, minArea?: number, maxArea?: number, minDeliveryDate?: string, maxDeliveryDate?: string, labels?: Array<string>, taxesExemption?: Array<string>, typeParents?: Array<'parking' | 'house' | 'apartment' | 'local' | 'field' | 'other'>, geoBoundingBox?: Array<GeoBoundingBox>, options?: any): AxiosPromise<ResponseFetchClusters> {
            return localVarFp.listProgramClusters(filterEnable, developerId, nbRooms, minPrice, maxPrice, minArea, maxArea, minDeliveryDate, maxDeliveryDate, labels, taxesExemption, typeParents, geoBoundingBox, options).then((request) => request(axios, basePath));
        },
        /**
         * List All Programs
         * @summary List Programs
         * @param {string} [querySearch] Text like query filter to search by city/zip or program title.
         * @param {number} [pageSize] Pagination size
         * @param {number} [pageToken] Page index token
         * @param {string} [orderBy] order by field
         * @param {boolean} [sortDirDesc] is sorting desc
         * @param {boolean} [filterEnable] Filter only enabled.
         * @param {boolean} [filterNotLinked] Filter only not linked program.
         * @param {string} [developerId] Filter Developer ID.
         * @param {Array<number>} [nbRooms] Filter number of rooms
         * @param {number} [minPrice] Filter min price
         * @param {number} [maxPrice] Filter max price
         * @param {number} [minArea] Filter min area
         * @param {number} [maxArea] Filter max area
         * @param {string} [minDeliveryDate] Filter min delivery date
         * @param {string} [maxDeliveryDate] Filter max delivery date
         * @param {Array<string>} [labels] Filter labels
         * @param {Array<string>} [taxesExemption] Filter taxes exemption
         * @param {Array<'parking' | 'house' | 'apartment' | 'local' | 'field' | 'other'>} [typeParents] Filter type parents.
         * @param {Array<GeoBoundingBox>} [geoBoundingBox] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPrograms(querySearch?: string, pageSize?: number, pageToken?: number, orderBy?: string, sortDirDesc?: boolean, filterEnable?: boolean, filterNotLinked?: boolean, developerId?: string, nbRooms?: Array<number>, minPrice?: number, maxPrice?: number, minArea?: number, maxArea?: number, minDeliveryDate?: string, maxDeliveryDate?: string, labels?: Array<string>, taxesExemption?: Array<string>, typeParents?: Array<'parking' | 'house' | 'apartment' | 'local' | 'field' | 'other'>, geoBoundingBox?: Array<GeoBoundingBox>, options?: any): AxiosPromise<ResponseFetchPrograms> {
            return localVarFp.listPrograms(querySearch, pageSize, pageToken, orderBy, sortDirDesc, filterEnable, filterNotLinked, developerId, nbRooms, minPrice, maxPrice, minArea, maxArea, minDeliveryDate, maxDeliveryDate, labels, taxesExemption, typeParents, geoBoundingBox, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a program based on a single ID
         * @summary Update Program
         * @param {number} id Program ID
         * @param {ProgramIn} programIn Program to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProgram(id: number, programIn: ProgramIn, options?: any): AxiosPromise<void> {
            return localVarFp.updateProgram(id, programIn, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a program link
         * @summary Update Program Link
         * @param {number} id Program ID
         * @param {ParamsProgramsLink} paramsProgramsLink New program id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProgramLink(id: number, paramsProgramsLink: ParamsProgramsLink, options?: any): AxiosPromise<void> {
            return localVarFp.updateProgramLink(id, paramsProgramsLink, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a program status based on a single ID
         * @summary Update Program Status
         * @param {number} id Program ID
         * @param {StatusIn} statusIn Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProgramStatus(id: number, statusIn: StatusIn, options?: any): AxiosPromise<void> {
            return localVarFp.updateProgramStatus(id, statusIn, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProgramsApi - object-oriented interface
 * @export
 * @class ProgramsApi
 * @extends {BaseAPI}
 */
export class ProgramsApi extends BaseAPI {
    /**
     * Create program
     * @summary Create Program
     * @param {ProgramIn} programIn Program to create
     * @param {boolean} [shared] Is shared with subscription.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramsApi
     */
    public createProgram(programIn: ProgramIn, shared?: boolean, options?: AxiosRequestConfig) {
        return ProgramsApiFp(this.configuration).createProgram(programIn, shared, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a program based on a single ID
     * @summary Get Program
     * @param {number} id Program ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramsApi
     */
    public getProgram(id: number, options?: AxiosRequestConfig) {
        return ProgramsApiFp(this.configuration).getProgram(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a program based on a single Flow ID and Developer ID
     * @summary Get Program by flow_id and developer_id
     * @param {string} flowId Program ID from flow
     * @param {number} developerId developer ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramsApi
     */
    public getProgramByFlowIdAndDeveloperId(flowId: string, developerId: number, options?: AxiosRequestConfig) {
        return ProgramsApiFp(this.configuration).getProgramByFlowIdAndDeveloperId(flowId, developerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get programs activity from today.
     * @summary Get Programs Activity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramsApi
     */
    public getProgramsActivity(options?: AxiosRequestConfig) {
        return ProgramsApiFp(this.configuration).getProgramsActivity(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Program Properties by program ID
     * @summary Get Program Properties
     * @param {number} id Program ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramsApi
     */
    public getPropertiesByProgramID(id: number, options?: AxiosRequestConfig) {
        return ProgramsApiFp(this.configuration).getPropertiesByProgramID(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List All Clusters for map display
     * @summary List Clusters
     * @param {boolean} [filterEnable] Filter only enabled.
     * @param {string} [developerId] Filter Developer ID.
     * @param {Array<number>} [nbRooms] Filter number of rooms
     * @param {number} [minPrice] Filter min price
     * @param {number} [maxPrice] Filter max price
     * @param {number} [minArea] Filter min area
     * @param {number} [maxArea] Filter max area
     * @param {string} [minDeliveryDate] Filter min delivery date
     * @param {string} [maxDeliveryDate] Filter max delivery date
     * @param {Array<string>} [labels] Filter labels
     * @param {Array<string>} [taxesExemption] Filter taxes exemption
     * @param {Array<'parking' | 'house' | 'apartment' | 'local' | 'field' | 'other'>} [typeParents] Filter type parents.
     * @param {Array<GeoBoundingBox>} [geoBoundingBox] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramsApi
     */
    public listProgramClusters(filterEnable?: boolean, developerId?: string, nbRooms?: Array<number>, minPrice?: number, maxPrice?: number, minArea?: number, maxArea?: number, minDeliveryDate?: string, maxDeliveryDate?: string, labels?: Array<string>, taxesExemption?: Array<string>, typeParents?: Array<'parking' | 'house' | 'apartment' | 'local' | 'field' | 'other'>, geoBoundingBox?: Array<GeoBoundingBox>, options?: AxiosRequestConfig) {
        return ProgramsApiFp(this.configuration).listProgramClusters(filterEnable, developerId, nbRooms, minPrice, maxPrice, minArea, maxArea, minDeliveryDate, maxDeliveryDate, labels, taxesExemption, typeParents, geoBoundingBox, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List All Programs
     * @summary List Programs
     * @param {string} [querySearch] Text like query filter to search by city/zip or program title.
     * @param {number} [pageSize] Pagination size
     * @param {number} [pageToken] Page index token
     * @param {string} [orderBy] order by field
     * @param {boolean} [sortDirDesc] is sorting desc
     * @param {boolean} [filterEnable] Filter only enabled.
     * @param {boolean} [filterNotLinked] Filter only not linked program.
     * @param {string} [developerId] Filter Developer ID.
     * @param {Array<number>} [nbRooms] Filter number of rooms
     * @param {number} [minPrice] Filter min price
     * @param {number} [maxPrice] Filter max price
     * @param {number} [minArea] Filter min area
     * @param {number} [maxArea] Filter max area
     * @param {string} [minDeliveryDate] Filter min delivery date
     * @param {string} [maxDeliveryDate] Filter max delivery date
     * @param {Array<string>} [labels] Filter labels
     * @param {Array<string>} [taxesExemption] Filter taxes exemption
     * @param {Array<'parking' | 'house' | 'apartment' | 'local' | 'field' | 'other'>} [typeParents] Filter type parents.
     * @param {Array<GeoBoundingBox>} [geoBoundingBox] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramsApi
     */
    public listPrograms(querySearch?: string, pageSize?: number, pageToken?: number, orderBy?: string, sortDirDesc?: boolean, filterEnable?: boolean, filterNotLinked?: boolean, developerId?: string, nbRooms?: Array<number>, minPrice?: number, maxPrice?: number, minArea?: number, maxArea?: number, minDeliveryDate?: string, maxDeliveryDate?: string, labels?: Array<string>, taxesExemption?: Array<string>, typeParents?: Array<'parking' | 'house' | 'apartment' | 'local' | 'field' | 'other'>, geoBoundingBox?: Array<GeoBoundingBox>, options?: AxiosRequestConfig) {
        return ProgramsApiFp(this.configuration).listPrograms(querySearch, pageSize, pageToken, orderBy, sortDirDesc, filterEnable, filterNotLinked, developerId, nbRooms, minPrice, maxPrice, minArea, maxArea, minDeliveryDate, maxDeliveryDate, labels, taxesExemption, typeParents, geoBoundingBox, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a program based on a single ID
     * @summary Update Program
     * @param {number} id Program ID
     * @param {ProgramIn} programIn Program to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramsApi
     */
    public updateProgram(id: number, programIn: ProgramIn, options?: AxiosRequestConfig) {
        return ProgramsApiFp(this.configuration).updateProgram(id, programIn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a program link
     * @summary Update Program Link
     * @param {number} id Program ID
     * @param {ParamsProgramsLink} paramsProgramsLink New program id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramsApi
     */
    public updateProgramLink(id: number, paramsProgramsLink: ParamsProgramsLink, options?: AxiosRequestConfig) {
        return ProgramsApiFp(this.configuration).updateProgramLink(id, paramsProgramsLink, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a program status based on a single ID
     * @summary Update Program Status
     * @param {number} id Program ID
     * @param {StatusIn} statusIn Body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramsApi
     */
    public updateProgramStatus(id: number, statusIn: StatusIn, options?: AxiosRequestConfig) {
        return ProgramsApiFp(this.configuration).updateProgramStatus(id, statusIn, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PropertiesApi - axios parameter creator
 * @export
 */
export const PropertiesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create Private Property.
         * @summary Create Property
         * @param {PropertyIn} propertyIn Property to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProperty: async (propertyIn: PropertyIn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyIn' is not null or undefined
            assertParamExists('createProperty', 'propertyIn', propertyIn)
            const localVarPath = `/properties`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(propertyIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all properties based on selected IDs
         * @summary Get Several Properties
         * @param {string} ids IDs (separated by ,) of properties to fetch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSeveralProperties: async (ids: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('findSeveralProperties', 'ids', ids)
            const localVarPath = `/properties/several/{ids}`
                .replace(`{${"ids"}}`, encodeURIComponent(String(ids)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a property based on a single ID
         * @summary Get Property
         * @param {number} id ID of property to fetch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProperty: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProperty', 'id', id)
            const localVarPath = `/properties/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List All Properties
         * @summary List Properties
         * @param {boolean} [filterEnable] Filter only enabled.
         * @param {number} [pageSize] Pagination size
         * @param {number} [pageToken] Page index token
         * @param {string} [orderBy] order by field
         * @param {boolean} [sortDirDesc] is sorting desc
         * @param {string} [developerId] Filter Developer ID.
         * @param {'buy' | 'rent'} [service] Filter service type.
         * @param {'pro' | 'private'} [ownerType] Filter owner type.
         * @param {Array<'parking' | 'house' | 'apartment' | 'local' | 'field' | 'other'>} [typeParents] Filter type parents.
         * @param {string} [flowOrigin] Filter flow origin.
         * @param {'new' | 'old' | 'both'} [age] Filter age.
         * @param {Array<number>} [nbRooms] Filter number of rooms
         * @param {number} [minPrice] Filter min price
         * @param {number} [maxPrice] Filter max price
         * @param {number} [minArea] Filter min area
         * @param {number} [maxArea] Filter max area
         * @param {string} [minDeliveryDate] Filter min delivery date
         * @param {string} [maxDeliveryDate] Filter max delivery date
         * @param {string} [minCreatedDate] Filter min created date
         * @param {string} [maxCreatedDate] Filter max created date
         * @param {Array<string>} [labels] Filter labels
         * @param {Array<string>} [taxesExemption] Filter taxes exemption
         * @param {Array<GeoDistances>} [geoDistances] 
         * @param {Array<GeoBoundingBox>} [geoBoundingBox] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProperties: async (filterEnable?: boolean, pageSize?: number, pageToken?: number, orderBy?: string, sortDirDesc?: boolean, developerId?: string, service?: 'buy' | 'rent', ownerType?: 'pro' | 'private', typeParents?: Array<'parking' | 'house' | 'apartment' | 'local' | 'field' | 'other'>, flowOrigin?: string, age?: 'new' | 'old' | 'both', nbRooms?: Array<number>, minPrice?: number, maxPrice?: number, minArea?: number, maxArea?: number, minDeliveryDate?: string, maxDeliveryDate?: string, minCreatedDate?: string, maxCreatedDate?: string, labels?: Array<string>, taxesExemption?: Array<string>, geoDistances?: Array<GeoDistances>, geoBoundingBox?: Array<GeoBoundingBox>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/properties`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterEnable !== undefined) {
                localVarQueryParameter['filter_enable'] = filterEnable;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (pageToken !== undefined) {
                localVarQueryParameter['page_token'] = pageToken;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['order_by'] = orderBy;
            }

            if (sortDirDesc !== undefined) {
                localVarQueryParameter['sort_dir_desc'] = sortDirDesc;
            }

            if (developerId !== undefined) {
                localVarQueryParameter['developer_id'] = developerId;
            }

            if (service !== undefined) {
                localVarQueryParameter['service'] = service;
            }

            if (ownerType !== undefined) {
                localVarQueryParameter['owner_type'] = ownerType;
            }

            if (typeParents) {
                localVarQueryParameter['type_parents'] = typeParents;
            }

            if (flowOrigin !== undefined) {
                localVarQueryParameter['flow_origin'] = flowOrigin;
            }

            if (age !== undefined) {
                localVarQueryParameter['age'] = age;
            }

            if (nbRooms) {
                localVarQueryParameter['nb_rooms'] = nbRooms;
            }

            if (minPrice !== undefined) {
                localVarQueryParameter['min_price'] = minPrice;
            }

            if (maxPrice !== undefined) {
                localVarQueryParameter['max_price'] = maxPrice;
            }

            if (minArea !== undefined) {
                localVarQueryParameter['min_area'] = minArea;
            }

            if (maxArea !== undefined) {
                localVarQueryParameter['max_area'] = maxArea;
            }

            if (minDeliveryDate !== undefined) {
                localVarQueryParameter['min_delivery_date'] = (minDeliveryDate as any instanceof Date) ?
                    (minDeliveryDate as any).toISOString() :
                    minDeliveryDate;
            }

            if (maxDeliveryDate !== undefined) {
                localVarQueryParameter['max_delivery_date'] = (maxDeliveryDate as any instanceof Date) ?
                    (maxDeliveryDate as any).toISOString() :
                    maxDeliveryDate;
            }

            if (minCreatedDate !== undefined) {
                localVarQueryParameter['min_created_date'] = (minCreatedDate as any instanceof Date) ?
                    (minCreatedDate as any).toISOString() :
                    minCreatedDate;
            }

            if (maxCreatedDate !== undefined) {
                localVarQueryParameter['max_created_date'] = (maxCreatedDate as any instanceof Date) ?
                    (maxCreatedDate as any).toISOString() :
                    maxCreatedDate;
            }

            if (labels) {
                localVarQueryParameter['labels'] = labels;
            }

            if (taxesExemption) {
                localVarQueryParameter['taxes_exemption'] = taxesExemption;
            }

            if (geoDistances) {
                localVarQueryParameter['geo_distances'] = geoDistances;
            }

            if (geoBoundingBox) {
                localVarQueryParameter['geo_bounding_box'] = geoBoundingBox;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List All Clusters for map display
         * @summary List Clusters
         * @param {boolean} [filterEnable] Filter only enabled.
         * @param {string} [developerId] Filter Developer ID.
         * @param {'buy' | 'rent'} [service] Filter service type.
         * @param {'pro' | 'private'} [ownerType] Filter owner type.
         * @param {Array<'parking' | 'house' | 'apartment' | 'local' | 'field' | 'other'>} [typeParents] Filter type parents.
         * @param {string} [flowOrigin] Filter flow origin.
         * @param {'new' | 'old' | 'both'} [age] Filter age.
         * @param {Array<number>} [nbRooms] Filter number of rooms
         * @param {number} [minPrice] Filter min price
         * @param {number} [maxPrice] Filter max price
         * @param {number} [minArea] Filter min area
         * @param {number} [maxArea] Filter max area
         * @param {string} [minDeliveryDate] Filter min delivery date
         * @param {string} [maxDeliveryDate] Filter max delivery date
         * @param {string} [minCreatedDate] Filter min created date
         * @param {string} [maxCreatedDate] Filter max created date
         * @param {Array<string>} [labels] Filter labels
         * @param {Array<string>} [taxesExemption] Filter taxes exemption
         * @param {Array<GeoBoundingBox>} [geoBoundingBox] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPropertyClusters: async (filterEnable?: boolean, developerId?: string, service?: 'buy' | 'rent', ownerType?: 'pro' | 'private', typeParents?: Array<'parking' | 'house' | 'apartment' | 'local' | 'field' | 'other'>, flowOrigin?: string, age?: 'new' | 'old' | 'both', nbRooms?: Array<number>, minPrice?: number, maxPrice?: number, minArea?: number, maxArea?: number, minDeliveryDate?: string, maxDeliveryDate?: string, minCreatedDate?: string, maxCreatedDate?: string, labels?: Array<string>, taxesExemption?: Array<string>, geoBoundingBox?: Array<GeoBoundingBox>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/properties/clusters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterEnable !== undefined) {
                localVarQueryParameter['filter_enable'] = filterEnable;
            }

            if (developerId !== undefined) {
                localVarQueryParameter['developer_id'] = developerId;
            }

            if (service !== undefined) {
                localVarQueryParameter['service'] = service;
            }

            if (ownerType !== undefined) {
                localVarQueryParameter['owner_type'] = ownerType;
            }

            if (typeParents) {
                localVarQueryParameter['type_parents'] = typeParents;
            }

            if (flowOrigin !== undefined) {
                localVarQueryParameter['flow_origin'] = flowOrigin;
            }

            if (age !== undefined) {
                localVarQueryParameter['age'] = age;
            }

            if (nbRooms) {
                localVarQueryParameter['nb_rooms'] = nbRooms;
            }

            if (minPrice !== undefined) {
                localVarQueryParameter['min_price'] = minPrice;
            }

            if (maxPrice !== undefined) {
                localVarQueryParameter['max_price'] = maxPrice;
            }

            if (minArea !== undefined) {
                localVarQueryParameter['min_area'] = minArea;
            }

            if (maxArea !== undefined) {
                localVarQueryParameter['max_area'] = maxArea;
            }

            if (minDeliveryDate !== undefined) {
                localVarQueryParameter['min_delivery_date'] = (minDeliveryDate as any instanceof Date) ?
                    (minDeliveryDate as any).toISOString() :
                    minDeliveryDate;
            }

            if (maxDeliveryDate !== undefined) {
                localVarQueryParameter['max_delivery_date'] = (maxDeliveryDate as any instanceof Date) ?
                    (maxDeliveryDate as any).toISOString() :
                    maxDeliveryDate;
            }

            if (minCreatedDate !== undefined) {
                localVarQueryParameter['min_created_date'] = (minCreatedDate as any instanceof Date) ?
                    (minCreatedDate as any).toISOString() :
                    minCreatedDate;
            }

            if (maxCreatedDate !== undefined) {
                localVarQueryParameter['max_created_date'] = (maxCreatedDate as any instanceof Date) ?
                    (maxCreatedDate as any).toISOString() :
                    maxCreatedDate;
            }

            if (labels) {
                localVarQueryParameter['labels'] = labels;
            }

            if (taxesExemption) {
                localVarQueryParameter['taxes_exemption'] = taxesExemption;
            }

            if (geoBoundingBox) {
                localVarQueryParameter['geo_bounding_box'] = geoBoundingBox;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a property based on a single ID
         * @summary Update Property
         * @param {number} id ID of property to fetch
         * @param {PropertyIn} propertyIn Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProperty: async (id: number, propertyIn: PropertyIn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateProperty', 'id', id)
            // verify required parameter 'propertyIn' is not null or undefined
            assertParamExists('updateProperty', 'propertyIn', propertyIn)
            const localVarPath = `/properties/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(propertyIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PropertiesApi - functional programming interface
 * @export
 */
export const PropertiesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PropertiesApiAxiosParamCreator(configuration)
    return {
        /**
         * Create Private Property.
         * @summary Create Property
         * @param {PropertyIn} propertyIn Property to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProperty(propertyIn: PropertyIn, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Property>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProperty(propertyIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns all properties based on selected IDs
         * @summary Get Several Properties
         * @param {string} ids IDs (separated by ,) of properties to fetch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findSeveralProperties(ids: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertiesSeveral>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findSeveralProperties(ids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a property based on a single ID
         * @summary Get Property
         * @param {number} id ID of property to fetch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProperty(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Property>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProperty(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List All Properties
         * @summary List Properties
         * @param {boolean} [filterEnable] Filter only enabled.
         * @param {number} [pageSize] Pagination size
         * @param {number} [pageToken] Page index token
         * @param {string} [orderBy] order by field
         * @param {boolean} [sortDirDesc] is sorting desc
         * @param {string} [developerId] Filter Developer ID.
         * @param {'buy' | 'rent'} [service] Filter service type.
         * @param {'pro' | 'private'} [ownerType] Filter owner type.
         * @param {Array<'parking' | 'house' | 'apartment' | 'local' | 'field' | 'other'>} [typeParents] Filter type parents.
         * @param {string} [flowOrigin] Filter flow origin.
         * @param {'new' | 'old' | 'both'} [age] Filter age.
         * @param {Array<number>} [nbRooms] Filter number of rooms
         * @param {number} [minPrice] Filter min price
         * @param {number} [maxPrice] Filter max price
         * @param {number} [minArea] Filter min area
         * @param {number} [maxArea] Filter max area
         * @param {string} [minDeliveryDate] Filter min delivery date
         * @param {string} [maxDeliveryDate] Filter max delivery date
         * @param {string} [minCreatedDate] Filter min created date
         * @param {string} [maxCreatedDate] Filter max created date
         * @param {Array<string>} [labels] Filter labels
         * @param {Array<string>} [taxesExemption] Filter taxes exemption
         * @param {Array<GeoDistances>} [geoDistances] 
         * @param {Array<GeoBoundingBox>} [geoBoundingBox] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listProperties(filterEnable?: boolean, pageSize?: number, pageToken?: number, orderBy?: string, sortDirDesc?: boolean, developerId?: string, service?: 'buy' | 'rent', ownerType?: 'pro' | 'private', typeParents?: Array<'parking' | 'house' | 'apartment' | 'local' | 'field' | 'other'>, flowOrigin?: string, age?: 'new' | 'old' | 'both', nbRooms?: Array<number>, minPrice?: number, maxPrice?: number, minArea?: number, maxArea?: number, minDeliveryDate?: string, maxDeliveryDate?: string, minCreatedDate?: string, maxCreatedDate?: string, labels?: Array<string>, taxesExemption?: Array<string>, geoDistances?: Array<GeoDistances>, geoBoundingBox?: Array<GeoBoundingBox>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFetchProperties>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listProperties(filterEnable, pageSize, pageToken, orderBy, sortDirDesc, developerId, service, ownerType, typeParents, flowOrigin, age, nbRooms, minPrice, maxPrice, minArea, maxArea, minDeliveryDate, maxDeliveryDate, minCreatedDate, maxCreatedDate, labels, taxesExemption, geoDistances, geoBoundingBox, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List All Clusters for map display
         * @summary List Clusters
         * @param {boolean} [filterEnable] Filter only enabled.
         * @param {string} [developerId] Filter Developer ID.
         * @param {'buy' | 'rent'} [service] Filter service type.
         * @param {'pro' | 'private'} [ownerType] Filter owner type.
         * @param {Array<'parking' | 'house' | 'apartment' | 'local' | 'field' | 'other'>} [typeParents] Filter type parents.
         * @param {string} [flowOrigin] Filter flow origin.
         * @param {'new' | 'old' | 'both'} [age] Filter age.
         * @param {Array<number>} [nbRooms] Filter number of rooms
         * @param {number} [minPrice] Filter min price
         * @param {number} [maxPrice] Filter max price
         * @param {number} [minArea] Filter min area
         * @param {number} [maxArea] Filter max area
         * @param {string} [minDeliveryDate] Filter min delivery date
         * @param {string} [maxDeliveryDate] Filter max delivery date
         * @param {string} [minCreatedDate] Filter min created date
         * @param {string} [maxCreatedDate] Filter max created date
         * @param {Array<string>} [labels] Filter labels
         * @param {Array<string>} [taxesExemption] Filter taxes exemption
         * @param {Array<GeoBoundingBox>} [geoBoundingBox] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPropertyClusters(filterEnable?: boolean, developerId?: string, service?: 'buy' | 'rent', ownerType?: 'pro' | 'private', typeParents?: Array<'parking' | 'house' | 'apartment' | 'local' | 'field' | 'other'>, flowOrigin?: string, age?: 'new' | 'old' | 'both', nbRooms?: Array<number>, minPrice?: number, maxPrice?: number, minArea?: number, maxArea?: number, minDeliveryDate?: string, maxDeliveryDate?: string, minCreatedDate?: string, maxCreatedDate?: string, labels?: Array<string>, taxesExemption?: Array<string>, geoBoundingBox?: Array<GeoBoundingBox>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFetchClusters>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPropertyClusters(filterEnable, developerId, service, ownerType, typeParents, flowOrigin, age, nbRooms, minPrice, maxPrice, minArea, maxArea, minDeliveryDate, maxDeliveryDate, minCreatedDate, maxCreatedDate, labels, taxesExemption, geoBoundingBox, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a property based on a single ID
         * @summary Update Property
         * @param {number} id ID of property to fetch
         * @param {PropertyIn} propertyIn Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProperty(id: number, propertyIn: PropertyIn, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProperty(id, propertyIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PropertiesApi - factory interface
 * @export
 */
export const PropertiesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PropertiesApiFp(configuration)
    return {
        /**
         * Create Private Property.
         * @summary Create Property
         * @param {PropertyIn} propertyIn Property to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProperty(propertyIn: PropertyIn, options?: any): AxiosPromise<Property> {
            return localVarFp.createProperty(propertyIn, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all properties based on selected IDs
         * @summary Get Several Properties
         * @param {string} ids IDs (separated by ,) of properties to fetch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSeveralProperties(ids: string, options?: any): AxiosPromise<PropertiesSeveral> {
            return localVarFp.findSeveralProperties(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a property based on a single ID
         * @summary Get Property
         * @param {number} id ID of property to fetch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProperty(id: number, options?: any): AxiosPromise<Property> {
            return localVarFp.getProperty(id, options).then((request) => request(axios, basePath));
        },
        /**
         * List All Properties
         * @summary List Properties
         * @param {boolean} [filterEnable] Filter only enabled.
         * @param {number} [pageSize] Pagination size
         * @param {number} [pageToken] Page index token
         * @param {string} [orderBy] order by field
         * @param {boolean} [sortDirDesc] is sorting desc
         * @param {string} [developerId] Filter Developer ID.
         * @param {'buy' | 'rent'} [service] Filter service type.
         * @param {'pro' | 'private'} [ownerType] Filter owner type.
         * @param {Array<'parking' | 'house' | 'apartment' | 'local' | 'field' | 'other'>} [typeParents] Filter type parents.
         * @param {string} [flowOrigin] Filter flow origin.
         * @param {'new' | 'old' | 'both'} [age] Filter age.
         * @param {Array<number>} [nbRooms] Filter number of rooms
         * @param {number} [minPrice] Filter min price
         * @param {number} [maxPrice] Filter max price
         * @param {number} [minArea] Filter min area
         * @param {number} [maxArea] Filter max area
         * @param {string} [minDeliveryDate] Filter min delivery date
         * @param {string} [maxDeliveryDate] Filter max delivery date
         * @param {string} [minCreatedDate] Filter min created date
         * @param {string} [maxCreatedDate] Filter max created date
         * @param {Array<string>} [labels] Filter labels
         * @param {Array<string>} [taxesExemption] Filter taxes exemption
         * @param {Array<GeoDistances>} [geoDistances] 
         * @param {Array<GeoBoundingBox>} [geoBoundingBox] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProperties(filterEnable?: boolean, pageSize?: number, pageToken?: number, orderBy?: string, sortDirDesc?: boolean, developerId?: string, service?: 'buy' | 'rent', ownerType?: 'pro' | 'private', typeParents?: Array<'parking' | 'house' | 'apartment' | 'local' | 'field' | 'other'>, flowOrigin?: string, age?: 'new' | 'old' | 'both', nbRooms?: Array<number>, minPrice?: number, maxPrice?: number, minArea?: number, maxArea?: number, minDeliveryDate?: string, maxDeliveryDate?: string, minCreatedDate?: string, maxCreatedDate?: string, labels?: Array<string>, taxesExemption?: Array<string>, geoDistances?: Array<GeoDistances>, geoBoundingBox?: Array<GeoBoundingBox>, options?: any): AxiosPromise<ResponseFetchProperties> {
            return localVarFp.listProperties(filterEnable, pageSize, pageToken, orderBy, sortDirDesc, developerId, service, ownerType, typeParents, flowOrigin, age, nbRooms, minPrice, maxPrice, minArea, maxArea, minDeliveryDate, maxDeliveryDate, minCreatedDate, maxCreatedDate, labels, taxesExemption, geoDistances, geoBoundingBox, options).then((request) => request(axios, basePath));
        },
        /**
         * List All Clusters for map display
         * @summary List Clusters
         * @param {boolean} [filterEnable] Filter only enabled.
         * @param {string} [developerId] Filter Developer ID.
         * @param {'buy' | 'rent'} [service] Filter service type.
         * @param {'pro' | 'private'} [ownerType] Filter owner type.
         * @param {Array<'parking' | 'house' | 'apartment' | 'local' | 'field' | 'other'>} [typeParents] Filter type parents.
         * @param {string} [flowOrigin] Filter flow origin.
         * @param {'new' | 'old' | 'both'} [age] Filter age.
         * @param {Array<number>} [nbRooms] Filter number of rooms
         * @param {number} [minPrice] Filter min price
         * @param {number} [maxPrice] Filter max price
         * @param {number} [minArea] Filter min area
         * @param {number} [maxArea] Filter max area
         * @param {string} [minDeliveryDate] Filter min delivery date
         * @param {string} [maxDeliveryDate] Filter max delivery date
         * @param {string} [minCreatedDate] Filter min created date
         * @param {string} [maxCreatedDate] Filter max created date
         * @param {Array<string>} [labels] Filter labels
         * @param {Array<string>} [taxesExemption] Filter taxes exemption
         * @param {Array<GeoBoundingBox>} [geoBoundingBox] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPropertyClusters(filterEnable?: boolean, developerId?: string, service?: 'buy' | 'rent', ownerType?: 'pro' | 'private', typeParents?: Array<'parking' | 'house' | 'apartment' | 'local' | 'field' | 'other'>, flowOrigin?: string, age?: 'new' | 'old' | 'both', nbRooms?: Array<number>, minPrice?: number, maxPrice?: number, minArea?: number, maxArea?: number, minDeliveryDate?: string, maxDeliveryDate?: string, minCreatedDate?: string, maxCreatedDate?: string, labels?: Array<string>, taxesExemption?: Array<string>, geoBoundingBox?: Array<GeoBoundingBox>, options?: any): AxiosPromise<ResponseFetchClusters> {
            return localVarFp.listPropertyClusters(filterEnable, developerId, service, ownerType, typeParents, flowOrigin, age, nbRooms, minPrice, maxPrice, minArea, maxArea, minDeliveryDate, maxDeliveryDate, minCreatedDate, maxCreatedDate, labels, taxesExemption, geoBoundingBox, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a property based on a single ID
         * @summary Update Property
         * @param {number} id ID of property to fetch
         * @param {PropertyIn} propertyIn Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProperty(id: number, propertyIn: PropertyIn, options?: any): AxiosPromise<void> {
            return localVarFp.updateProperty(id, propertyIn, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PropertiesApi - object-oriented interface
 * @export
 * @class PropertiesApi
 * @extends {BaseAPI}
 */
export class PropertiesApi extends BaseAPI {
    /**
     * Create Private Property.
     * @summary Create Property
     * @param {PropertyIn} propertyIn Property to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertiesApi
     */
    public createProperty(propertyIn: PropertyIn, options?: AxiosRequestConfig) {
        return PropertiesApiFp(this.configuration).createProperty(propertyIn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all properties based on selected IDs
     * @summary Get Several Properties
     * @param {string} ids IDs (separated by ,) of properties to fetch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertiesApi
     */
    public findSeveralProperties(ids: string, options?: AxiosRequestConfig) {
        return PropertiesApiFp(this.configuration).findSeveralProperties(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a property based on a single ID
     * @summary Get Property
     * @param {number} id ID of property to fetch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertiesApi
     */
    public getProperty(id: number, options?: AxiosRequestConfig) {
        return PropertiesApiFp(this.configuration).getProperty(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List All Properties
     * @summary List Properties
     * @param {boolean} [filterEnable] Filter only enabled.
     * @param {number} [pageSize] Pagination size
     * @param {number} [pageToken] Page index token
     * @param {string} [orderBy] order by field
     * @param {boolean} [sortDirDesc] is sorting desc
     * @param {string} [developerId] Filter Developer ID.
     * @param {'buy' | 'rent'} [service] Filter service type.
     * @param {'pro' | 'private'} [ownerType] Filter owner type.
     * @param {Array<'parking' | 'house' | 'apartment' | 'local' | 'field' | 'other'>} [typeParents] Filter type parents.
     * @param {string} [flowOrigin] Filter flow origin.
     * @param {'new' | 'old' | 'both'} [age] Filter age.
     * @param {Array<number>} [nbRooms] Filter number of rooms
     * @param {number} [minPrice] Filter min price
     * @param {number} [maxPrice] Filter max price
     * @param {number} [minArea] Filter min area
     * @param {number} [maxArea] Filter max area
     * @param {string} [minDeliveryDate] Filter min delivery date
     * @param {string} [maxDeliveryDate] Filter max delivery date
     * @param {string} [minCreatedDate] Filter min created date
     * @param {string} [maxCreatedDate] Filter max created date
     * @param {Array<string>} [labels] Filter labels
     * @param {Array<string>} [taxesExemption] Filter taxes exemption
     * @param {Array<GeoDistances>} [geoDistances] 
     * @param {Array<GeoBoundingBox>} [geoBoundingBox] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertiesApi
     */
    public listProperties(filterEnable?: boolean, pageSize?: number, pageToken?: number, orderBy?: string, sortDirDesc?: boolean, developerId?: string, service?: 'buy' | 'rent', ownerType?: 'pro' | 'private', typeParents?: Array<'parking' | 'house' | 'apartment' | 'local' | 'field' | 'other'>, flowOrigin?: string, age?: 'new' | 'old' | 'both', nbRooms?: Array<number>, minPrice?: number, maxPrice?: number, minArea?: number, maxArea?: number, minDeliveryDate?: string, maxDeliveryDate?: string, minCreatedDate?: string, maxCreatedDate?: string, labels?: Array<string>, taxesExemption?: Array<string>, geoDistances?: Array<GeoDistances>, geoBoundingBox?: Array<GeoBoundingBox>, options?: AxiosRequestConfig) {
        return PropertiesApiFp(this.configuration).listProperties(filterEnable, pageSize, pageToken, orderBy, sortDirDesc, developerId, service, ownerType, typeParents, flowOrigin, age, nbRooms, minPrice, maxPrice, minArea, maxArea, minDeliveryDate, maxDeliveryDate, minCreatedDate, maxCreatedDate, labels, taxesExemption, geoDistances, geoBoundingBox, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List All Clusters for map display
     * @summary List Clusters
     * @param {boolean} [filterEnable] Filter only enabled.
     * @param {string} [developerId] Filter Developer ID.
     * @param {'buy' | 'rent'} [service] Filter service type.
     * @param {'pro' | 'private'} [ownerType] Filter owner type.
     * @param {Array<'parking' | 'house' | 'apartment' | 'local' | 'field' | 'other'>} [typeParents] Filter type parents.
     * @param {string} [flowOrigin] Filter flow origin.
     * @param {'new' | 'old' | 'both'} [age] Filter age.
     * @param {Array<number>} [nbRooms] Filter number of rooms
     * @param {number} [minPrice] Filter min price
     * @param {number} [maxPrice] Filter max price
     * @param {number} [minArea] Filter min area
     * @param {number} [maxArea] Filter max area
     * @param {string} [minDeliveryDate] Filter min delivery date
     * @param {string} [maxDeliveryDate] Filter max delivery date
     * @param {string} [minCreatedDate] Filter min created date
     * @param {string} [maxCreatedDate] Filter max created date
     * @param {Array<string>} [labels] Filter labels
     * @param {Array<string>} [taxesExemption] Filter taxes exemption
     * @param {Array<GeoBoundingBox>} [geoBoundingBox] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertiesApi
     */
    public listPropertyClusters(filterEnable?: boolean, developerId?: string, service?: 'buy' | 'rent', ownerType?: 'pro' | 'private', typeParents?: Array<'parking' | 'house' | 'apartment' | 'local' | 'field' | 'other'>, flowOrigin?: string, age?: 'new' | 'old' | 'both', nbRooms?: Array<number>, minPrice?: number, maxPrice?: number, minArea?: number, maxArea?: number, minDeliveryDate?: string, maxDeliveryDate?: string, minCreatedDate?: string, maxCreatedDate?: string, labels?: Array<string>, taxesExemption?: Array<string>, geoBoundingBox?: Array<GeoBoundingBox>, options?: AxiosRequestConfig) {
        return PropertiesApiFp(this.configuration).listPropertyClusters(filterEnable, developerId, service, ownerType, typeParents, flowOrigin, age, nbRooms, minPrice, maxPrice, minArea, maxArea, minDeliveryDate, maxDeliveryDate, minCreatedDate, maxCreatedDate, labels, taxesExemption, geoBoundingBox, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a property based on a single ID
     * @summary Update Property
     * @param {number} id ID of property to fetch
     * @param {PropertyIn} propertyIn Body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertiesApi
     */
    public updateProperty(id: number, propertyIn: PropertyIn, options?: AxiosRequestConfig) {
        return PropertiesApiFp(this.configuration).updateProperty(id, propertyIn, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SCPIsApi - axios parameter creator
 * @export
 */
export const SCPIsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create SCPI
         * @summary Create SCPI
         * @param {ScpiIn} scpiIn Body
         * @param {boolean} [shared] Is shared with subscription.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSCPI: async (scpiIn: ScpiIn, shared?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scpiIn' is not null or undefined
            assertParamExists('createSCPI', 'scpiIn', scpiIn)
            const localVarPath = `/scpi`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (shared !== undefined) {
                localVarQueryParameter['shared'] = shared;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scpiIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a scpi based on a single ID
         * @summary Get SCPI
         * @param {number} id SCPI ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSCPI: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSCPI', 'id', id)
            const localVarPath = `/scpi/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List All SCPIs
         * @summary List SCPIs
         * @param {number} [pageSize] Pagination size
         * @param {number} [pageToken] Page index token
         * @param {string} [managerId] Manager ID.
         * @param {Array<'fiscal' | 'rendement' | 'capitalisation'>} [scpiTypes] Array of scpi types to filter.
         * @param {boolean} [filterEnable] Filter only enabled.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSCPIs: async (pageSize?: number, pageToken?: number, managerId?: string, scpiTypes?: Array<'fiscal' | 'rendement' | 'capitalisation'>, filterEnable?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/scpi`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (pageToken !== undefined) {
                localVarQueryParameter['page_token'] = pageToken;
            }

            if (managerId !== undefined) {
                localVarQueryParameter['manager_id'] = managerId;
            }

            if (scpiTypes) {
                localVarQueryParameter['scpi_types'] = scpiTypes;
            }

            if (filterEnable !== undefined) {
                localVarQueryParameter['filter_enable'] = filterEnable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a scpi based on a single ID
         * @summary Update SCPI
         * @param {number} id SCPI ID
         * @param {ScpiIn} scpiIn Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSCPI: async (id: number, scpiIn: ScpiIn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSCPI', 'id', id)
            // verify required parameter 'scpiIn' is not null or undefined
            assertParamExists('updateSCPI', 'scpiIn', scpiIn)
            const localVarPath = `/scpi/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scpiIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a scpi status based on a single ID
         * @summary Update SCPI Status
         * @param {number} id SCPI ID
         * @param {StatusInShort} statusInShort Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSCPIStatus: async (id: number, statusInShort: StatusInShort, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSCPIStatus', 'id', id)
            // verify required parameter 'statusInShort' is not null or undefined
            assertParamExists('updateSCPIStatus', 'statusInShort', statusInShort)
            const localVarPath = `/scpi/{id}/status`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(statusInShort, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SCPIsApi - functional programming interface
 * @export
 */
export const SCPIsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SCPIsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create SCPI
         * @summary Create SCPI
         * @param {ScpiIn} scpiIn Body
         * @param {boolean} [shared] Is shared with subscription.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSCPI(scpiIn: ScpiIn, shared?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Scpi>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSCPI(scpiIn, shared, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a scpi based on a single ID
         * @summary Get SCPI
         * @param {number} id SCPI ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSCPI(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Scpi>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSCPI(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List All SCPIs
         * @summary List SCPIs
         * @param {number} [pageSize] Pagination size
         * @param {number} [pageToken] Page index token
         * @param {string} [managerId] Manager ID.
         * @param {Array<'fiscal' | 'rendement' | 'capitalisation'>} [scpiTypes] Array of scpi types to filter.
         * @param {boolean} [filterEnable] Filter only enabled.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSCPIs(pageSize?: number, pageToken?: number, managerId?: string, scpiTypes?: Array<'fiscal' | 'rendement' | 'capitalisation'>, filterEnable?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFetchSCPIs>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSCPIs(pageSize, pageToken, managerId, scpiTypes, filterEnable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a scpi based on a single ID
         * @summary Update SCPI
         * @param {number} id SCPI ID
         * @param {ScpiIn} scpiIn Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSCPI(id: number, scpiIn: ScpiIn, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSCPI(id, scpiIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a scpi status based on a single ID
         * @summary Update SCPI Status
         * @param {number} id SCPI ID
         * @param {StatusInShort} statusInShort Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSCPIStatus(id: number, statusInShort: StatusInShort, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSCPIStatus(id, statusInShort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SCPIsApi - factory interface
 * @export
 */
export const SCPIsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SCPIsApiFp(configuration)
    return {
        /**
         * Create SCPI
         * @summary Create SCPI
         * @param {ScpiIn} scpiIn Body
         * @param {boolean} [shared] Is shared with subscription.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSCPI(scpiIn: ScpiIn, shared?: boolean, options?: any): AxiosPromise<Scpi> {
            return localVarFp.createSCPI(scpiIn, shared, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a scpi based on a single ID
         * @summary Get SCPI
         * @param {number} id SCPI ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSCPI(id: number, options?: any): AxiosPromise<Scpi> {
            return localVarFp.getSCPI(id, options).then((request) => request(axios, basePath));
        },
        /**
         * List All SCPIs
         * @summary List SCPIs
         * @param {number} [pageSize] Pagination size
         * @param {number} [pageToken] Page index token
         * @param {string} [managerId] Manager ID.
         * @param {Array<'fiscal' | 'rendement' | 'capitalisation'>} [scpiTypes] Array of scpi types to filter.
         * @param {boolean} [filterEnable] Filter only enabled.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSCPIs(pageSize?: number, pageToken?: number, managerId?: string, scpiTypes?: Array<'fiscal' | 'rendement' | 'capitalisation'>, filterEnable?: boolean, options?: any): AxiosPromise<ResponseFetchSCPIs> {
            return localVarFp.listSCPIs(pageSize, pageToken, managerId, scpiTypes, filterEnable, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a scpi based on a single ID
         * @summary Update SCPI
         * @param {number} id SCPI ID
         * @param {ScpiIn} scpiIn Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSCPI(id: number, scpiIn: ScpiIn, options?: any): AxiosPromise<void> {
            return localVarFp.updateSCPI(id, scpiIn, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a scpi status based on a single ID
         * @summary Update SCPI Status
         * @param {number} id SCPI ID
         * @param {StatusInShort} statusInShort Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSCPIStatus(id: number, statusInShort: StatusInShort, options?: any): AxiosPromise<void> {
            return localVarFp.updateSCPIStatus(id, statusInShort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SCPIsApi - object-oriented interface
 * @export
 * @class SCPIsApi
 * @extends {BaseAPI}
 */
export class SCPIsApi extends BaseAPI {
    /**
     * Create SCPI
     * @summary Create SCPI
     * @param {ScpiIn} scpiIn Body
     * @param {boolean} [shared] Is shared with subscription.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SCPIsApi
     */
    public createSCPI(scpiIn: ScpiIn, shared?: boolean, options?: AxiosRequestConfig) {
        return SCPIsApiFp(this.configuration).createSCPI(scpiIn, shared, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a scpi based on a single ID
     * @summary Get SCPI
     * @param {number} id SCPI ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SCPIsApi
     */
    public getSCPI(id: number, options?: AxiosRequestConfig) {
        return SCPIsApiFp(this.configuration).getSCPI(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List All SCPIs
     * @summary List SCPIs
     * @param {number} [pageSize] Pagination size
     * @param {number} [pageToken] Page index token
     * @param {string} [managerId] Manager ID.
     * @param {Array<'fiscal' | 'rendement' | 'capitalisation'>} [scpiTypes] Array of scpi types to filter.
     * @param {boolean} [filterEnable] Filter only enabled.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SCPIsApi
     */
    public listSCPIs(pageSize?: number, pageToken?: number, managerId?: string, scpiTypes?: Array<'fiscal' | 'rendement' | 'capitalisation'>, filterEnable?: boolean, options?: AxiosRequestConfig) {
        return SCPIsApiFp(this.configuration).listSCPIs(pageSize, pageToken, managerId, scpiTypes, filterEnable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a scpi based on a single ID
     * @summary Update SCPI
     * @param {number} id SCPI ID
     * @param {ScpiIn} scpiIn Body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SCPIsApi
     */
    public updateSCPI(id: number, scpiIn: ScpiIn, options?: AxiosRequestConfig) {
        return SCPIsApiFp(this.configuration).updateSCPI(id, scpiIn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a scpi status based on a single ID
     * @summary Update SCPI Status
     * @param {number} id SCPI ID
     * @param {StatusInShort} statusInShort Body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SCPIsApi
     */
    public updateSCPIStatus(id: number, statusInShort: StatusInShort, options?: AxiosRequestConfig) {
        return SCPIsApiFp(this.configuration).updateSCPIStatus(id, statusInShort, options).then((request) => request(this.axios, this.basePath));
    }
}


