<template>
  <b-sidebar
    id="sidebar-program"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Détails du programme</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- Body -->
      <div class="mx-1 mt-1" v-if="program">
        <program-card-expanded :program="program" />
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import { BSidebar } from "bootstrap-vue";
import ProgramCardExpanded from "./ProgramCardExpanded";
import useEstimate from "../../useEstimate";

export default {
  components: {
    BSidebar,

    ProgramCardExpanded,
  },
  props: {
    programId: {
      type: String,
      required: true,
    },
  },
  watch: {
    programId(val) {
      this.getProgramByID(val).then((response) => {
        this.program = response.data;
      });
    },
  },
  setup() {
    const { getProgramByID } = useEstimate();
    let program = null;

    return {
      getProgramByID,
      program,
    };
  },
};
</script>

<style></style>
