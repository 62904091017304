<template>
  <div>
    <!-- Stats Card Vertical -->
    <b-row class="match-height">
      <b-col md="4" sm="6">
        <statistic-card-vertical
          color="primary"
          icon="TrendingDownIcon"
          style="background: #f3f2f7"
          :statistic="currency(estimationResult.estimatedPriceMin)"
          :statistic-title="
            'Prix minimum : ' +
            currency(estimationResult.squareMeterPriceMinimum) +
            '/m²'
          "
        />
      </b-col>
      <b-col md="4" sm="6">
        <statistic-card-vertical
          color="success"
          icon="EyeIcon"
          :confidenceIndice="estimationResult.confidenceIndice"
          :statistic="currency(estimationResult.estimatedPrice)"
          :statistic-title="
            'Prix : ' + currency(estimationResult.squareMeterPrice) + '/m²'
          "
        />
      </b-col>
      <b-col md="4" sm="6">
        <statistic-card-vertical
          icon="TrendingUpIcon"
          style="background: #f3f2f7"
          :statistic="currency(estimationResult.estimatedPriceMax)"
          :statistic-title="
            'Prix maximum : ' +
            currency(estimationResult.squareMeterPriceMaximum) +
            '/m²'
          "
          color="primary"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";
import { currency } from "@core/utils/filter";

export default {
  components: {
    BRow,
    BCol,
    StatisticCardVertical,
  },
  props: {
    estimationResult: {
      type: Object,
      required: true,
    },
  },
  methods: {
    currency,
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.card {
  margin-bottom: 0.5rem !important;
}
</style>
