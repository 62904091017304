<template>
  <div class="mt-2">
    <b-row v-if="!estimationResult">
      <b-col cols="12">
        <b-card
          title="Estimez un bien immobilier 🚀"
          sub-title="Renseignez les informations sur le bien pour lancer une estimation. Vous pouvez cliquer directement sur la carte pour trouver une adresse."
        >
          <b-row class="justify-content-md-center">
            <b-col lg="6" class="mt-1">
              <select-card
                @create-estimation-selected="createEstimationSelected"
                :query="query"
              />
            </b-col>
            <b-col lg="6">
              <map-card :query="query" />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="estimationResult">
      <b-col>
        <tab-card
          @back-form="backForm()"
          :query="query"
          :estimationResult="estimationResult"
          :adsRealestate="adsRealestate"
          :adsPrograms="adsPrograms"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { PageBoxed } from "@/views/utils/PageBoxed.js";
import store from "@/store";
import { BCard, BRow, BCol } from "bootstrap-vue";
import { onUnmounted } from "@vue/composition-api";
import MapCard from "./components/MapCard.vue";
import SelectCard from "./components/SelectCard.vue";
import TabCard from "./components/TabCard.vue";
import estimateStoreModule from "./estimateStoreModule";
import useEstimate from "./useEstimate";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    MapCard,
    SelectCard,
    TabCard,
  },
  mixins: [PageBoxed],
  data() {
    return {
      contentWidth: this.$store.state.appConfig.layout.contentWidth,
    };
  },
  setup() {
    const ESTIMATE_APP_STORE_MODULE_NAME = "app-estimate";

    const {
      query,
      estimationResult,
      adsRealestate,
      adsPrograms,

      fetchAddressByLatlng,
      createEstimate,
      listProperties,
      suggestPrograms,
    } = useEstimate();

    // Register module
    if (!store.hasModule(ESTIMATE_APP_STORE_MODULE_NAME))
      store.registerModule(ESTIMATE_APP_STORE_MODULE_NAME, estimateStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ESTIMATE_APP_STORE_MODULE_NAME))
        store.unregisterModule(ESTIMATE_APP_STORE_MODULE_NAME);
    });

    return {
      query,
      estimationResult,
      adsRealestate,
      adsPrograms,

      fetchAddressByLatlng,
      createEstimate,
      listProperties,
      suggestPrograms,
    };
  },
  watch: {
    "query.latlng"() {
      this.fetchAddressByLatlng(this.query).then((response) => {
        this.query.address = response.data.features[0].properties.label;
      });
    },
  },
  methods: {
    createEstimationSelected() {
      this.query.loading = true;
      this.createEstimate(this.query)
        .then((response) => {
          this.estimationResult = response.data;
          this.evaluateSquareMetersPrices(this.estimationResult);

          // Search for Ads suggest (Realestate, Programs, DVF)
          this.listProperties(this.query, this.estimationResult).then(
            (response) => {
              if (
                response.data.properties &&
                response.data.properties.length > 0
              ) {
                this.adsRealestate = response.data.properties;
              } else {
                this.adsRealestate = [];
              }
            }
          );
          this.suggestPrograms(this.query).then((response) => {
            if (response.data.lots && response.data.lots.length > 0) {
              this.adsPrograms = response.data.lots;
            } else {
              this.adsPrograms = [];
            }
          });

          this.query.loading = false;
          this.$swal({
            icon: "success",
            title: "Estimation réussie",
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        })
        .catch(() => {
          this.query.loading = false;
        });
    },
    evaluateSquareMetersPrices(estimationResult) {
      this.estimationResult.squareMeterPrice =
        estimationResult.estimatedPrice / this.query.area;
      this.estimationResult.squareMeterPriceMaximum =
        estimationResult.estimatedPriceMax / this.query.area;
      this.estimationResult.squareMeterPriceMinimum =
        estimationResult.estimatedPriceMin / this.query.area;
    },
    addressSelected(address, latlng) {
      this.query.address = address;
      this.query.latlng = latlng;
    },
    backForm() {
      this.estimationResult = null;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";

.background-color {
  background-color: rgba(grey, 0.18) !important;
}
</style>

<style scoped>
.card {
  margin-bottom: 0.5rem !important;
}
</style>
