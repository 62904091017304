<template>
  <div>
    <b-row class="my-1">
      <b-col cols="3">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="backToForm"
        >
          <div>
            <feather-icon icon="ArrowLeftIcon" class="mr-50" />
            <span>Retour</span>
          </div>
        </b-button>
      </b-col>
    </b-row>

    <b-tabs>
      <b-tab>
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span
            >{{
              query.propertyType === "apartment" ? "Appartement" : "Maison"
            }}
            - {{ query.area }}m² - {{ query.rooms }} pièce{{
              query.rooms > 1 ? "s" : ""
            }}
            - {{ query.address }}</span
          >
        </template>

        <estimation-cards
          v-if="estimationResult"
          :estimationResult="estimationResult"
        />
      </b-tab>
    </b-tabs>
    <b-tabs>
      <b-tab>
        <template #title>
          <feather-icon icon="CodepenIcon" />
          <span>Immobilier neuf</span>
        </template>

        <programs-cards
          v-if="adsPrograms && adsPrograms.length >= 0"
          :adsPrograms="adsPrograms"
        />

        <b-alert
          variant="primary"
          :show="!adsPrograms || adsPrograms.length === 0"
        >
          <h4 class="alert-heading">
            Aucun programme similaire n'a été trouvé à proximité.
          </h4>
        </b-alert>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="EyeIcon" />
          <span>Annonces</span>
        </template>

        <property-cards
          v-if="adsRealestate && adsRealestate.length >= 0"
          :adsRealestate="adsRealestate"
        />

        <b-alert
          variant="primary"
          :show="!adsRealestate || adsRealestate.length === 0"
        >
          <h4 class="alert-heading">
            Aucune annonce similaire n'a été trouvé à proximité.
          </h4>
        </b-alert>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import { BRow, BCol, BButton, BAlert, BTabs, BTab } from "bootstrap-vue";
import EstimationCards from "./tabs/EstimationCards.vue";
import PropertyCards from "./tabs/PropertyCards.vue";
import ProgramsCards from "./tabs/ProgramsCards.vue";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BAlert,
    BTabs,
    BTab,

    // Local Components
    EstimationCards,
    PropertyCards,
    ProgramsCards,
  },
  props: {
    query: {
      type: Object,
      required: true,
    },
    estimationResult: {
      type: Object,
      required: false,
    },
    adsRealestate: {
      type: Array,
      required: false,
    },
    adsPrograms: {
      type: Array,
      required: false,
    },
  },
  directives: {
    Ripple,
  },
  methods: {
    backToForm() {
      this.$emit("back-form");
    },
  },
};
</script>

<style></style>
