<template>
  <section class="grid-view four-col-grid-view">
    <!-- SideBar Right -->
    <program-sidebar :program-id="selectedProgramId" />

    <b-card
      v-for="ad in adsPrograms"
      :key="ad.id_program"
      class="itemgrid-card"
      @click="selectProgram(ad.id_program)"
      v-b-toggle.sidebar-program
      no-body
    >
      <div>
        <b-link>
          <b-img
            :alt="`${ad.id_program}`"
            fluid
            class="tabs-img-estimate"
            :src="ad.brochure_url"
            @error="imageLoadError(ad.id_program)"
          />
        </b-link>
      </div>

      <!-- Product Details -->
      <b-card-body>
        <h6 class="item-price">
          {{ ad.location.sector_name }}
        </h6>
        <div class="item-wrapper">
          <div class="item-rating">
            <h6 class="item-price">
              {{ currency(ad.price) }}
            </h6>
          </div>
          <div>
            <span> {{ currency(ad.price / ad.area) }}/m² </span>
          </div>
        </div>
        <div class="item-wrapper">
          <div class="item-rating">
            <b-badge variant="light-success" class="mr-1">
              {{ ad.area }} m²
            </b-badge>
            <b-badge variant="light-primary" class="ml-25">
              {{ ad.rooms }}
              {{ ad.rooms > 1 ? "pièces" : "pièce" }}
            </b-badge>
          </div>
          <div>
            <span>
              {{ ad.delivery_date.slice(0, 10) }}
            </span>
          </div>
        </div>
      </b-card-body>
    </b-card>
  </section>
</template>

<script>
import { BCard, BBadge, BCardBody, BImg, BLink, VBToggle } from "bootstrap-vue";
import { currency } from "@core/utils/filter";
import ProgramSidebar from "../components/ProgramSidebar.vue";

export default {
  components: {
    BCard,
    BBadge,
    BCardBody,
    BImg,
    BLink,

    ProgramSidebar,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  props: {
    adsPrograms: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedProgramId: "",
    };
  },
  methods: {
    currency,
    selectProgram(programId) {
      this.selectedProgramId = programId;
    },
    imageLoadError(adID) {
      const ad = this.adsPrograms.find((ad) => ad.id === adID);
      ad.brochure_url = this.$config.VUE_APP_IMG_404;
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-itemgrid.scss";

.four-col-grid-view {
  grid-template-columns: 1fr 1fr 1fr 1fr !important;
}

.tabs-img-estimate {
  height: 170px;
  object-fit: contain;
}
</style>
