import Vue from "vue";
import * as ApiEstimate from "../../../axios-generated/estimate/";
import * as ApiRealestate from "../../../axios-generated/realestate/";
import * as ApiProgram from "../../../axios-generated/program";
// @ts-ignore
import axios from "@axios";

const ESTIMATE_API = new ApiEstimate.EstimatesApi(
  undefined,
  Vue.prototype.$config.VUE_APP_ESTIMATE_API_BASE_URL,
  axios
);

const REALESTATE_API = new ApiRealestate.PropertiesApi(
  undefined,
  Vue.prototype.$config.VUE_APP_REALESTATE_API_BASE_URL,
  axios
);

const PROGRAMS_API = new ApiProgram.ProgramsApi(
  undefined,
  Vue.prototype.$config
    ? Vue.prototype.$config.VUE_APP_PROGRAM_API_BASE_URL
    : "",
  axios
);

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAddressByLatlng(ctx, { latlng }) {
      return new Promise((resolve, reject) => {
        axios
          .get(Vue.prototype.$config.VUE_APP_GOUV_API_GEO_REVERSE, {
            params: { lon: latlng.lng, lat: latlng.lat },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchSuggestAddress(ctx, { text }) {
      return new Promise((resolve, reject) => {
        axios
          .get(Vue.prototype.$config.VUE_APP_GOUV_API_GEO_SEARCH, {
            params: { q: text },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    // ESTIMATION
    createEstimate(ctx, { query }) {
      return new Promise((resolve, reject) => {
        ESTIMATE_API.fetchEstimation(
          query.propertyType,
          query.area,
          query.rooms,
          query.latlng.lat,
          query.latlng.lng,
          query.floor
        )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    // SUGGESTION
    listProperties(ctx, searchProperties) {
      return new Promise((resolve, reject) => {
        REALESTATE_API.listProperties(
          searchProperties.filter_enable,
          searchProperties.page_size,
          searchProperties.page,
          searchProperties.order_by,
          searchProperties.sort_dir_desc,
          searchProperties.developer_id,
          searchProperties.service,
          searchProperties.owner_type,
          searchProperties.type_parents,
          searchProperties.flow_origin,
          searchProperties.age,
          searchProperties.nb_rooms,
          searchProperties.min_price,
          searchProperties.max_price,
          searchProperties.min_area,
          searchProperties.max_area,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          searchProperties.geo_distances,
          searchProperties.geo_bounding_box
        )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    suggestPrograms(ctx, searchSuggestPrograms) {
      return new Promise((resolve, reject) => {
        PROGRAMS_API.suggestPrograms(searchSuggestPrograms)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    getProgramByID(ctx, id) {
      return new Promise((resolve, reject) => {
        PROGRAMS_API.getProgramByID(id)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
