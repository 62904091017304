<template>
  <l-map :zoom="zoom" :center="query.latlng" @click="addMarker">
    <l-tile-layer :url="url" :attribution="attribution" />
    <l-marker :lat-lng="query.latlng" :icon="iconBlue"></l-marker>
    <l-circle :lat-lng="query.latlng" :radius="query.distance" />
  </l-map>
</template>

<script>
import { LMap, LTileLayer, LMarker, LCircle } from "vue2-leaflet";
import { latLng, icon } from "leaflet";
import "leaflet/dist/leaflet.css";

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LCircle,
  },
  props: {
    query: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      zoom: 13,
      url: this.$config.VUE_APP_MAP_TILE_URL,
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      iconBlue: icon({
        iconRetinaUrl: require("@/assets/markers/marker-icon-2x-blue.png"),
        iconUrl: require("@/assets/markers/marker-icon-blue.png"),
        shadowUrl: require("@/assets/markers/marker-shadow.png"),
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
      }),
    };
  },
  methods: {
    addMarker(event) {
      this.query.latlng = event.latlng;
    },
    latLng,
  },
};
</script>

<style lang="scss" scoped>
.vue2leaflet-map {
  &.leaflet-container {
    height: 24rem;
  }
}
</style>
