/* tslint:disable */
/* eslint-disable */
/**
 * API Estimation (full version)
 * L\'API Estimation permet d\'estimer le prix d\'un bien immobilier à partir de critères simples.  **Exemple d\'intégration** : [Découvrez notre exemple d\'estimateur de biens](https://bo-staging.hello-cadredevie.fr/estimate/demo)  # Authentication  Authentification par token JWT généré à partir du backoffice client \'Cadre de Vie\'.  <!-- ReDoc-Inject: <security-definitions> -->
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: cdv-si@cadredevie.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Estimation
 */
export interface Estimation {
    /**
     * 
     * @type {string}
     * @memberof Estimation
     */
    'id': string;
    /**
     * Prix estimé.
     * @type {number}
     * @memberof Estimation
     */
    'estimatedPrice': number;
    /**
     * Prix estimé minimum.
     * @type {number}
     * @memberof Estimation
     */
    'estimatedPriceMin': number;
    /**
     * Prix estimé maximum.
     * @type {number}
     * @memberof Estimation
     */
    'estimatedPriceMax': number;
    /**
     * Indice de confiance (1 à 5).
     * @type {number}
     * @memberof Estimation
     */
    'confidenceIndice': number;
}
/**
 * 
 * @export
 * @interface ListRentMeterPeriod
 */
export interface ListRentMeterPeriod {
    /**
     * 
     * @type {string}
     * @memberof ListRentMeterPeriod
     */
    'zip_code'?: string;
    /**
     * 
     * @type {Array<RentMeterPeriod>}
     * @memberof ListRentMeterPeriod
     */
    'data'?: Array<RentMeterPeriod>;
}
/**
 * 
 * @export
 * @interface Metric
 */
export interface Metric {
    /**
     * Date time interval
     * @type {string}
     * @memberof Metric
     */
    'time'?: string;
    /**
     * Http request count in time interval
     * @type {number}
     * @memberof Metric
     */
    'count'?: number;
}
/**
 * A representation of an errorMessage
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * error Message
     * @type {string}
     * @memberof ModelError
     */
    'errorMessage'?: string;
}
/**
 * 
 * @export
 * @interface RentMeterPeriod
 */
export interface RentMeterPeriod {
    /**
     * 
     * @type {string}
     * @memberof RentMeterPeriod
     */
    'year'?: string;
    /**
     * Loyer m² moyen.
     * @type {number}
     * @memberof RentMeterPeriod
     */
    'rent_meter'?: number;
}

/**
 * EstimatesApi - axios parameter creator
 * @export
 */
export const EstimatesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Estimation d\'un bien immobilier, appartement ou maison.
         * @summary Estimation d\'un bien
         * @param {'apartment' | 'house'} propertyType Type de bien
         * @param {number} area Surface du bien
         * @param {number} rooms Nombre de chambres
         * @param {number} latitude GPS Latitude
         * @param {number} longitude GPS Longitude of the property
         * @param {number} [floor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchEstimation: async (propertyType: 'apartment' | 'house', area: number, rooms: number, latitude: number, longitude: number, floor?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyType' is not null or undefined
            assertParamExists('fetchEstimation', 'propertyType', propertyType)
            // verify required parameter 'area' is not null or undefined
            assertParamExists('fetchEstimation', 'area', area)
            // verify required parameter 'rooms' is not null or undefined
            assertParamExists('fetchEstimation', 'rooms', rooms)
            // verify required parameter 'latitude' is not null or undefined
            assertParamExists('fetchEstimation', 'latitude', latitude)
            // verify required parameter 'longitude' is not null or undefined
            assertParamExists('fetchEstimation', 'longitude', longitude)
            const localVarPath = `/estimates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (propertyType !== undefined) {
                localVarQueryParameter['property_type'] = propertyType;
            }

            if (area !== undefined) {
                localVarQueryParameter['area'] = area;
            }

            if (rooms !== undefined) {
                localVarQueryParameter['rooms'] = rooms;
            }

            if (floor !== undefined) {
                localVarQueryParameter['floor'] = floor;
            }

            if (latitude !== undefined) {
                localVarQueryParameter['latitude'] = latitude;
            }

            if (longitude !== undefined) {
                localVarQueryParameter['longitude'] = longitude;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Estimation des loyer m² par période et code postal.
         * @summary Estimation des loyer au m² par période.
         * @param {string} zipCode Code postal.
         * @param {number} [nbYears] Nombre d\&#39;années antérieures à scanner.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchRentEstimation: async (zipCode: string, nbYears?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'zipCode' is not null or undefined
            assertParamExists('fetchRentEstimation', 'zipCode', zipCode)
            const localVarPath = `/estimates/rent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nbYears !== undefined) {
                localVarQueryParameter['nb_years'] = nbYears;
            }

            if (zipCode !== undefined) {
                localVarQueryParameter['zip_code'] = zipCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer une estimation à partir de son ID.
         * @summary Récupérer une estimatation par ID
         * @param {string} id ID (uuid)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEstimationByID: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEstimationByID', 'id', id)
            const localVarPath = `/estimates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EstimatesApi - functional programming interface
 * @export
 */
export const EstimatesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EstimatesApiAxiosParamCreator(configuration)
    return {
        /**
         * Estimation d\'un bien immobilier, appartement ou maison.
         * @summary Estimation d\'un bien
         * @param {'apartment' | 'house'} propertyType Type de bien
         * @param {number} area Surface du bien
         * @param {number} rooms Nombre de chambres
         * @param {number} latitude GPS Latitude
         * @param {number} longitude GPS Longitude of the property
         * @param {number} [floor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchEstimation(propertyType: 'apartment' | 'house', area: number, rooms: number, latitude: number, longitude: number, floor?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Estimation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchEstimation(propertyType, area, rooms, latitude, longitude, floor, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Estimation des loyer m² par période et code postal.
         * @summary Estimation des loyer au m² par période.
         * @param {string} zipCode Code postal.
         * @param {number} [nbYears] Nombre d\&#39;années antérieures à scanner.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchRentEstimation(zipCode: string, nbYears?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ListRentMeterPeriod>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchRentEstimation(zipCode, nbYears, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer une estimation à partir de son ID.
         * @summary Récupérer une estimatation par ID
         * @param {string} id ID (uuid)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEstimationByID(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Estimation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEstimationByID(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EstimatesApi - factory interface
 * @export
 */
export const EstimatesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EstimatesApiFp(configuration)
    return {
        /**
         * Estimation d\'un bien immobilier, appartement ou maison.
         * @summary Estimation d\'un bien
         * @param {'apartment' | 'house'} propertyType Type de bien
         * @param {number} area Surface du bien
         * @param {number} rooms Nombre de chambres
         * @param {number} latitude GPS Latitude
         * @param {number} longitude GPS Longitude of the property
         * @param {number} [floor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchEstimation(propertyType: 'apartment' | 'house', area: number, rooms: number, latitude: number, longitude: number, floor?: number, options?: any): AxiosPromise<Estimation> {
            return localVarFp.fetchEstimation(propertyType, area, rooms, latitude, longitude, floor, options).then((request) => request(axios, basePath));
        },
        /**
         * Estimation des loyer m² par période et code postal.
         * @summary Estimation des loyer au m² par période.
         * @param {string} zipCode Code postal.
         * @param {number} [nbYears] Nombre d\&#39;années antérieures à scanner.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchRentEstimation(zipCode: string, nbYears?: number, options?: any): AxiosPromise<Array<ListRentMeterPeriod>> {
            return localVarFp.fetchRentEstimation(zipCode, nbYears, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer une estimation à partir de son ID.
         * @summary Récupérer une estimatation par ID
         * @param {string} id ID (uuid)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEstimationByID(id: string, options?: any): AxiosPromise<Array<Estimation>> {
            return localVarFp.getEstimationByID(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EstimatesApi - object-oriented interface
 * @export
 * @class EstimatesApi
 * @extends {BaseAPI}
 */
export class EstimatesApi extends BaseAPI {
    /**
     * Estimation d\'un bien immobilier, appartement ou maison.
     * @summary Estimation d\'un bien
     * @param {'apartment' | 'house'} propertyType Type de bien
     * @param {number} area Surface du bien
     * @param {number} rooms Nombre de chambres
     * @param {number} latitude GPS Latitude
     * @param {number} longitude GPS Longitude of the property
     * @param {number} [floor] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EstimatesApi
     */
    public fetchEstimation(propertyType: 'apartment' | 'house', area: number, rooms: number, latitude: number, longitude: number, floor?: number, options?: AxiosRequestConfig) {
        return EstimatesApiFp(this.configuration).fetchEstimation(propertyType, area, rooms, latitude, longitude, floor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Estimation des loyer m² par période et code postal.
     * @summary Estimation des loyer au m² par période.
     * @param {string} zipCode Code postal.
     * @param {number} [nbYears] Nombre d\&#39;années antérieures à scanner.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EstimatesApi
     */
    public fetchRentEstimation(zipCode: string, nbYears?: number, options?: AxiosRequestConfig) {
        return EstimatesApiFp(this.configuration).fetchRentEstimation(zipCode, nbYears, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer une estimation à partir de son ID.
     * @summary Récupérer une estimatation par ID
     * @param {string} id ID (uuid)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EstimatesApi
     */
    public getEstimationByID(id: string, options?: AxiosRequestConfig) {
        return EstimatesApiFp(this.configuration).getEstimationByID(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HealthcheckApi - axios parameter creator
 * @export
 */
export const HealthcheckApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * The healthcheck endpoint retrieve 200 code if service is up.
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealth: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/healthcheck`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthcheckApi - functional programming interface
 * @export
 */
export const HealthcheckApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthcheckApiAxiosParamCreator(configuration)
    return {
        /**
         * The healthcheck endpoint retrieve 200 code if service is up.
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHealth(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHealth(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HealthcheckApi - factory interface
 * @export
 */
export const HealthcheckApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthcheckApiFp(configuration)
    return {
        /**
         * The healthcheck endpoint retrieve 200 code if service is up.
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealth(options?: any): AxiosPromise<void> {
            return localVarFp.getHealth(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthcheckApi - object-oriented interface
 * @export
 * @class HealthcheckApi
 * @extends {BaseAPI}
 */
export class HealthcheckApi extends BaseAPI {
    /**
     * The healthcheck endpoint retrieve 200 code if service is up.
     * @summary Health Check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthcheckApi
     */
    public getHealth(options?: AxiosRequestConfig) {
        return HealthcheckApiFp(this.configuration).getHealth(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MetricsApi - axios parameter creator
 * @export
 */
export const MetricsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * The Metrics endpoint retrieves stats data from http request logs.
         * @summary Fetch Metrics
         * @param {string} subscriptionId ID (uuid) of subscription
         * @param {number} [nbDays] Nombre de jours antérieures à scanner.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetrics: async (subscriptionId: string, nbDays?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriptionId' is not null or undefined
            assertParamExists('getMetrics', 'subscriptionId', subscriptionId)
            const localVarPath = `/metrics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nbDays !== undefined) {
                localVarQueryParameter['nb_days'] = nbDays;
            }

            if (subscriptionId !== undefined) {
                localVarQueryParameter['subscription_id'] = subscriptionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MetricsApi - functional programming interface
 * @export
 */
export const MetricsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MetricsApiAxiosParamCreator(configuration)
    return {
        /**
         * The Metrics endpoint retrieves stats data from http request logs.
         * @summary Fetch Metrics
         * @param {string} subscriptionId ID (uuid) of subscription
         * @param {number} [nbDays] Nombre de jours antérieures à scanner.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMetrics(subscriptionId: string, nbDays?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Metric>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMetrics(subscriptionId, nbDays, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MetricsApi - factory interface
 * @export
 */
export const MetricsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MetricsApiFp(configuration)
    return {
        /**
         * The Metrics endpoint retrieves stats data from http request logs.
         * @summary Fetch Metrics
         * @param {string} subscriptionId ID (uuid) of subscription
         * @param {number} [nbDays] Nombre de jours antérieures à scanner.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetrics(subscriptionId: string, nbDays?: number, options?: any): AxiosPromise<Array<Metric>> {
            return localVarFp.getMetrics(subscriptionId, nbDays, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MetricsApi - object-oriented interface
 * @export
 * @class MetricsApi
 * @extends {BaseAPI}
 */
export class MetricsApi extends BaseAPI {
    /**
     * The Metrics endpoint retrieves stats data from http request logs.
     * @summary Fetch Metrics
     * @param {string} subscriptionId ID (uuid) of subscription
     * @param {number} [nbDays] Nombre de jours antérieures à scanner.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsApi
     */
    public getMetrics(subscriptionId: string, nbDays?: number, options?: AxiosRequestConfig) {
        return MetricsApiFp(this.configuration).getMetrics(subscriptionId, nbDays, options).then((request) => request(this.axios, this.basePath));
    }
}


