import store from "@/store";
import { ref } from "@vue/composition-api";
import { latLng } from "leaflet";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useEstimate() {
  const query = ref({
    propertyType: "apartment",
    area: null,
    rooms: null,
    floor: null,
    priceFiltered: true,
    distance: 500,

    latlng: latLng(45.761053, 4.846503),
    address: "",
    loading: false,
    isGeoBusy: false,
  });

  const estimationResult = ref(null);
  const adsRealestate = ref([]);
  const adsPrograms = ref([]);

  // Use toast
  const toast = useToast();

  const fetchAddressByLatlng = (query) => {
    query.isGeoBusy = true;
    return new Promise((resolve, reject) => {
      store
        .dispatch("app-estimate/fetchAddressByLatlng", {
          latlng: query.latlng,
        })
        .then((response) => {
          query.isGeoBusy = false;
          resolve(response);
        })
        .catch((err) => {
          query.isGeoBusy = false;
          toast(
            {
              component: ToastificationContent,
              props: {
                title: "Adresse introuvable",
                icon: "AlertTriangleIcon",
                variant: "warning",
              },
            },
            { position: "top-center" }
          );
          reject(err);
        });
    });
  };

  const fetchSuggestAddress = (text, query) => {
    query.isGeoBusy = true;
    return new Promise((resolve, reject) => {
      store
        .dispatch("app-estimate/fetchSuggestAddress", { text })
        .then((response) => {
          query.isGeoBusy = false;
          resolve(response);
        })
        .catch((err) => {
          query.isGeoBusy = false;
          toast(
            {
              component: ToastificationContent,
              props: {
                title: "Adresse introuvable",
                icon: "AlertTriangleIcon",
                variant: "warning",
              },
            },
            { position: "top-center" }
          );
          reject(err);
        });
    });
  };

  const createEstimate = (query) => {
    return new Promise((resolve, reject) => {
      store
        .dispatch("app-estimate/createEstimate", { query })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          toast(
            {
              component: ToastificationContent,
              props: {
                title:
                  "Estimation impossible. Veuillez réessayer ultérieurement.",
                icon: "AlertTriangleIcon",
                variant: "warning",
              },
            },
            { position: "top-center" }
          );
          reject(err);
        });
    });
  };

  const listProperties = (query, estimation) => {
    const searchProperties = {
      page: 1,
      page_size: 8,
      max_area: Number(query.area) + 20,
      min_area: Number(query.area) - 20,
      nb_rooms: [Number(query.rooms)],
      type_parents: [query.propertyType],
      service: "buy",
      age: "old",
      geo_distances: [
        JSON.stringify({
          lat: query.latlng.lat,
          lng: query.latlng.lng,
          distance: query.distance / 1000,
        }),
      ],
    };

    if (String(query.priceFiltered).toLowerCase() === "true") {
      searchProperties.max_price = parseInt(estimation.estimatedPriceMax);
      searchProperties.min_price = parseInt(estimation.estimatedPriceMin);
    }

    return new Promise((resolve, reject) => {
      store
        .dispatch("app-estimate/listProperties", searchProperties)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          toast(
            {
              component: ToastificationContent,
              props: {
                title: "Serveur Realestate indisponible",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            },
            { position: "top-center" }
          );
          reject(err);
        });
    });
  };

  const suggestPrograms = (query) => {
    const searchSuggestPrograms = {
      page: 1,
      page_limit: 8,
      area_max: Number(query.area) + 20,
      area_min: Number(query.area) - 20,
      rooms: Number(query.rooms),
      construction_type:
        query.propertyType === "house" ? "INDIVIDUEL" : "COLLECTIF",
      geometries: {
        circle: {
          distance: query.distance, // convert in meters
          center: {
            latitude: query.latlng.lat,
            longitude: query.latlng.lng,
          },
        },
      },
    };

    return new Promise((resolve, reject) => {
      store
        .dispatch("app-estimate/suggestPrograms", searchSuggestPrograms)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          toast(
            {
              component: ToastificationContent,
              props: {
                title: "Serveur Programmes indisponible",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            },
            { position: "top-center" }
          );
          reject(err);
        });
    });
  };

  const getProgramByID = (id) => {
    return new Promise((resolve, reject) => {
      store
        .dispatch("app-estimate/getProgramByID", id)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          toast(
            {
              component: ToastificationContent,
              props: {
                title: "Serveur Programmes indisponible",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            },
            { position: "top-center" }
          );
          reject(err);
        });
    });
  };

  return {
    query,
    estimationResult,
    adsRealestate,
    adsPrograms,

    fetchAddressByLatlng,
    fetchSuggestAddress,
    createEstimate,
    listProperties,
    suggestPrograms,
    getProgramByID,
  };
}
