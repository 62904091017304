<template>
  <section class="grid-view four-col-grid-view">
    <b-card
      v-for="ad in adsRealestate"
      :key="ad.id"
      class="itemgrid-card"
      @click="
        newTabTo(
          $router.resolve({
            name: `realestate-property`,
            query: {
              propertyID: ad.id,
            },
          }).href
        )
      "
      no-body
    >
      <div>
        <b-link>
          <b-img
            v-if="ad.images && ad.images.length > 0"
            :src="ad.images[0]"
            :alt="`${ad.id}`"
            fluid
            class="tabs-img-estimate"
            @error="imageLoadError(ad.id)"
          />
        </b-link>
      </div>

      <!-- Product Details -->
      <b-card-body>
        <div class="item-wrapper">
          <div class="item-rating">
            <h6 class="item-price">
              {{ currency(ad.price) }}
            </h6>
          </div>
          <div>
            <span> {{ currency(ad.price / ad.area) }}/m² </span>
          </div>
        </div>
        <div class="item-wrapper">
          <div class="item-rating">
            <b-badge variant="light-success" class="mr-1">
              {{ ad.area }} m²
            </b-badge>
            <b-badge variant="light-primary" class="ml-25">
              {{ ad.nb_rooms }}
              {{ ad.nb_rooms > 1 ? "pièces" : "pièce" }}
            </b-badge>
          </div>
          <div>
            <span>
              {{ ad.created_at.slice(0, 10) }}
            </span>
          </div>
        </div>
      </b-card-body>
    </b-card>
  </section>
</template>

<script>
import { BCard, BBadge, BCardBody, BImg, BLink } from "bootstrap-vue";
import { currency } from "@core/utils/filter";

export default {
  components: {
    BCard,
    BBadge,
    BCardBody,
    BImg,
    BLink,
  },
  props: {
    adsRealestate: {
      type: Array,
      required: true,
    },
  },
  methods: {
    currency,
    newTabTo(pageRedirectURL) {
      window.open(pageRedirectURL);
    },
    imageLoadError(adID) {
      const ad = this.adsRealestate.find((ad) => ad.id === adID);
      ad.images = [this.$config.VUE_APP_IMG_404];
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-itemgrid.scss";

.four-col-grid-view {
  grid-template-columns: 1fr 1fr 1fr 1fr !important;
}

.tabs-img-estimate {
  height: 170px;
  object-fit: contain;
}
</style>
