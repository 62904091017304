<template>
  <validation-observer ref="selectRules" tag="form">
    <b-overlay :show="query.isGeoBusy" no-wrap opacity="0.4" rounded="lg">
      <template v-slot:overlay>
        <div class="d-flex align-items-center">
          <b-spinner small type="grow" variant="dark" />
          <b-spinner type="grow" variant="primary" />
          <b-spinner small type="grow" variant="dark" />
          <!-- We add an SR only text for screen readers -->
          <span class="sr-only">Chargement wait...</span>
        </div>
      </template>
    </b-overlay>

    <b-row class="justify-content-md-center">
      <b-col cols="12">
        <b-form-group label="Adresse" label-for="selected">
          <validation-provider
            #default="{ errors }"
            name="Adresse"
            vid="Adresse"
            rules="required"
          >
            <vue-autosuggest
              id="selected"
              :suggestions="addressOptions"
              :limit="4"
              :input-props="{
                id: 'autosuggest__input',
                class: 'form-control',
                placeholder: '51, Rue du Marché 69300 Caluire-et-Cuire',
              }"
              @input="onInputChange"
              @selected="onSelected"
              @blur="onBlur"
              v-model="selected"
              :state="errors.length > 0 ? false : null"
            >
              <template slot-scope="{ suggestion }">
                <span class="my-suggestion-item">{{
                  suggestion.item.properties.label
                }}</span>
              </template>
            </vue-autosuggest>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <validation-provider #default="{ errors }" name="Type" rules="required">
          <b-form-group
            label="Type"
            label-for="Type"
            :state="errors.length > 0 ? false : null"
          >
            <v-select
              id="type"
              v-model="query.propertyType"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="propertyTypeOptions"
              :reduce="(val) => val.value"
              :clearable="false"
              label="text"
            />
            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col cols="6">
        <b-form-group label="Surface (m²)" label-for="area">
          <validation-provider
            #default="{ errors }"
            name="Surface"
            vid="Surface"
            rules="required"
          >
            <b-form-input
              id="area"
              v-model="query.area"
              type="number"
              :state="errors.length > 0 ? false : null"
              placeholder="48"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group label="Nombre de pièces" label-for="rooms">
          <validation-provider
            #default="{ errors }"
            name="Rooms"
            vid="Rooms"
            rules="required"
          >
            <b-form-input
              id="rooms"
              v-model="query.rooms"
              type="number"
              :state="errors.length > 0 ? false : null"
              placeholder="3"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col cols="6" v-if="query.propertyType === 'apartment'">
        <b-form-group label="Etage du bien" label-for="floor">
          <validation-provider
            #default="{ errors }"
            name="Floor"
            vid="Floor"
            rules="required"
          >
            <b-form-input
              id="floor"
              v-model="query.floor"
              type="number"
              :state="errors.length > 0 ? false : null"
              placeholder="2"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="justify-content-md-center">
      <b-col cols="3" class="text-center">
        <b-form-group
          label="Annonces similaires uniquement"
          label-for="filtre prix"
          label-cols-sm="4"
          label-cols-lg="12"
        >
          <b-form-checkbox
            v-model="query.priceFiltered"
            checked="true"
            class="custom-control-primary"
            name="check-button"
            switch
          />
        </b-form-group>
      </b-col>
      <b-col cols="9">
        <b-form-group
          label="Distance de recherche des suggestions"
          label-for="distance"
          label-cols-sm="4"
          label-cols-lg="12"
        >
          <b-input-group>
            <b-form-input
              id="distance"
              v-model="query.distance"
              type="range"
              number
              min="100"
              max="1500"
              step="100"
            />
            <b-input-group-append is-text class="text-monospace">
              {{ query.distance.toFixed(0) }} m
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12" lg="6">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          block
          variant="primary"
          @click="createEstimation()"
          v-show="!query.loading"
        >
          Estimer
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          block
          variant="primary"
          disabled
          v-show="query.loading"
        >
          <b-spinner small />
          Estimation en cours...
        </b-button>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import vSelect from "vue-select";
import { VueAutosuggest } from "vue-autosuggest";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BFormInvalidFeedback,
  BSpinner,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BOverlay,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import useEstimate from "../useEstimate";
import { latLng } from "leaflet";
import _ from "lodash";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BFormInvalidFeedback,
    BSpinner,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BOverlay,
    vSelect,
    VueAutosuggest,
  },
  directives: {
    Ripple,
  },
  props: {
    query: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      propertyTypeOptions: [
        { value: "apartment", text: "Appartement" },
        { value: "house", text: "Maison" },
      ],
      addressOptions: [],
    };
  },
  computed: {
    selected: {
      get() {
        return this.query.address;
      },
      set(value) {
        this.query.address = value;
      },
    },
  },
  setup() {
    const { fetchSuggestAddress } = useEstimate();
    return {
      fetchSuggestAddress,
    };
  },
  created() {
    this.debouncedFetchSuggestions = _.debounce(this.fetchSuggestions, 500);
  },
  methods: {
    createEstimation() {
      this.validationForm().then(() => {
        this.$emit("create-estimation-selected");
      });
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.selectRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    onBlur() {
      this.selected = "";
    },
    onSelected(item) {
      this.query.address = item.item.properties.label;
      this.query.latlng = latLng(
        item.item.geometry.coordinates[1],
        item.item.geometry.coordinates[0]
      );
    },
    fetchSuggestions(text) {
      this.fetchSuggestAddress(text, this.query).then((response) => {
        this.addressOptions = [
          {
            data: response.data.features,
          },
        ];
      });
    },
    onInputChange(text) {
      if (text === "" || text === undefined) {
        return;
      }
      this.debouncedFetchSuggestions(text);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-autosuggest.scss";
</style>
